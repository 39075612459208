#MagazinContainer {
  position: absolute;
  height: 100%;
  width: 100%;
}
#Magazin {
  position: relative;
  height: 100%;
  width: 100%;
}

.Magazinbackground {
  position: absolute;
  height: 100%;
  width: 100.1%;
  left: 0;
  object-fit: cover;
}

.bottomContainer {
  position: absolute;
  height: 106px;
  width: 64%;
  bottom: 0;
  left: 15%;

}
.rightContainer {
  position: absolute;
  position: absolute;
  height: 77%;
  width: 243px;
  left: 732px;
  top: 10%;

}

.contoireContainer {
  position: absolute;
  height: 100px;
  width: 190px;
  left: 95px;
  top: 36.8%;
}
.contoireContainerMonde3 {
  position: absolute;
  height: 86px;
  width: 245px;
  left: 59px;
  top: 39.5%;
}

.middleContainer {
  position: absolute;
  height: 302px;
  width: 148px;
  right: 43.5%;
  top: 22.5%;
}
.middleRightContainer {
  position: absolute;
  height: 227px;
  width: 221px;
  right: 22%;
  top: 35%;
}
.middleRightContainerMonde3 {
  position: absolute;
  height: 300px;
  width: 212px;
  right: 23%;
  top: 21.5%;
}
.middleLeftContainer {
  position: absolute;
  height: 299px;
  width: 96px;
  right: 60%;
  top: 24%;
}
.middleLeftContainerMonde3 {
  position: absolute;
  height: 299px;
  width: 84px;
  right: 59%;
  top: 24%;
}
.middleLeftContainerItem {
  height: 100%;
  width: 100%;
}
.middleContainerItem {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 12% 24% 4% 15%;
}
.contoireContainerItem {
  display: flex;
  height: 100%;
  width: 100%;
}
.relativeArea {
  position: relative;
  height: 100%;
  width: 100%;
}
.relativeArea > .texture {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  object-fit: contain;
  object-position: bottom center;
}

.bottomContainerItem {
  display: flex;
  height: 100%;
  width: 94%;
  position: relative;
  left: 22px;
  justify-content: space-between;
}
.items33w {
  position: relative;
  height: 100%;
  width: 33.333%;
}

.items27w {
  position: relative;
  height: 100%;
  width: 27.333%;
  /*! margin-left: 0%; */
  /*! justify-content: end; */
}

.bottomContainerItem > .items33w {
  padding-bottom: 5px;
}

.rightContainerItem {
  position: relative;
  top: -90px;
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
  
}
.items25h {
  position: relative;
  height: 25%;
  width: 100%;
}
.items25w {
  position: relative;
  height: 100%;
  width: 25%;
}
.rightContainerItem > .items25h {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.rightContainerItem > .items25h > div {
  width: 33.333%;
  height: 70%;
  position: relative;
}
.rightContainerItem > .items25h > div > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom center;
}



.rightContainerItem > div:nth-child(2) > div:nth-child(2) {
  top: -2px;
}
.rightContainerItem > div:nth-child(2) > div:nth-child(3) {
  top: -3px;
}

.cintreCrochet3 > div:nth-child(1) > img:nth-child(2) {
  top: 5px;
}
.cintreCrochet3 > div:nth-child(1) > img:nth-child(1) {
  top: 5px;
  left: 17px;
}

.middleContainerItem > .items25h {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  align-content: start;
}

.rightContainerItem > .items25h:nth-child(1) {
  transform: rotate(-7deg);
}



.rightContainerItem > .items25h:nth-child(3) {
  width: 90%;
  left: 25px;
}

.rightContainerItem > div:nth-child(4) {
  width: 90%;
  left: 9%;
  height: 20%;
  top: 6%;
}

.rightContainerItem > div:nth-child(1) > div:nth-child(1) {
  top: 21px;
  width: 100%;
  transform: rotate(6deg);
}

.rightContainerItem > div:nth-child(3) > div:nth-child(1) {
  top: -17px;
}
.rightContainerItem > div:nth-child(3) > div:nth-child(2) {
  top: -6px;
}
.rightContainerItem > div:nth-child(3) > div:nth-child(3) {
  top: 5px;
}
.rightContainerItem > div:nth-child(4) > div:nth-child(3) {
  top: 19px;
}
.rightContainerItem > div:nth-child(4) > div:nth-child(1) {
  top: -22px;
}

.items25h.item-2 > div {
  height: 100%;
  max-width: 50%;
  display: flex;
  align-items: flex-end;
}

.items25h.item-2 > div > img {
  width: 100%;
  object-fit: contain;
  object-position: bottom center;
  max-height: 95%;
}

.items25h.item-3 > div {
  height: 100%;
  max-width: 33.333%;
  display: flex;
  align-items: flex-end;
  width: 50px;
}

.items25h.item-3 > div > img {
  width: 85%;
  object-fit: contain;
  object-position: bottom center;
  max-height: 90%;
}

.contoireContainerItem > .items25w > img {
  width: 100%;
  object-fit: contain;
  position: absolute;
  bottom: 0px;
  object-position: bottom center;
}
.bommerMonde2 {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}
.sFlag {
  position: absolute;
  height: 33%;

  bottom: 15px;
  left: 100px;
}
.mFlag {
  position: absolute;
  height: 52%;

  bottom: 25px;
  left: 130px;
}

.lFlag {
  position: absolute;
  height: 60%;

  bottom: 6px;
  left: 58px;
  transform: rotate(17deg);
}
.xFlag {
  position: absolute;
  height: 56%;
  bottom: 37%;

  left: 42px;
}
.xlFlag {
  position: absolute;
  height: 63%;
  bottom: 46%;
  transform: rotate(-33deg);
  left: -15px;
}
.zFlag {
  position: absolute;
  height: 140px;
  bottom: -38%;
  transform: rotate(19deg);
  left: 155px;
}
/* .sFlag  ,.xFlag ,.mFlag  ,.lFlag  {
    left: 50%;
    transform: translateX(-50%);

} */
.sFlag > img,
.xFlag > img,
.mFlag > img,
.lFlag > img,
.xlFlag > img,
.zFlag > img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  object-fit: contain;
}

.sFlag::before,
.xFlag::before,
.mFlag::before,
.lFlag::before,
.xlFlag::before {
  content: "";
  height: 200%;
  width: 5%;
  background-color: #8f5937;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-95%);
  border-radius: 20px 20px 20px 20px;
}

.boueeSauvetageBlue {
  position: absolute;
  height: 100%;
  bottom: 0%;

  left: 30%;
}
.boueeSauvetageRouge {
  position: absolute;
  height: 133%;
  bottom: 0%;
  left: 29%;
}
.boueeSauvetageOrange {
  position: absolute;
  height: 112%;
  bottom: 0%;
  left: 5%;
}
.boueeSauvetage {
  position: absolute;
  height: 124%;
  width: 104%;
  bottom: 0%;
  left: 5%;
}
.boueeSauvetageBlue > img,
.boueeSauvetageRouge > img,
.boueeSauvetageOrange > img,
.boueeSauvetage > img,
.jumelle1Monde2 > img,
.jumelle2Monde2 > img,
.parapluie1Monde2 > img,
.parapluie2Monde2 > img,
.ancreMonde3 > img {
  position: relative;
  height: 100%;
  object-fit: contain;
  bottom: -30%;
}

.jumelle1Monde2 {
  position: absolute;
  height: 100%;
  bottom: 10%;
  left: 0%;
  transform: rotate(-10deg);
}
.jumelle2Monde2 {
  position: absolute;
  height: 110%;
  bottom: 20%;
  left: 10%;
  transform: rotate(-10deg);
}
.ancreMonde2 {
  position: absolute;
  height: 100px;
  width: 70px;
  top: 10%;
  left: 64%;
}
/* carteNavigation */
.carte_navigationMonde2{
  position: absolute;
  height: 99px;
  width: 168px;
  top: 16%;
  left: 8.5%;
}

.paniersMonde2 {
  position: absolute;
  height: 32%;
  width: 100%;
  bottom: 0;
  left: 5%;
}
.paniersMonde3 {
  position: absolute;
  height: 24%;
  width: 65%;
  bottom: 19px;
  left: -6%;

}
.BouteilleEauMonde3 {
  position: absolute;
  height: 21%;
  width: 39%;
  bottom: 19px;
  left: 63%;
}
.BouteilleEauMonde3Num2 {
  position: absolute;
  height: 22%;
  width: 42%;
  bottom: 18px;
  left: 36%;
  top: 73%;

}
.paniersMonde2 > img,
.paniersMonde3 > img,
.BouteilleEauMonde3 > img,
.carte_navigationMonde2 >img,
.ancreMonde2 > img, .BouteilleEauMonde3Num2 > img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.parapluie1Monde2 {
  position: absolute;
  height: 100%;
  bottom: 15%;
  left: 60%;
  transform: rotate(20deg);
}
.parapluie2Monde2 {
  position: absolute;
  height: 120%;
  bottom: 20%;
  left: 45%;
  transform: rotate(20deg);
}
.ancreMonde3 {
  position: absolute;
  height: 120%;
  bottom: 0%;
  left: 57%;
  transform: rotate(20deg);
}
.canes {
  position: absolute;
  height: 100%;
  width: 40px;
  left: -16px;
  bottom: -7px;
}

.extincteurRougeMonde2 {
  position: absolute;
  height: 40%;
  width: 46px;
  bottom: 0;
  left: 71px;
}
.extincteurMarronMonde2 {
  position: absolute;
  height: 35%;
  width: 42px;
  bottom: 0;
  left: 54px;
}
.extincteurBlueMonde2 {
  position: absolute;
  height: 26%;
  width: 22px;
  bottom: 0;
  left: 51px;
}
.extincteurvertMonde2 {
  position: absolute;
  height: 29%;
  width: 34px;
  bottom: 0;
  left: 28px;
}








.ext_rouge {
  position: absolute;
  height: 31%;
  width: 48px;
  bottom: 0;
  left: 171px;
}

.ext_orange {
  position: absolute;
  height: 30%;
  width: 48px;
  bottom: 0;
  left: 15px;
}
.bottesmonde3 {
  position: absolute;
  height: 18%;
  width: 35px;
  bottom: 0;
  left: 144px;
}
.bottesbleuclairmonde3 {
  position: absolute;
  height: 14%;
  width: 34px;
  bottom: 0;
  left: 109px;
}
.chaussures1monde3 {
  position: absolute;
  height: 12%;
  width: 26px;
  bottom: 0;
  left: 84px;
}
.chaussures2monde3 {
  position: absolute;
  height: 12%;
  width: 26px;
  bottom: 0;
  left: 56px;
}


.seauRougeMonde2 {
  position: absolute;
  height: 23%;
  width: 51px;
  bottom: 0;
  right: 5px;
}
.seauJauneMonde2 {
  position: absolute;
  height: 23%;
  width: 51px;
  bottom: 0;
  right: 29px;
}
.seauBlueMonde2 {
  position: absolute;
  height: 23%;
  width: 51px;
  bottom: 0;
  right: 54px;
}


.cintreCrochet1 {
  position: absolute;
  height: 44%;
  width: 31%;
  left: 24px;
  top: 11px;
}

.cintreCrochet2 {
  position: absolute;
  height: 49%;
  width: 38%;
  right: 30px;
  top: 12px;
}

.cintreCrochet3 {
  position: absolute;
  height: 70%;
  width: 100%;
  /* right: 35px; */
  top: 0px;
}

.cintreCrochet1Monde3 {
  position: absolute;
  height: 100px;
  width: 90px;
  left: 10px;
  top: 0px;
}
.cintreCrochet2Monde3 {
  position: absolute;
  height: 100px;
  width: 90px;
  left: 100px;
  top: 0px;
}
.cintreCrochet3Monde3 {
  position: absolute;
  height: 100px;
  width: 90px;
  left: 10px;
  top: 107px;
}
.cintreCrochet4Monde3 {
  position: absolute;
  height: 100px;
  width: 90px;
  left: 100px;
  top: 112px;
}
.cintreCrochet5Monde3 {
  position: absolute;
  height: 38px;
  width: 38px;
  left: 42px;
  top: 90px;
}

.canes > img,
.seaux > img,
.extincteurs > img,
.ext_rouge > img,
.ext_orange > img,
.cintreCrochet5Monde3 > img,
.chaussures1monde3 > img,
.chaussures2monde3 > img,
.bottesbleuclairmonde3 > img,
.bottesmonde3 > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.cintreCrochet2 > .relativeArea > img,
.cintreCrochet1 > .relativeArea > img,
.cintreCrochet3 > .relativeArea > img,
.cintreCrochet4 > .relativeArea > img,
.cintreCrochet1Monde3 > .relativeArea > img,
.cintreCrochet2Monde3 > .relativeArea > img,
.cintreCrochet3Monde3 > .relativeArea > img,
.cintreCrochet4Monde3 > .relativeArea > img,
.cintreCrochet5Monde3 > .relativeArea > img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.cintreCrochet1 > .relativeArea > img:nth-child(1) {
  right: -12px;
  top: -3px;
}
.cintreCrochet2 > .relativeArea > img:nth-child(1) {
  right: -10px;
  top: -12px;
}
.cintreCrochet2 > .relativeArea > img:nth-child(2) {
  top: -11px;
}

.mi3le9::before {
  position: absolute;
  content: "";
  display: inline-block;
  background: url("../../img/monde_ba/Paquetage/vetement/cintre-crochet.png")
    no-repeat;
  background-size: contain;
  right: 0;
  top: -8px;
  width: 70px;
  height: 25px;
  background-position: center right;
}
.buttonValiderMonde2Game4 {
  position: absolute;
  height: 50px;
  width: 150px;
  bottom: 17px;
  left: 50%;
  transform: translate(-50%, 0);
  object-fit: contain;
}
.haricot{

}
.cans{

}
.hanger{
  position: absolute;
  height: 10px;
  width: 70px;
  top: 10%;
  left: 64%;
}
.hanger>img{
  object-fit: contain;
  height: 100%;
  width: 100%;
}