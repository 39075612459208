

.Alarm-container {
  width: 22.2%;
  height: 42%;
  position: relative;
  top: 1%;
  margin-left: 39%;
}
.Alarm-container > img {
  object-position: center center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lock-container {
  position: relative;
  width: 25%;
  height: 23%;
  margin-left: 67%;
  top: 7%;
}
.lock-container > div {
  object-position: center center;
  position: absolute;
  width: 100%;
  height: 100%;

}
.lock{
  background-image: url("../../../../assets/img/monde_cat/Alarm/lock.png");
  background-size: contain;
  background-repeat: no-repeat;


}
.unlock{
  background-image: url("../../../../assets/img/monde_cat/Alarm/unlock.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.totalunlock{
  background-image: url("../../../../assets/img/monde_cat/Alarm/totalunlock.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.lock-container > img {
  object-position: center center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.clavier-container {
  width: 72%;
  height: 69%;
  position: absolute;
  top: 25.2%;
  left: -1.1%;
}
.clavier-container > form {
  width: 100%;
  height: 100%;
}
.input_buttons {
  width: 27px;
  z-index: 1;
  height: 27px;
  position: relative;
  float: left;
  color: white;
  background-color: #45e7f9;
  border-width: 0px;
  filter: drop-shadow(1px 1px 2px #0000006e);
  border-radius: 20%;
  font-size: 21px;
  padding-left: 0px;
  padding-right: 0px;
}
.lettre-a {
  margin-top: 16%;
  left: -71px;
}
.lettre-b {
  margin-top: -12.5%;
  left: -28px;
}
.lettre-c {
  margin-top: -13%;
  left: 14px;
}
.lettre-d {
  margin-top: 7%;
  left: -71px;
}
.lettre-e {
  margin-top: -13%;
  left: -28px;
}
.lettre-f {
  margin-top: -13%;
  left: 14px;
}
.lettre-g {
  margin-top: 7%;
  left: -71px;
}
.lettre-h {
  margin-top: -13%;
  left: -27px;
}
.lettre-i {
  margin-top: -13%;
  left: 14px;
}
#submit{
  margin-left: 97% !important;
  margin-top: -63% !important;
  background: linear-gradient(90deg, rgba(63,195,212,1) 50%, rgba(29,98,117,1) 100%) !important;  /* border-radius: 50% !important; */
  width: 35% !important;
  height: 32px !important;
  filter: drop-shadow(0px 1px 2px white) ;
  text-align: center !important;
  font-size: 17px !important;
}
.reset-alarm {
  margin-left: 97% !important;
  margin-top: -97% !important;
  background: linear-gradient(90deg, rgba(63,195,212,1) 50%, rgba(29,98,117,1) 100%)!important;  /* border-radius: 50% !important; */
  width: 35% !important;
  height: 32px !important;
  filter: drop-shadow(0px 1px 2px white) !important;
  text-align: center !important;
  font-size: 17px !important;
}

#keyboard {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 5%;
  margin-top: 6%;
}
#keyboard li {
  float: left;
  margin: 0 5px 5px 0;
  width: 21%;
  height: 20%;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  background: #00000059;
  border: 1px solid #f9f9f9;
  border-radius: 9px;
  margin-left: 5%;
  margin-top: 8%;
  font-size: 25px;
  color: white;
  background-color: #45e7f9;
  border-width: 0px;
  filter: drop-shadow(1px 0px 4px white);
}
.capslock,
.tab,
.left-shift,
.clearl,
.switch {
  clear: left;
}
#keyboard .tab,
#keyboard .delete {
  width: 70px;
}
#keyboard .capslock {
  width: 80px;
}
#keyboard .return {
  width: 90px;
}
#keyboard .left-shift {
  width: 70px;
}

#keyboard .switch {
  width: 90px;
}
#keyboard .rightright-shift {
  width: 109px;
}
.lastitem {
  margin-right: 0;
}
.uppercase {
  text-transform: uppercase;
}
#keyboard .space {
  float: left;
  width: 556px;
}
#keyboard .switch,
#keyboard .space,
#keyboard .return {
  font-size: 16px;
}
.on {
  display: none;
}
#keyboard li:hover {
  position: relative;
  top: 1px;
  left: 1px;
  border-color: #e5e5e5;
  cursor: pointer;
}
.Pass-container {
  width: 108px;
  height: 45px;
  position: relative;
  top: -15%;
  left: 8%;
  border-radius: 10%;
  background-color: #f1f1f0;
  font-size: 25px;
  padding-top: 14px;
  padding-left: 6px;
}
.Bungalow-container {
  width: 22%;
  height: 14%;
  position: relative;
  margin-top: 155px;
  margin-left: 375px;
  font-size: 17px;
  padding-top: 30px;
  padding-left: 62px;
  color: white;
  background-image: url("../../../../assets/img/monde_cat/Alarm/bungalow.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.anim-bung{
	-webkit-animation: flip-out-ver-right 1s ease-in-out 2 alternate both;
	        animation: flip-out-ver-right 1s ease-in-out 2 alternate both;
}
.flip-out-ver-right {
	-webkit-animation: flip-out-ver-right 1s ease-in-out 2 alternate both;
	        animation: flip-out-ver-right 1s ease-in-out 2 alternate both;}
@-webkit-keyframes flip-out-ver-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(70deg);
            transform: rotateY(70deg);
    opacity: 0;
  }
}
@keyframes flip-out-ver-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(70deg);
            transform: rotateY(70deg);
    opacity: 0;
  }
}
.desactivated{
  filter: grayscale(1)!important;
}
.shadow{
  position: absolute;
  height: 38px;
  width: 38px;
  top: 25.2px;
  border-radius: 50px;
  left: 151px;  
  border: 1px solid red;
  box-shadow: 0px 0px 10px 2px red;
  animation: blink-1 0.6s ease-in-out infinite both;
}