.slider-wrapper {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}

.slider-wrapper input {
  width: 100px;
  height: 9px;
  position: relative;
  margin: 0px;
  top: -49%;
  transform-origin: 75px 75px;
  transform: rotate(-90deg);
  background: rgb(255,0,0);
  background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,248,0,1) 25%, rgba(0,255,98,1) 50%, rgba(255,248,0,1) 75%, rgba(255,0,0,1) 100%);}
input[type=range]{
  -webkit-appearance: none;
  /* writing-mode: vertical-rl; */
}



.slider-wrapper input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 15px;
  width: 15px;
  background: white;
  border-radius: 0;
  
}

.slider-wrapper input[type=range]::-moz-range-thumb {
  border: none;
  height: 15px;
  width: 15px;
  background: white;
  border-radius: 0;
}

.slider-wrapper input[type=range]:focus {
  outline: none;
}

