.error-div {
  position: absolute;
  z-index: 1;
  height: 4%;
  width: 6%;
  left: 9%;
  top: 7%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  /* filter: drop-shadow(0px 0px 5px rgba(230, 174, 174, 0.767)); */
}

.EroorDump_Cocktail {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
  height: 60px;
  width: 60px;
  border: 1px solid white;
  top: 20px;
  left: 2%;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  display: flex;
}

.EroorDump_Cocktail>.error-container {
  width: 22% !important;
}

.EroorDump_Zombie {
  /* background-color: rgba(255, 255, 255, 0.5); */
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
  height: 60px;
  width: 60px;
  border: 1px solid white;
  top: 20px;
  left: 2%;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  display: flex;
}

.EroorDump_Zombie>.error-container {
  width: 22% !important;
}

.error-div>img {
  width: 60%;
  object-fit: contain;
  object-position: center center;
}

.error-div>.error-container {
  width: 50%;
  font-size: 1.6em;
}