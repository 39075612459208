@font-face {
  font-family: "biko" ;
  src : url("shared/Biko_Regular.otf");
}
@font-face {
  font-family: "SmudgieCrayon" ;
  src : url("shared/segoesc.ttf") format('truetype');
}
body,html {
  margin: 0 auto ;
  height: calc(600px - 33px) !important;
  width: 960px ;
  font-family: "biko" !important;
}

input[type="button"],input[type="submit"]
{
    width:120px;
    height:60px;
    margin-left:35px;
    display:block;
    background-color:gray;
    color:white;
    border: none;
    outline:none;
}
span.red-text {
  word-wrap: break-word;
  max-width: 31%;
  margin-bottom: 0.5rem;
}
input[type="button"]::-moz-focus-inner,input[type="submit"]::-moz-focus-inner {
  border: 0;
}

* {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
  outline: none !important;
  font-family: "biko" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.container {
  height: 100%;
  width: 100%;
  position: absolute;
 }



.describe {
  box-sizing: border-box ;
  position: absolute;
  height: 100%;
  width: 100%;
  min-width: 150px;
  color: aliceblue ;
  padding: 3px ;
  font-size: 20px ;
  font-weight: 600;
  line-height: 26px;

}

.describe.right {
  right: 90%;
  top: 10%;
}
.describe.left {

  transform: translate(50%);
  left: 50%;
  top: 5%;
}

.warpper {
  height: 100%;
  width: 100%;
  background-color: #94d3e5 ; 
  position: absolute;
}


.map {
  background-image: url(./assets/img/map/map_with_tower.png);
  height: 93%;
  position: absolute;
  width: 97%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  top: 50%;
  left: 50%;
  transform: translate(-51%,-48%);
}




.small_map {
  background-image: url(./assets/img/map/small_map.png);
  height: 170px;
  position: absolute;
  width: 170px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  bottom: 0;
  left: 0;
}
.shakeCorrect,
.shakeCorrect:hover,
.shakeCorrect:focus {
  /* -webkit-animation-name: spaceboots;
  -webkit-animation-duration: 0.8s;
  -webkit-transform-origin:50% 50%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-name: spaceboots;
  animation-duration: 0.8s;
  transform-origin:50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  filter : drop-shadow(0px 0px 10px green) drop-shadow(0px 0px 5px green)!important ;
}
  /* class css */
*, :after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}