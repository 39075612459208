
.App {
  height: 100%;
  width: 100%;

}

.App-logo {
  height: 40vmin;
}
input:focus::placeholder {
  color: transparent;
}
input {
  color: #667a91;
}
#root {
  /* height: calc(600px - 4vh);
  width: 960px; */
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.loaderContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  /* background-color: rgb(148 163 229 / 95%); */
  background-color: white;
}
.background {
  height: 100%;
  width: 100%;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
/* **************** */


/* Inline #2 | http://localhost:3000/dashboard */



/* ***************************** */
.score-container {
  color: #4c8bcf  ;
  font-size: 230%;
  width: 11%;
  position: absolute;
  top: 20px;
  left: 2%;
}

.score-total.monde0Score {
  display: flex;
  align-items: center;
  height: 75px;
  width: 75px;

}
.score-total.monde0Score > img {
  position: relative;
  left: -5px;
  height: 75px;
}

.score-total.MondeScoreStyle {
    display: flex;
    align-items: baseline;
    background-color: rgba(255,255,255,0.5);
    border-radius: 50%;
    box-sizing: border-box;
    -webkit-box-shadow: inset 0px 0px 8px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: inset 0px 0px 8px 1px rgba(0,0,0,0.2);
    box-shadow: inset 0px 0px 8px 1px rgba(0,0,0,0.2);
    height: 62px;
    width: 62px;
    border: 1px solid white;
    padding-left: 3%;
    padding-right: 5%;
    /*! align-content: flex-start; */
  
   
}
.score-total.MondeScoreStyle > img {

  height: 70%;
  width: 50%;
  object-fit: contain;
  object-position: bottom;

 
}

.score-total.MondeScoreStyle > div {

  width: 19px;
  text-align: end;

}
.score-details {
  display: flex;
  flex-flow: column;
  position: relative;
  top: -4px;
  width: 90%;
}
.score-details_div {
  height: 8px;
  background-color: #dddddd;
  margin-bottom: 3px;
  -webkit-clip-path: polygon(0px 0px, 100% 0px, 90% 100%, 0% 100%);
  clip-path: polygon(0px 0px, 100% 0px, 95% 100%, 0% 100%);
  width: 120%;
  -webkit-box-shadow: -13px 7px 18px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -13px 7px 18px -7px rgba(0, 0, 0, 0.75);
  box-shadow: -13px 7px 18px -7px rgba(0, 0, 0, 0.75);
}

.score-details_div.active {
  background-color: rgb(255, 255, 255);
}
.score-details_div.mondeColor1.active > div  {
  background-color: #e78852 ;
}
.score-details_div.mondeColor2.active > div {
  background-color: #0097c2 ;
}
.score-details_div.mondeColor3.active > div {
  background-color: #baa16b ;
}
.score-details_div.mondeColor4.active > div {
  background-color: #9e3cb0 ;
}

.mondeColor1 {
  color: #e78852 ;
  font-size: 75%;
}
.mondeColor2 {
  color: #0097c2 ;
  font-size: 75%;
}
.mondeColor3 {
  color: #c1a66d ;
  font-size: 75%;
}
.mondeColor4{
  color: #9e3cb0 ;
  font-size: 75%;
}
.my_full_screen_btn {
  position: "absolute";
  top: "100%";
}

@keyframes fade-in-up {
  0%,
  10% {
    top: 0%;
    opacity: 1;
  }
  100% {
    top: -150%;
    opacity: 0;
  }
}

.plus-number {
  position: absolute;
  animation-name: fade-in-up;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

/* .TimeSheetCells::after {
  content: "";
  height: 1px;
  width: 100%;
  background: #0000ff38;
  position: absolute;
  top: 50%;
  left : 0 ;
  transform: translateY(-50%);
} */

.cls-1,.cls-3{
  fill:#da1c5c ;
}
.cocktail.cls-1 {
  stroke:#da1c5c
}
.cls-2{
  fill:#fff
}
.cls-3{
  fill-rule:evenodd
}

.wrapper-item-svg.sport .cls-1,.wrapper-item-svg.sport .cls-3  {
  fill : #158bc2  ;
}


.wrapper-item-svg.culturelle .cls-1,.wrapper-item-svg.culturelle .cls-3 {
  fill : #652d9a ;
}

.wrapper-item-svg.culturelle .cocktail.cls-1 {
  stroke:#652d9a
}
.text-alerty {
  position: relative;
  transform: translateY(-50%);
  font-size: 130%;
  padding: 0 45px;
  margin-top: 0%;

}
.text-alertyMonde4{
  position: relative;
  transform: translateY(-50%);
  font-size: 13pt;
  padding: 0 45px;
  margin-top: 1%;
}

.text-alerty > .blue{
    color: #158bc2;
    font-weight: 700
}
.text-alerty > .violet{
  color: #652d9a;
  font-weight: 700
}
