.mondeCocktailBg {
  /* z-index: -1; */
}
.Cocktail {
  position: absolute;
  width: 100%;
  height: 94%;
  top: 4%;
  /* border: 1px solid; */
}
/*etagere*/

.etagere {
  position: relative;
  height: 58%;
  /* border: 1px solid; */
  width: 64%;
  margin: auto;
}
.Monde_Cocktail_port2_fermer {
  height: 86%;
  object-fit: contain;
  position: absolute;
  margin: auto;
  top: 17%;
  width: 100%;
}

.left-etagere {
  /* border: 1px solid red; */
  width: 29%;
  height: 31%;
  height: 100%;
  float: left;
  position: relative;
}
.top_left_etagere {
  height: 31%;
  width: 70%;
  margin: auto;
}
.glacon {
  width: 49%;
  object-fit: contain;
}
.middle-etagere {
  /* border: 1px solid red; */
  width: 44%;
  height: 31%;
  height: 100%;
  float: left;
  position: relative;
}
.right-etagere {
  width: 22%;
  height: 62%;
  float: left;
  position: relative;
  top: 38%;
}
.middle_left_etagere {
  width: 90%;
  height: 30%;
  margin: auto;
}
.decoration {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(59px, 92px);
  grid-template-rows: 68px 34px;
  top: 12%;
  grid-row-gap: 57px;
  position: relative;
  height: 100%;
  width: 93%;
  margin: auto;
  left: 3%;
}
.decorationscore_img {
  width: 100%;
  height: 100%;
}
.support_deco {
  width: 100%;
  height: 10%;
}
.decorationscore_img:first-child {
  grid-column: 1 / 3;
}
.decoration img {
  object-fit: contain;
  position: relative;
  width: 100%;
  object-position: bottom;
  height: 80%;
}
.Parasole {
  display: flex;
  position: relative;
  height: 90%;
  flex: 1 1 1;
  align-items: flex-end;
  justify-content: center;
}

.Parasole img {
  object-fit: contain;
  position: relative;
  width: 100%;
  object-position: bottom;
  height: 80%;
}
.port_fermer {
  width: 83%;
  position: absolute;

  height: 55%;
  left: 12%;
  top: 37%;
}
.Parasolescore_img {
  width: 15%;
  height: 73%;
}
.etagereParasole {
  width: 80%;
  margin: auto;
  height: 32%;
  position: relative;
  /* border: 1px solid; */
}
.jus {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  height: 100%;
  left: 8%;
}
.jus img {
  object-fit: contain;
  width: 100%;
  height: 51px;
  object-position: bottom;
  position: relative;
}
.lamelleDeFruit {
  display: flex;
  position: relative;
  height: 90%;
  flex: 1 1 1;
  align-items: flex-end;
  justify-content: center;
}

.lamelleDeFruit img {
  object-fit: contain;
  position: relative;
  width: 72%;
  object-position: bottom;
  height: 80%;
}
.middle_top_etagere {
  position: relative;
  height: 38%;
}
.support {
  width: 80%;
  object-fit: contain;
  position: relative;
  display: grid;
  margin: auto;
}
.lamelleDeFruitscore_img {
  width: 10%;
  height: 73%;
}
.boisson {
  display: flex;
  position: relative;
  height: 41%;
  flex: 1 1 1;
  align-items: flex-end;
  left: 2.2%;
}

.boisson img {
  object-fit: contain;
  position: relative;
  width: 72%;
  object-position: bottom;
  height: 100%;
}
.boisson div {
  width: 19%;
}
.sirops {
  position: absolute;
  width: 28%;
  height: 27%;
  left: 70%;
  top: 68%;
}
.sirops > img {
  position: absolute;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.sirop {
  display: flex;
  /* border: 1px solid; */
  margin-left: 12%;
  margin-top: -7%;
}
.sirop div {
  width: 10%;
  position: relative;
}
.sirop img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: relative;
}
.sirop > * {
  margin: 1px 21px 0 0px;
}
.siropscore_img:nth-child(3) {
  margin: 2px 23px 0px 44px;
}
/* #monde_bonus_sirop_framboise {
  margin: 2px 23px 0px 44px;
} */
/* .item{
    border: 1px solid;
} */
.verre {
  width: 9%;
  height: 20%;
  position: relative;
  left: 47%;
  top: 17%;
}
.verreImg {
  object-fit: contain;
  width: 100%;
  height: 80%;
  position: absolute;
  top: 16%;
}
.verreCocktail {
  content: "";
  position: absolute;
  left: 30px;
  bottom: 51px;
  width: 22px;
  height: 0px;
  border-radius: 50%;
  /* background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%); */
}
.JaugeAutomatique {
  left: -10%;
}
/*Root Niveaux*/
.niveauxCocktail {
  width: 42%;
  top: 6%;
  position: absolute;
  left: 40%;
  height: 48%;
}
.niveau4Cocktail {
  position: relative;
  width: 12%;
  height: 21%;
  left: 1.5%;
  top: -1%;
}
.niveau3Cocktail {
  position: absolute;
  width: 41%;
  height: 371px;
  bottom: -74%;
  left: -15%;
}
.niveau2Cocktail {
  position: absolute;
  left: 59%;
  bottom: -1%;
  height: 20%;
  width: 12%;
}
.niveau1Cocktail {
  position: absolute;
  bottom: -3px;
  left: 27%;
  height: 16%;
  width: 11%;
}

/* TODO: yessmin badal niveau1 niveau2 niveauI ib des noms o5rin wala sar conflit :p  */

.imgNiveau {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Monde_Cocktail_port2_ouvert {
  height: 21.5%;
  object-fit: cover;
  position: absolute;
  margin: auto;
  top: 17%;
  width: 95.5%;
  left: 2.5%;
}
.port_ouvert {
  width: 82%;
  position: absolute;

  height: 63%;
  left: -70%;
  top: 37%;
}

/* .decoration div {
  grid-column: span 3;
  height: 50px;
  background: red;
}

.decoration div:nth-child(n + 3) {
  grid-column: span 2;
} */
.support1 {
  position: absolute;
  top: 58%;
  left: 13%;
}
.support2 {
  position: absolute;
  top: 90%;
  left: 13%;
}

.Seauscore {
  height: 43%;
  text-align: center;
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.deco_verre {
  width: 27%;
  height: 31%;
  left: 13%;
  position: relative;
  top: -18px;
}

.img_deco_verre {
  object-fit: contain;
  height: 100%;
  width: 100%;
  transform: rotate(10deg);
}
.lamelle_verre {
  width: 27%;
  height: 31%;
  left: -4%;
  position: absolute;
  top: -18px;
}
.img_lamelle_verre {
  object-fit: contain;
  height: 100%;
  width: 100%;
  transform: rotate(-22deg);
}
.Nostart {
  opacity: 0;
  display: none;
}
.Arrow_cockatil {
  width: 15px;
  border: 60%;
  position: absolute;
}
#arrow_cocktailniv1 {
  left: 31%;
  bottom: 19%;
  z-index: -1;
}
#arrow_cocktailniv2 {
  left: 63%;
  bottom: 19%;
  z-index: -1;
}
#arrow_cocktailniv3 {
  left: -4%;
  bottom: 65%;
  z-index: -1;
}
#arrow_cocktailniv4 {
  left: 5.5%;
  bottom: 100%;
  z-index: -1;
}
/*Score*/
.ScoreCocktailNum {
  color: #7db229;
  top: 32%;
}
.classscore {
  background: linear-gradient(to top, #de5e34 50%, white 50%);
  background-size: 200% 200%;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 1s linear infinite;
}
@keyframes shine {
  to {
    background-position: 0 100%;
  }
}
.chaise_cocktail {
  position: absolute;
  height: 270px;
  width: 135px;
  top: 50%;
  left: 38%;
}

.cls-1-glass {
  fill: url(#radial-gradient);
}

.cls-2-glass {
  fill: url(#radial-gradient-2);
}

.cls-3-glass {
  fill: #fff;
  opacity: 0.4;
}

.cls-4-glass,
.cls-5-glass,
.cls-6-glass {
  opacity: 0.2;
}

.cls-4-glass {
  fill: url(#radial-gradient-3);
}

.cls-5-glass {
  fill: url(#radial-gradient-4);
}

.cls-6-glass {
  fill: url(#radial-gradient-5);
}

.cls-7-glass {
  opacity: 0.3;
  fill: url(#radial-gradient-6);
}

.cls-8-glass {
  fill: #d9f5fc;
  fill: url(#Linear);
}
.glassContainer {
  position: absolute;
  width: 36%;
  top: 8%;
  z-index: 2;
}
.manCockatil {
  transform: translate(-50%, -50%);
  width: calc(493px / 2);
  height: 301px;

  /* animation: animateMe 2s steps(2) infinite; */
  -webkit-animation: animateMe 0.5s steps(2) infinite;
  /* -webkit-animation: scale-up-center 4s linear infinite; */
  top: 72%;
  position: absolute;
  left: 26%;
}
.manCockatil1 {
  background: url("../../../../assets/img/monde_bonus/monde_cocktail/Monde_Cocktail_ami_UP_DOWN_V2.png");
}
.manCockatil2 {
  background: url("../../../../assets/img/monde_bonus/monde_cocktail/mondeBonus2/Monde_Cocktail_ami_UP_DOWN_V2.png");
}
@keyframes animateMe {
  from {
    background-position: 0;
  }
  to {
    background-position: 493px;
  }
}
.table_cocktail {
  position: absolute;
  top: 92%;
  height: 9%;
  width: 100%;
}
#click1 {
  position: absolute;
  top: 20%;
  display: none;
}
#click2 {
  position: absolute;
  top: 40%;
  display: none;
}
#click3 {
  position: absolute;
  top: 50%;
  display: none;
}
#click4 {
  position: absolute;
  top: 60%;
  display: none;
}
.cock2 > .flip-clock-wrapper ul li a div div.inn {
  background-image: linear-gradient(30deg, #4cc6f2, #3596c0);

  color: white;
}
.barBonus {
  height: 42%;
  position: absolute;
  bottom: 5%;
  width: 100%;
  left: 8%;
}
.juice_machineBonus {
  position: absolute;
  width: 15%;
  left: 69%;
  height: 19%;
  top: 36%;
}

.bounceniveau4 {
  z-index: 1 !important;
  -webkit-animation: bounce-top 3s infinite;
  animation: bounce-top 3s infinite;
}
.cube_glac {
  z-index: 2;
  position: absolute;
  width: 36%;
}
.cube_glac > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
