.enter {
  opacity: 0;
  /* transform: translateX(-50%) perspective(500px) translateZ(-500px) scale(0.2) !important; */
}
.active-enter {
  opacity: 1;
  /* transform: translateX(-50%) perspective(500px) translateZ(0px) scale(1) !important; */
  transition: all 3000ms cubic-bezier(0.17, 0.84, 0.44, 1);
  /* animation-name: keyFrameObstacleAppear;
  animation-duration: 4s; */
}



.exit {
  opacity: 0.1;
  transform: translateX(-50%) perspective(500px) translateZ(0px) scale(1)
    translateY(100px);
  transition: opacity 1000ms;
}
.active-exit {
  opacity: 0;
  transform: translateX(-50%) perspective(500px) translateZ(0px) scale(1)
  translateY(100px);
  transition: opacity 1000ms;
}
.done-exit {
  opacity: 0;
}
#obstacleMoving {
  display: flex;
           
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
}
#obstacleMoving > div {
  height: 100%;
  width: 33%;
  position: relative;
  bottom: 0;
}
#obstacleMoving > div > img:nth-child(1) {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: bottom;
  position: absolute;
  bottom: 0;
  z-index: +1;
}
#obstacleMoving > div > img:nth-child(2) {
  position: absolute;
  object-fit: contain;
  width: 200%;
  bottom: 0;
  height: initial;
 }
 

.BlindError {
  -webkit-animation: blink-1 1s ease-in-out both;
  animation: blink-1 1s ease-in-out both;
}

.GreenShadow {
  filter: drop-shadow(0px 0px 10px #008000) drop-shadow(0px 0px 5px #008000) !important ;
}
.RedShadowNoBlink{
  filter: drop-shadow(0px 0px 10px red) drop-shadow(0px 0px 5px red) !important;
  -webkit-animation: blink-1 0.6s ease-in-out infinite both;
  animation: blink-1 0.6s ease-in-out infinite both;
}
#buttonContainerBateau {
  
  height: 40px;
  width: 81px;
  position: absolute;
  left: 47.7%;
  transform: translateX(-50%);
  top: 23.7%;
  display: flex;
  justify-content: space-between;
}
#volantBateau {
  height: 150px;
  width: 194px;
  position: absolute;
  left: 48%;
  transform: translateX(-50%);
  top: 46.7%;
}
#volant {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: rotate(0deg);
  object-position: top center;
  transition: transform 2s cubic-bezier(.08,.82,.17,1) ;
}

#buttonContainerBateau > img {
  height: 100%;
  object-fit: contain ;
  border-radius: 25%;
}


#styleSeaContainer {
  height: calc(100% - 200px);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.absolute100x100 {
  height: 100%;
  width: 100%;
  position: absolute;
}

.relative100x100 {
  height: 100%;
  width: 100%;
  position: relative;
}


#styleSea {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: background 5s cubic-bezier(0.22, 0.61, 0.36, 1) 0s,
}

#styleWaterAnimation{
  height:  100% ;
  width:  100% ;
  position:  absolute ;
}

#styleIle {
  height: 200px;
  width: 100%;
  position: absolute;
  background-color: rgb(247, 220, 141);
  top: 0;
  left: 0;
}

#styleMontain {
  object-fit: contain;
  width: 100%;
  transform: scale(1) translateX(0px);
  position: absolute;
  bottom: 0;
  object-position: bottom;
  transform-origin: bottom center;
  transition: transform 5s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;

}

#styleMontainMenu {
  object-fit: contain;
  width: 100%;
transform: scale(3.9) translate(11.1%,28.1px);
position: absolute;
bottom: 0;
object-position: bottom;
transform-origin: bottom center;
}
#styleBateauContainer {
  height: 203px;
  width: 690px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
}

#styleWaterBateauAnimation {
  height: 100%;
  width: 100%;
  position: absolute;
  clip-path: polygon(48.99% 14.77%, 0.72% 98.52%, 100.58% 99.01%);

}

#styleObstacleContainer {
  height: 100px;
  width: 140%;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  animation : keyFrameObstacleAppear 3s cubic-bezier(.55,.09,.68,.53) ;
}

.obstacleAppear {
  animation-name: keyFrameObstacleAppear;
  animation-duration: 4s;
}
@keyframes keyFrameObstacleAppear {
  from {height: 0px;width: 0%;}
  to {height: 100px;width: 140%;}
}



#menuIleContainer {
  position: absolute;
  height: 25%;
  width: 72%;
  left: 16%;
}
#Meduse {
  position: absolute;
  right: 19%;
  height: 45%;
  bottom: 14%;

}
#Poisson {
  position: absolute;
  height: 41%;
  bottom: 7px;
}
#Rochers {
  position: absolute;
  right: 14px;
  height: 52%;
  bottom: 0;
}
#Sirene {
  position: absolute;
  height: 72%;
  right: 54%;
  top: 4%;
}

.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in {
  0% {
    filter: opacity(0);
  }
  100% {
    filter: opacity(1);
  }
}
@keyframes fade-in {
  0% {
    filter: opacity(0);
  }
  100% {
    filter: opacity(1);
  }
}


.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  /* padding-top: calc(591.44px / 1127.34px * 100%); */
  background: white;
  position: relative;
  
}
.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.echo::before, .echo::after {
  content : "";
  position: absolute;
  border: 3px solid white;
  opacity: 1;
  border-radius: 50%;
  left : 50%;
  top : 50% ;
  transform :  translate(-50%,-50%);
  animation: echo-animation 1s cubic-bezier(0,  0.2, 0.8, 1) infinite;
}
.echo::after {
  animation-delay: -0.5s;
}

@keyframes echo-animation {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}


.PortBlockLeft {
  position: absolute;
}
.platformContainerPhare {
  position: relative;
  /* bottom: 26px; */
  height: 130%;
  width: 100%;
}
.phareVertImage {
  object-fit: contain;
  position: absolute;
  bottom: 0;
  height: 160%;
}
.rocherPhareImage {
  object-fit: contain;
  position: absolute;
  bottom: 0;
  height: 100%;
}