.bateau_ancre_monde2 {
        height: 31%;
        position: absolute;
        object-fit: cover;
        bottom: 7%;
}

.bateau_bouee_monde2 {
    height: 15%;
    position: absolute;
    object-fit: cover;
    bottom: 14%;
    left: 59%;
}
.bateau_poisson_claire_monde2 {
    height: 15%;
    position: absolute;
    object-fit: cover;
    bottom: 20%;
    left: 0%;
}
.bateau_poisson_monde2 {
    height: 20%;
    position: absolute;
    object-fit: cover;
    bottom: 14%;
    left: 36%;
}
.bateau_poisson_vert_monde2 {
    height: 15%;
    position: absolute;
    object-fit: cover;
    bottom: 20%;
    left: 80%;
}
.bateau_poisson_plante_monde2 {
    height: 20%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    bottom: 0;
}

/* .transition-platform {
   transition:  left 2s ease, bottom 2s linear;
} */