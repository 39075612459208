.flex-2-items {
  display: flex;
  height: 30%;
  width: 100%;
  justify-content : center ;
  align-items: flex-end; 
  position: absolute;
  top: 31%;
}
.flex-2-items-choix {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
}
.item-flex-2-center {
  text-align: center ;
  margin: 0 auto;
  color : white ;
  text-shadow: 1px 2px 2px black;
 font-size: 20px;
  line-height: 32px;
  font-weight: 900
}
/* .item-flex-2 {
  width: 20%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

} */

.item-flex-2 {
  width: 20%;
  height: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

}

/* .item-flex-2 > img {
  width: 100%;
  max-height: 60%;
  min-height: 50%;
  object-fit: contain;
  object-position: bottom center;
  bottom: 0;
} */
.item-flex-2 > img {
  width: 100%;
  max-height: 80%;
  min-height: 70%;
  object-fit: contain;
  object-position: bottom center;
  bottom: 0;
}
.error-shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .remove-btn {
    margin-right: 0.5rem;
  }
  

  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in;
  }
  .item-exit {
    opacity: 0;
    display: none;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 50ms ease-in;
  }


  .armoireClock-outer {
    position: absolute;
    height: 45%;
    width: 16%;
    top: 38%;
  }

  .armoireClock-inner {
    position: relative;
    height: 100%;
    width: 100%;
    
  }
  .armoireWithClock {
    height: calc(100% - 100px);

  object-fit: contain;

  object-position: top left;
  }
  
  .background-choix {
    height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
    position: absolute;
    left : 0 ;
    top : 0 ;

}

.glow.culturelle{
  filter: drop-shadow(0px 0px 10px #652d9a) drop-shadow(0px 0px 10px #652d9a85);
}

.glow.sport {
  filter: drop-shadow(0px 0px 10px #158bc2) drop-shadow(0px 0px 10px #158bc277);
}

.shadowChoix.text.sport {
  text-shadow: -1px -1px 2px #158bc2,1px 1px 2px #158bc2 ;
}

.shadowChoix.text.culturelle {
  text-shadow: -1px -1px 2px #652d9a,1px 1px 2px #652d9a;
}