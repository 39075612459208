.DirectionButtons {
  position: absolute;
  height: 30%;
  width: 100%;
  bottom: 1%;
  justify-content: center;
  align-items: center;
}

#DirectionButtonsRight {
  position: absolute;
  height: 25%;
  width: 6%;
  top: 51%;
  left: 53.2%;
  z-index: 1;

}
#DirectionButtonsRight > img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 8%;
}

#DirectionButtonsLeft {
  position: absolute;
  height: 25%;
  width: 6%;
  left: 41.5%;
  top: 51%;
  z-index: 1;
}
#DirectionButtonsLeft > img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 8%;
}

#DirectionAvant {
  position: absolute;
  height: 19%;
  width: 7.5%;
  top: 50%;
  left: 88%;
  z-index: 1;
}
#DirectionAvant > img {
  position: absolute;
  height: 100%;
  width: 100%;
}

#CurseurAlt {
  height: 15px;
  width: 15px;
  position: relative;
  top: 50%;
  right: 3px;
  background: url(../../img/monde_av/curseur.png);
  transition: all 0.2s ease-in-out;
  transform: translateY(-75%);
}

#CurseurOx {
  height: 15px;
  width: 15px;
  position: relative;
  top: 50%;
  right: 3px;
  background: url(../../img/monde_av/curseur.png);
  transition: all 0.2s ease-in-out;
  transform: translateY(-75%);
}

#CurseurTemp {
  height: 15px;
  width: 15px;
  position: relative;
  top: 50%;
  right: 3px;
  background: url(../../img/monde_av/curseur.png);
  transition: all 0.2s ease-in-out;
  transform: translateY(-75%);
}

.ProgressBar {
  width: 23%;
  height: 3%;
  bottom: 59%;
  position: absolute;
  left: 87%;
  transform: rotate(270deg);
  z-index: 1;
}
.NinjaProgressBar {
  /* background-image: url("../../../assets/img/monde_cat/Ninja/battery.svg"); */
  width: 23%;
  height: 3%;
  bottom: 59%;
  position: absolute;
  left: 87%;
  transform: rotate(270deg);
  z-index: 1;
}
/* .loader {

  width: 78%;
  height: 18px;
  border-radius: 14px;
  border-bottom: 1px solid #696565;
  border-top: 1px solid #999;
  overflow: hidden;
  position: absolute;
  z-index: 9999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.loader.gray {
  background: #999;
} */

.progress-bar {
  height: inherit;
  width: 0%;
  border-radius: inherit;
  position: relative;
  overflow: hidden;
  line-height: 0.5 !important;
}

.progress-stripes {
  width: inherit;
  height: inherit;
  font-size: 180px;
  font-weight: bold;
  margin-top: -50px;
  letter-spacing: -14px;
}

.percentage {
  position: absolute;
  top: 6px;
  right: 5px;
  font-weight: bold;
  font-size: 12px;
}
.red .progress-bar {
  background: #029396;
}
.red .progress-stripes {
  color: #01bbbf;
}
.red .percentage {
  color: #eee;
}

.my_alt_container {
  width: 8%;
  border: 1px solid red;
}

/* <div class="loader">
        <div class="progress-bar"><div class="progress-stripes"></div><div class="percentage">0%</div></div>
    </div>  */

.stripes {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

/* jauge containers  */
.JaugeContainer {
  position: relative;
  margin: auto;
  height: 100%;
  width: 65%;
  top: 3%;
}
.EqAltContainer {
  position: relative;
  float: left;
  width: 30%;
  height: 100%;
}
.OxTempContainer {
  position: relative;
  float: right;
  width: 30%;
  height: 100%;
}
.Range {
  position: relative;
  float: left;
  width: 49%;
  height: 100%;
}
.TopPart {
  position: relative;
  margin: auto;
  height: 40%;
  width: 78%;
}
.Jauge {
  position: relative;
  float: left;
  margin: auto;
  height: 64%;
  width: 100%;
}
.TopPartImg {
  width: 100%;
  height: 100%;
}
.JaugeBottom {
  background: url(../../img/monde_av/range.png);
  background-size: contain;
  position: relative;
  margin: auto;
  height: 95%;
  width: 10%;
}
#JaugeBottomEq {
  position: relative;
  margin: auto;
  height: 100%;
  width: 10%;
  background-color: #3bdcbf;
}

.BoxShadow {
  position: absolute;
  height: 55px;
  width: 55px;
  top: 6.2px;
  border-radius: 50px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.RedShadow {
  border: 1.3px solid red;
  box-shadow: 0px 0px 10px 1px red inset;
  -webkit-animation: blink-1 0.6s ease-in-out infinite both;
  animation: blink-1 0.6s ease-in-out infinite both;
}

.OrangeShadow {
  border: 1.3px solid #ff9c22;
  box-shadow: 0px 0px 10px 1px #ff9c22 inset;
  -webkit-animation: blink-1 0.6s ease-in-out infinite both;
  animation: blink-1 0.6s ease-in-out infinite both;
}
.RedEq {
  background-color: red !important;
}
.OrangeEq {
  background-color: orange !important;
}
/* // create div
 // var mydiv = document.createElement("div")
 // mydiv.setAttribute("class","RedShadow");
 // a.appendChild(mydiv);
 // a.removeChild
  */
.blink-1 {
  -webkit-animation: blink-1 0.6s ease-in-out infinite both;
  animation: blink-1 0.6s ease-in-out infinite both;
}
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
