.info-planification {
    position: absolute;
    width: 100%;
    transform: translateY(-100%);
    top: 10px ;
    color: #667A91 ;
    border-top-right-radius: 20%;
    border-top-left-radius: 20%;
    background-color: white;
    z-index: -1;
    padding: 10px 0px 10px 3px ;
    height: 100px;
    font-size: 100%;
    display: flex;
    justify-content : space-evenly;
    flex-flow: column;
}
.info-planification.niveau1 {
    align-items: center;
}


.dispo,.time,.price {
    display: flex;
    align-items: center;
    margin-top: 1px;

    
}

.dispo.niveau1 {
    flex-flow: column;
}
.dispo > .text,.time > .text,.price > .text {
    width: 95%;
} 
.dispo.niveau1 > .text {
    width: 100%;
    font-size: 71%;
    text-align: center;
}
.dispo > .imgPlan  {
    background: url("/plan/timer.png")  center center no-repeat;
    background-size: contain;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 3px;
    box-sizing: border-box;
}
.time > .imgPlan {
    background: url("/plan/clock.png")  center center no-repeat;
    background-size: contain;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 3px;
}

.price > .imgPlan {
    background: url("/plan/price.png")  center center no-repeat;
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 3px;

}


   .Btn-valider{
    background: url("../../../../assets/img/monde_org/bt_valider.png")  center center no-repeat;
    background-size: contain;
    width: 95px;
    height: 37px;
    position: absolute;
    right: 115px;
    bottom: 109px;
    border-width: 0;
   }



.reposTimeSheetCells {
    background-color: #00000012;
}

.reposTimeSheetCellsInitial {
    background-color: initial ;
}

.reposPlan {  
    background-color: rgba(255,0,0,0.2) !important;
}

.erroritems {
    filter : drop-shadow(0px 0px 10px red) drop-shadow(0px 0px 5px red) ;
}
.Btn-valider-game2-monde2{

    background: url("../../../../assets/img/monde_ba/batiment/valider.png")  center center no-repeat;
    background-size: contain;
    width: 117px;
    height: 37px;
    position: absolute;
    right: 182px;
    bottom: 202px;
    border-width: 0;
   }


.BoardPlan {
    height: 59.6%;
    width: 47.1%;
    display: grid;
    grid-template-columns: repeat(7, 14.25%);
    grid-template-rows: repeat(18, 5.55%);
    position: relative;
    top: 49.88%;
    left: 53.200%;
    transform: translate(-50%, -50%);
   }

   .BoardGame2Monde2 {
    height: 48.6%;
    width: 60.1%;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 33.33%);
    position: relative;
    top: 31.88%;
    left: 45.3083%;
    transform: translate(-39%, -50%);
   }

   .itemContainerGame2.game2Monde2 {
        display: flex;
        left: 50%;
        position: absolute;
        height: 14%;
        width: 100%;
        bottom: 0;
        justify-content: center;
        align-items :center ;
        transform: translateX(-50%);
        
        background-color: #032944;
        
   }
   .itemContainerGame2.game2Monde2::before {
    content: "";
    display: block;
    position: absolute;
    height: 13px;
    width: 100%;
    top: 0;
    transform: translateY(-100%);
    background-color: #7ea0ae;

    
}

.item-description{
    color: white;
    font-size: 0.8em;
    width: calc(100% - 75px);
}