.worlds-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.icon-world {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.icon-worldBonus {
  height: 75%;
  width: 75%;
  object-fit: contain;
}
.bonus1 {
  position: absolute;
  top: 0;
  right: 0;
  height: 50%;
  width: 50%;
  transform: translate(100%, -100%);
}
.monde {
  position: absolute;
  height: 80px;
  width: 80px;
  background-color: #f9dd8f;
  border-radius: 50%;
  filter: grayscale(100%);
  transition: all 1s ease;
}
.mondeBonus {
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: #f9dd8f;
  border-radius: 50%;
  filter: grayscale(100%);
  transition: all 1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.monde.active {
  filter: grayscale(0%) drop-shadow(0px 0px 10px white);
}
.mondeBonus.active {
  filter: grayscale(0%) drop-shadow(0px 0px 10px white);
}
.monde1 {
  bottom: 3px;
  left: 15px;
}
.bonusCocktail1 {
  bottom: 72px;
  left: 68px;
}
.bonusCocktail2 {
  bottom: 473px;
  left: 471px;
}
.bonusZombie1 {
  bottom: 160px;
  left: 333px;
}
.bonusZombie2 {
  left: 314px;
  bottom: 351px;
}
.monde2 {
  top: 3px;
  left: 42%;
}
.monde3 {
  bottom: 29%;
  left: 38%;
}
.monde4 {
  bottom: 52%;
  left: 26%;
}

.lamp {
  position: absolute;
  height: 25%;
  width: 100%;
  display: inline-block;
  transform-origin: top center;
}

.bulb {
  fill: #fbfbfb;
  fill-opacity: 0.7;
}
@keyframes icon-ship {
  from {
    transform: rotateZ(-4deg);
  }
  to {
    transform: rotateZ(4deg);
  }
}

.icon-ship-animate {
  animation: icon-ship alternate linear 4s infinite;
}

.lamp-animated {
  animation: lamp 10s forwards;
}

.bulb-animated {
  animation: bulb 10s forwards;
}

@keyframes bulb {
  to {
    fill: #fff59d;
    fill-opacity: 1;
  }
}

@keyframes lamp {
  5% {
    transform: rotate(-45deg);
  }

  10% {
    transform: rotate(40deg);
  }

  15% {
    transform: rotate(-35deg);
  }

  25% {
    transform: rotate(30deg);
  }

  40% {
    transform: rotate(-25deg);
  }

  55% {
    transform: rotate(20deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  95% {
    transform: rotate(5deg);
  }

  97.5% {
    transform: rotate(-2.5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
