.itemContainer,.itemContainerGame2 {
display: flex;
left: 50%;
position: absolute;
height: 14%;
width: 100%;
bottom: 1%;
justify-content: center;
align-items :center ;
transform: translateX(-50%);

}

.itemContainer > div {
    height: 85%;
    width: 7%;

    margin: 0 5px;
    /* background-color: rgba(255, 255, 255, 0.5); */
    background-color: white;
    border-radius: 20%;
    /* overflow: hidden; */
}
.itemContainerGame2 > div {
    height: 85%;
    width: 7%;
    margin: 0 5px;
    border-radius: 20%;
}

.itemContainer > .empty,itemContainerGame2 > .empty {
    background-color: rgba(255, 255, 255, 0.5);

}
.itemContainer > div > img ,.itemContainerGame2 > div > img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 95%;
    width: 95%;
    object-fit: contain;
    object-position: center center;
}
.border20With-children, .border20With-children > div {
    border-radius: 20%;
}


.wrapper-item-svg > div{
    position: relative;
    height: 100%;
    width: 100%;

} 

.wrapper-item-svg > div > svg{
    position: relative;
    height: 100%;
    width: 100%;
} 
