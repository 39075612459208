.cls-1 {
  opacity: 0.9;
}

.cls-2 {
  fill: #fff;
}

.Genie-Container {
  background-color: #2f3031cf;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.box-Genie-container {
  position: relative;
  height: 31%;
  width: 62%;
  float: left;
  left: 17%;
  top: 3%;
  /* top: 9%; */
}

.box-Genie-container>svg {
  position: absolute;
}

.dialog-genie-container {
  line-height: 26px;
  color: #667a91;
  position: relative;
  top: 0;
  left: 0;
  padding-top: 4%;
  box-sizing: content-box;
  padding-left: 5%;
  padding-right: 12%;
  font-size: 150%;
}

.Genie-Container-img {
  float: right;
  object-fit: contain;
  width: 26%;
  position: relative;
  left: -5%;
  /* top: 8%; */
  top: 6%;
}

.btn-next-dialog {
  position: absolute;
  bottom: 0px;
  right: 14%;
  height: 27px;
  width: 70px;
  transform: translateY(-80%);
  z-index: 999;
  object-fit: contain;
  object-position: center center;
}

.button-next-dialog {
  position: absolute;
  bottom: 0px;
  right: 14%;
  border-radius: 8px;
  padding: 10px;
  font-size: 20px;
  height: 52px;
  background-color: #4b8cce12 ;
}
.Erreur {
  position: absolute;
  bottom: 6px;
  right: 12%;
  height: 13px;
  width: 25px;
  transform: translateY(-50%);
  z-index: 999;
  background-image: url("../img/button/skipmonde4.png");
  background-size: contain;
  background-repeat: no-repeat;
}