
.BackgroundContainer{
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  object-position: top center;
}

.obstacle-item {
  display: flex;
  height: 30%;
  width: 100%;
  position: absolute;
  top: 25%; 
}
.obstacle-item > div {
    display: flex;
    height: 100%;
    width: 50%;
    
}

.obstacle-item-flex-center {
    text-align: center ;
    max-width: 80%;
    margin: 0 auto;
    
}
#indication-item {
  display: flex;
  height: 45%;
  width: 100%;
  position: absolute;
  top: 17%;
}
#indication-item > div {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  float: left;

}

.indication-item-flex-center {
    text-align: center ;
    max-width: 80%;
    margin: 0 auto;
    
}
.indicationElement{
  width: 20%;
  height: 90%;
  position: relative;
  float: left;
  top: 6%;
  left: 40%;
  animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

}
.indicationFleche1{
  background-color: aliceblue;
  width: 100%;
  height: 50%;
  clip-path: polygon(100% 85%, 85% 100%, 50% 40%, 15% 100%, 0 85%, 50% 0);
  animation: fade-in 1s both;
}
.indicationFleche2{
  background-color: aliceblue;
  width: 100%;
  height: 50%;
  clip-path: polygon(100% 85%, 85% 100%, 50% 40%, 15% 100%, 0 85%, 50% 0);
  animation: fade-in 0.5s both;
}
.indicationRight {
  width: 20%;
  height: 90%;
  position: relative;
  float: left;
  top: 6%;
  left: 40%;
  transform: rotate(90deg);
 
}
.indicationLeft {
  transform: rotate(270deg);
  width: 20%;
  height: 90%;
  position: relative;
  float: left;
  top: 6%;
  left: 40%;
  
}

#GO{
  width: 100%;
  height: 100%;
  font-size: 93pt;
  color: white;
  z-index: 3;
  position: absolute;
  /* left: 25%; */
  /* top: 15.5%; */
  background-color: #00000073;
  /* box-shadow: 0px 0px 3px 0px white; */
  text-align: center;

}
.GOtext{
  top: 50%;
  position: relative;
  filter: drop-shadow(4px 4px 10px white);
  font-size: 180px;
  font-weight: 1000;
  animation: scale-in-centerGO 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

}

.error-color {
  filter: drop-shadow(0px 4px 4px red);
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.glow-answer {
  filter: drop-shadow(0px 4px 4px white);
}

.error-shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
.TutoObs{
  width: 300px;
  height: 244px;
  position: absolute;
  top: 100px;
  left: 100px;
  background : url("../../img/monde_av/Obstacles/nuage2.png") center no-repeat ;
  background-size: contain;
  z-index: -1;
  animation: scale-in-center 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.TutoObs1{
  width: 300px;
  height: 244px;
  position: absolute;
  top: 100px;
  left: 100px;
  background : url("../../img/monde_av/Obstacles/hel1.png") center no-repeat ;
  background-size: contain;
  z-index: -1;
  animation: scale-in-center 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.TutoObs2{
  width: 300px;
  height: 244px;
  position: absolute;
  top: 100px;
  left: 550px;
  background : url("../../img/monde_av/Obstacles/parachute1.png") center no-repeat ;
  background-size: contain;
  z-index: -1;
  animation: scale-in-center 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


.tutoavant{
  z-index: -1;
  animation: scale-in-center 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

#indication-itemtutoavant{
  width: 20%;
  height: 90%;
  position: relative;
  float: left;
  top: 6%;
  left: 40%;
  animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  z-index: -1;
}
#indication-itemtutoright{
  width: 20%;
  height: 90%;
  position: relative;
  float: left;
  top: 6%;
  left: 19%;
    transform: rotate(90deg);
    z-index: -1;
}
#indication-itemtutoleft{
  transform: rotate(270deg);
  width: 20%;
  height: 90%;
  position: relative;
  float: left;
  top: 6%;
  left: 1%;
  z-index: -1;
}

.equil{
  clip-path: ellipse(50% 50% at 50% 94%);
  background-image: linear-gradient(30deg, #3BDCD8, #BAFBDB); 
  width: 100%;
  height: 100%;
  position: relative;
  left: -2%;
  top: -45%;
	-webkit-animation: rotate-90-cw 6s ease-in-out infinite alternate-reverse both;
	        animation: rotate-90-cw 6s ease-in-out infinite alternate-reverse both;
  
}
.equil-container{
  clip-path: circle(50% at 50% 50%);
  background-color: #311142;
  width: 15%;
  height: 25%;
  position: absolute;
  left: 43.5%;
  top: -7%;
  
}
/* .rotate-90-cw {
	-webkit-animation: rotate-90-cw 0.4s ease-in-out infinite alternate-reverse both;
	        animation: rotate-90-cw 0.4s ease-in-out infinite alternate-reverse both;
} */
@-webkit-keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
}
@keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
}




.scale-in-centerGO {
  -webkit-animation: scale-in-centerGO 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: scale-in-centerGO 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes scale-in-centerGO {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}
@keyframes scale-in-centerGO {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

  /* #clock > ul li {
    line-height: 900%;
  
  }
  #clock > span  {
    color: #662962;

    font-size: 39px;
  } 
  #clock {
    text-align: center;
    width: 3.8%;
    height: 31%;
    position: absolute;
    bottom: 10px;
    left: 48%;
    background-color: white;
    border-radius: 5px;
    
  }  */

  .scale-in-center {
    -webkit-animation: scale-in-center 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: scale-in-center 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  .fade-in-bottom {
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  .fade-in {
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @-webkit-keyframes fade-in {
    0% {
      filter: opacity(0);
    }
    100% {
      filter: opacity(1);
    }
  }
  @keyframes fade-in {
    0% {
      filter: opacity(0);
    }
    100% {
      filter: opacity(1);
    }
  }
  .fade-in-left {
    -webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  .fade-in-right {
    -webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  @-webkit-keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
