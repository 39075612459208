#mainContainer1{
    height: 100%;
    width: 100%;
    position: relative;

}
.ClickForGame {
    position: absolute;
    object-fit: contain;
    object-position: center center;
}

#mainContainer1 > .AgendaContainer {
    position: absolute;
    top: 70%;
    left: 33%;
    height: 9%;
    width: 7%;
}
  
#mainContainer1 > .PlanificationContainer {
    position: absolute;
    height: 41%;
    width: 18%;
    top: 58%;
    left: 8%;
}

.AgendaContainer > div, .PlanificationContainer > div{
    position: relative;
    height: 100%;
    width: 100%;
}
.ClickForGame.Agenda {
    height: 100%;
    width: 100%;
}
.ClickForGame.Chevalet {
    height: 100%;
    width: 100%;
}
   
.ClickForGame:hover {
    cursor: pointer;  
}

.ClickForGame.Agenda + .Arrow, .ClickForGame.Chevalet + .Arrow {
    top: -50px;
    width: 100%;
    height: 50px;
    opacity: 0 ;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}





.ClickForGame.Necklace {
    height: 14%;
    width: 6%;
    top: 69%;
    left: 43%;
}

.ClickForGame.Danseuse {
    height: 28%;
    width: 9%;
    top: 54%;
    left: 50%;
}

#arrowAgenda{
    width: 2%;
    height: 7%;
    position: absolute;
    top: 61%;
    left: 35%;
    z-index: -1;

  }
  #arrowPlan{
    width: 2%;
    height: 7%;
    position: absolute;
    top: 48%;
    left: 16%;
    z-index: -1;

  }
  #arrowFlow{
    width: 2%;
    height: 7%;
    position: absolute;
    top: 58%;
    left: 44%;
    z-index: -1;
  }
  #arrowDans{
    width: 2%;
    height: 7%;
    position: absolute;
    top: 43%;
    left: 55%;
    z-index: -1
  }
.highlight{
    z-index: 3;
}