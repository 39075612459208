.Flower-Container{
    height: 70%;
    width: 70%;
    position: relative;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%)
}
.Flower-Items-Container{
    height: 100px;
    width: 450px;
    display: flex;
    margin: auto;
    position: relative;
    bottom: 4%;
    left: 5%;

}

.Flower-Item {
    height: 150px;
    width: 150px;
}
.RuleFlower-Container {
    display: flex;
    position: relative;
    align-items: center;
    background-color:
    whitesmoke;
    top: 90%;
    height: 100px!important;
    width: 350px!important;
    padding: 0 3%;
    margin: auto;
    -webkit-clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
    clip-path: polygon(7% 0, 100% 0%, 93% 100%, 0% 100%);
}


.RuleFlower-Text{
    font-size: 21px;
    color: #667a91;
    position: relative;
    left: 8%;
}
.RuleFlower-Indication{

    position: relative;
    left: 20%;
}
