.ninja-container {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  /* background-image: url("/img/ninja_back.jpg"); */
}

.curtainsZombie {
  width: 60%;
  height: 50%;
  position: absolute;
  display: flex;
  left: 26%;
  /* left: 21%; */
  top: 14%;
  z-index: 1;
}

.light {
  width: 20%;
  height: 34%;
  position: absolute;
  display: flex;
  left: 43%;
  top: 16%;
  -webkit-animation: flicker-in-1 5s linear infinite both;
  animation: flicker-in-1 5s linear infinite both;
  -moz-animation-delay: 4s;
  /* animation: blinker 2s linear infinite; */
}

.bedZombie {
  width: 38%;
  height: 25%;
  position: absolute;
  display: flex;
  left: -1%;
  top: 50%;
  z-index: 2;
}

.chairZombie {
  position: absolute;
  display: flex;
  left: 52%;
  top: 52%;
  z-index: 2;
  width: 65px;
  height: 133px;
}

.zombieObs1 {
  position: absolute;
  display: flex;
  left: 29%;
  top: 78%;
  z-index: 3;
  width: 13%;
  height: 19%;
}

.zombieObs2 {
  position: absolute;
  display: flex;
  left: 8%;
  top: 72%;
  z-index: 3;
  width: 35%;
  height: 25%;
}

.zombieObs3 {
  position: absolute;
  display: flex;
  left: 55%;
  top: 79%;
  z-index: 3;
  width: 41%;
  height: 21%;
}

.zombieClosetDoor {
  position: absolute;
  display: flex;
  left: 83%;
  top: 27%;
  z-index: 2;
  width: 7%;
  height: 47%;
}

.zombieCloset {
  position: absolute;
  display: flex;
  left: 76%;
  top: 25%;
  z-index: 1;
  width: 15%;
  height: 53%;
}

.zombieClothes {
  position: absolute;
  display: flex;
  left: 59%;
  top: 30%;
  z-index: 2;
  width: 10%;
  height: 44%;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@-webkit-keyframes flicker-in-1 {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  10.1% {
    opacity: 1;
  }

  10.2% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  20.1% {
    opacity: 1;
  }

  20.6% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  30.1% {
    opacity: 1;
  }

  30.5% {
    opacity: 1;
  }

  30.6% {
    opacity: 0;
  }

  45% {
    opacity: 0;
  }

  45.1% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  55.1% {
    opacity: 0;
  }

  57% {
    opacity: 0;
  }

  57.1% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  60.1% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  65.1% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  75.1% {
    opacity: 0;
  }

  77% {
    opacity: 0;
  }

  77.1% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  85.1% {
    opacity: 0;
  }

  86% {
    opacity: 0;
  }

  86.1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flicker-in-1 {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  10.1% {
    opacity: 1;
  }

  10.2% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  20.1% {
    opacity: 1;
  }

  20.6% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  30.1% {
    opacity: 1;
  }

  30.5% {
    opacity: 1;
  }

  30.6% {
    opacity: 0;
  }

  45% {
    opacity: 0;
  }

  45.1% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  55.1% {
    opacity: 0;
  }

  57% {
    opacity: 0;
  }

  57.1% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  60.1% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  65.1% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  75.1% {
    opacity: 0;
  }

  77% {
    opacity: 0;
  }

  77.1% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  85.1% {
    opacity: 0;
  }

  86% {
    opacity: 0;
  }

  86.1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.rain-box {
  overflow: hidden;
  top: 16%;
  left: 32%;
  display: block;
  position: absolute;
  width: 362px;
  height: 317px;
  z-index: 0;
}

.man-walk {
  width: 100px;
  height: 125px;
  position: absolute;
  top: 0;
  left: 50px;
  -webkit-animation: man-walk;
  animation: man-walk;
  animation-duration: 2s;
  /* animation-iteration-count: infinite; */
}

@keyframes man-walk {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(1.5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-1.5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.man-scale1 {
  width: 100px;
  height: 125px;

  animation-name: man-scale1;
  animation-duration: 5s;
  animation-timing-function: linear;
}

.man-scale2 {
  width: 100px;
  height: 125px;

  animation-name: man-scale2;
  animation-duration: 5s;
  animation-timing-function: linear;
}

.man-scale3 {
  width: 100px;
  height: 125px;

  animation-name: man-scale3;
  animation-duration: 5s;
  animation-timing-function: linear;
}

.man-scale4 {
  width: 100px;
  height: 125px;

  animation-name: man-scale4;
  animation-duration: 5s;
  animation-timing-function: linear;
}

.man-scale5 {
  width: 100px;
  height: 125px;

  animation-name: man-scale5;
  animation-duration: 5s;
  animation-timing-function: linear;
}

.man-scale6 {
  width: 100px;
  height: 125px;

  animation-name: man-scale6;
  animation-duration: 5s;
  animation-timing-function: linear;
}

.man-scale7 {
  width: 100px;
  height: 125px;

  animation-name: man-scale7;
  animation-duration: 5s;
  animation-timing-function: linear;
}

.man-scale8 {
  width: 100px;
  height: 125px;

  animation-name: man-scale8;
  animation-duration: 5s;
  animation-timing-function: linear;
}

/* define the animation */
/**** behind curtains *****/
@keyframes man-scale8 {
  0% {
    transform: translate(195px, 225px);
  }

  25% {
    transform: translate(195px, 225px);
  }

  50% {
    transform: translate(195px, 225px);
  }

  75% {
    transform: translate(195px, 225px);
  }

  100% {
    transform: translate(195px, 225px);
  }
}

/**** beside obstacle *****/
@keyframes man-scale7 {
  0% {
    transform: translate(150px, 420px);
  }

  25% {
    transform: translate(150px, 420px);
  }

  50% {
    transform: translate(150px, 420px);
  }

  75% {
    transform: translate(150px, 420px);
  }

  100% {
    transform: translate(150px, 420px);
  }
}

/**** on the window right *****/
@keyframes man-scale6 {
  0% {
    transform: translate(576px, 235px) scale(0.4);
  }

  25% {
    transform: translate(576px, 320px) scale(0.5);
  }

  50% {
    transform: translate(576px, 255px) scale(0.6);
  }

  75% {
    transform: translate(576px, 280px) scale(0.7);
  }

  100% {
    transform: translate(576px, 345px) scale(0.8);
  }
}

/**** on the window left *****/
@keyframes man-scale5 {
  0% {
    transform: translate(340px, 230px) scale(0.4);
  }

  25% {
    transform: translate(340px, 320px) scale(0.5);
  }

  50% {
    transform: translate(340px, 250px) scale(0.6);
  }

  75% {
    transform: translate(340px, 275px) scale(0.7);
  }

  100% {
    transform: translate(340px, 310px) scale(0.8);
  }
}

/**** on the center *****/
@keyframes man-scale4 {
  0% {
    transform: translate(445px, 285px) scale(0.4);
  }

  25% {
    transform: translate(445px, 320px) scale(0.5);
  }

  50% {
    transform: translate(445px, 345px) scale(0.6);
  }

  75% {
    transform: translate(445px, 375px) scale(0.7);
  }

  100% {
    transform: translate(445px, 390px) scale(0.8);
  }
}

/**** on the left *****/
@keyframes man-scale3 {
  0% {
    transform: translate(10px, 310px) scale(0.4);
  }

  25% {
    transform: translate(25px, 320px) scale(0.5);
  }

  50% {
    transform: translate(25px, 335px) scale(0.6);
  }

  75% {
    transform: translate(25px, 350px) scale(0.7);
  }

  100% {
    transform: translate(25px, 380px) scale(0.8);
  }
}

/**** on the right *****/
@keyframes man-scale2 {
  0% {
    transform: translate(835px, 310px) scale(0.4);
  }

  25% {
    transform: translate(820px, 335px) scale(0.5);
  }

  50% {
    transform: translate(820px, 350px) scale(0.6);
  }

  75% {
    transform: translate(820px, 370px) scale(0.7);
  }

  100% {
    transform: translate(820px, 395px) scale(0.8);
  }
}

/**** in the closet *****/
@keyframes man-scale1 {
  0% {
    transform: translate(745px, 255px) scale(0.4);
  }

  25% {
    transform: translate(715px, 265px) scale(0.5);
  }

  50% {
    transform: translate(715px, 285px) scale(0.6);
  }

  75% {
    transform: translate(715px, 320px) scale(0.7);
  }

  100% {
    transform: translate(715px, 350px) scale(0.8);
  }
}

.slide-right {
  -webkit-animation: slide-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-left {
  -webkit-animation: slide-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.zombie-item {
  width: 80px;
  height: 80px;
  position: absolute;
  display: block;
  filter: drop-shadow(2px 4px 6px white);
  z-index: 3;
}

.fade-in-fwd {
  -webkit-animation: fade-in-fwd 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-fwd {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.shakeCorrect,
.shakeCorrect:hover,
.shakeCorrect:focus {
  filter: drop-shadow(0px 0px 10px green) drop-shadow(0px 0px 5px green) !important;
}

@keyframes spaceboots {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -1px) rotate(-1deg);
  }

  20% {
    transform: translate(-1px, 1px) rotate(1deg);
  }

  30% {
    transform: translate(1px, 0px) rotate(0deg);
  }

  40% {
    transform: translate(0px, 1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(0px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(1px, 1px) rotate(1deg);
  }

  90% {
    transform: translate(0px, 1px) rotate(0deg);
  }

  100% {
    transform: translate(1px, 0px) rotate(-1deg);
  }
}

.shakeZ,
.shakeZ:hover,
.shakeZ:focus {
  filter: drop-shadow(0px 0px 10px red) drop-shadow(0px 0px 5px red) !important;
  /* transform: scale(1.7) !important; */
}

/*** objects' animations ***/
.zombieScaleUp {
  -webkit-animation: scale-up-center 4s cubic-bezier(0.39, 0.575, 0.565, 1) 3s both slide-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-up-center 4s cubic-bezier(0.39, 0.575, 0.565, 1) 3s both;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

/**** rain animation ****/
.rain {
  background: white;
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%);
  height: 50px;
  position: absolute;
  width: 2px;
}

.rain:nth-of-type(1) {
  animation-name: rain-1;

  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 52%;
  opacity: 0.44;
  top: -92%;
}

@keyframes rain-1 {
  0% {
    left: 52%;
    opacity: 0.44;
    top: -92%;
  }

  100% {
    opacity: 0;
    top: 132%;
  }
}

.rain:nth-of-type(2) {
  animation-name: rain-2;

  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 54%;
  opacity: 0.47;
  top: -80%;
}

@keyframes rain-2 {
  0% {
    left: 54%;
    opacity: 0.47;
    top: -80%;
  }

  100% {
    opacity: 0;
    top: 120%;
  }
}

.rain:nth-of-type(3) {
  animation-name: rain-3;
  animation-delay: 4s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 53%;
  opacity: 0.41;
  top: -68%;
}

@keyframes rain-3 {
  0% {
    left: 53%;
    opacity: 0.41;
    top: -68%;
  }

  100% {
    opacity: 0;
    top: 108%;
  }
}

.rain:nth-of-type(4) {
  animation-name: rain-4;
  animation-delay: 0s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 66%;
  opacity: 0.43;
  top: -93%;
}

@keyframes rain-4 {
  0% {
    left: 66%;
    opacity: 0.43;
    top: -93%;
  }

  100% {
    opacity: 0;
    top: 133%;
  }
}

.rain:nth-of-type(5) {
  animation-name: rain-5;
  animation-delay: 7s;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  left: 39%;
  opacity: 0.31;
  top: -68%;
}

@keyframes rain-5 {
  0% {
    left: 39%;
    opacity: 0.31;
    top: -68%;
  }

  100% {
    opacity: 0;
    top: 108%;
  }
}

.rain:nth-of-type(6) {
  animation-name: rain-6;
  animation-delay: 0s;
  animation-duration: 6 s;
  animation-iteration-count: infinite;
  left: 90%;
  opacity: 0.54;
  top: -77%;
}

@keyframes rain-6 {
  0% {
    left: 90%;
    opacity: 0.54;
    top: -77%;
  }

  100% {
    opacity: 0;
    top: 117%;
  }
}

.rain:nth-of-type(7) {
  animation-name: rain-7;
  animation-delay: 19s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 50%;
  opacity: 0.31;
  top: -61%;
}

@keyframes rain-7 {
  0% {
    left: 50%;
    opacity: 0.31;
    top: -61%;
  }

  100% {
    opacity: 0;
    top: 101%;
  }
}

.rain:nth-of-type(8) {
  animation-name: rain-8;
  animation-delay: 4s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 96%;
  opacity: 0.4;
  top: -91%;
}

@keyframes rain-8 {
  0% {
    left: 96%;
    opacity: 0.4;
    top: -91%;
  }

  100% {
    opacity: 0;
    top: 131%;
  }
}

.rain:nth-of-type(9) {
  animation-name: rain-9;
  animation-delay: 15s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 90%;
  opacity: 0.39;
  top: -97%;
}

@keyframes rain-9 {
  0% {
    left: 90%;
    opacity: 0.39;
    top: -97%;
  }

  100% {
    opacity: 0;
    top: 137%;
  }
}

.rain:nth-of-type(10) {
  animation-name: rain-10;
  animation-delay: 19s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 27%;
  opacity: 0.32;
  top: -64%;
}

@keyframes rain-10 {
  0% {
    left: 27%;
    opacity: 0.32;
    top: -64%;
  }

  100% {
    opacity: 0;
    top: 104%;
  }
}

.rain:nth-of-type(11) {
  animation-name: rain-11;
  animation-delay: 6s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 29%;
  opacity: 0.5;
  top: -63%;
}

@keyframes rain-11 {
  0% {
    left: 29%;
    opacity: 0.5;
    top: -63%;
  }

  100% {
    opacity: 0;
    top: 103%;
  }
}

.rain:nth-of-type(12) {
  animation-name: rain-12;
  animation-delay: 9s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 93%;
  opacity: 0.58;
  top: -70%;
}

@keyframes rain-12 {
  0% {
    left: 93%;
    opacity: 0.58;
    top: -70%;
  }

  100% {
    opacity: 0;
    top: 110%;
  }
}

.rain:nth-of-type(13) {
  animation-name: rain-13;
  animation-delay: 16s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 64%;
  opacity: 0.43;
  top: -93%;
}

@keyframes rain-13 {
  0% {
    left: 64%;
    opacity: 0.43;
    top: -93%;
  }

  100% {
    opacity: 0;
    top: 133%;
  }
}

.rain:nth-of-type(14) {
  animation-name: rain-14;
  animation-delay: 7s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 74%;
  opacity: 0.41;
  top: -59%;
}

@keyframes rain-14 {
  0% {
    left: 74%;
    opacity: 0.41;
    top: -59%;
  }

  100% {
    opacity: 0;
    top: 99%;
  }
}

.rain:nth-of-type(15) {
  animation-name: rain-15;
  animation-delay: 17s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 23%;
  opacity: 0.31;
  top: -93%;
}

@keyframes rain-15 {
  0% {
    left: 23%;
    opacity: 0.31;
    top: -93%;
  }

  100% {
    opacity: 0;
    top: 133%;
  }
}

.rain:nth-of-type(16) {
  animation-name: rain-16;
  animation-delay: 9s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 9%;
  opacity: 0.49;
  top: -91%;
}

@keyframes rain-16 {
  0% {
    left: 9%;
    opacity: 0.49;
    top: -91%;
  }

  100% {
    opacity: 0;
    top: 131%;
  }
}

.rain:nth-of-type(17) {
  animation-name: rain-17;
  animation-delay: 6s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 45%;
  opacity: 0.55;
  top: -73%;
}

@keyframes rain-17 {
  0% {
    left: 45%;
    opacity: 0.55;
    top: -73%;
  }

  100% {
    opacity: 0;
    top: 113%;
  }
}

.rain:nth-of-type(18) {
  animation-name: rain-18;
  animation-delay: 18s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 17%;
  opacity: 0.47;
  top: -84%;
}

@keyframes rain-18 {
  0% {
    left: 17%;
    opacity: 0.47;
    top: -84%;
  }

  100% {
    opacity: 0;
    top: 124%;
  }
}

.rain:nth-of-type(19) {
  animation-name: rain-19;
  animation-delay: 6s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 97%;
  opacity: 0.42;
  top: -89%;
}

@keyframes rain-19 {
  0% {
    left: 97%;
    opacity: 0.42;
    top: -89%;
  }

  100% {
    opacity: 0;
    top: 129%;
  }
}

.rain:nth-of-type(20) {
  animation-name: rain-20;
  animation-delay: 18s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 53%;
  opacity: 0.31;
  top: -57%;
}

@keyframes rain-20 {
  0% {
    left: 53%;
    opacity: 0.31;
    top: -57%;
  }

  100% {
    opacity: 0;
    top: 97%;
  }
}

.rain:nth-of-type(21) {
  animation-name: rain-21;
  animation-delay: 17s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 10%;
  opacity: 0.41;
  top: -73%;
}

@keyframes rain-21 {
  0% {
    left: 10%;
    opacity: 0.41;
    top: -73%;
  }

  100% {
    opacity: 0;
    top: 113%;
  }
}

.rain:nth-of-type(22) {
  animation-name: rain-22;
  animation-delay: 11s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 22%;
  opacity: 0.54;
  top: -77%;
}

@keyframes rain-22 {
  0% {
    left: 22%;
    opacity: 0.54;
    top: -77%;
  }

  100% {
    opacity: 0;
    top: 117%;
  }
}

.rain:nth-of-type(23) {
  animation-name: rain-23;
  animation-delay: 3s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 36%;
  opacity: 0.58;
  top: -51%;
}

@keyframes rain-23 {
  0% {
    left: 36%;
    opacity: 0.58;
    top: -51%;
  }

  100% {
    opacity: 0;
    top: 91%;
  }
}

.rain:nth-of-type(24) {
  animation-name: rain-24;
  animation-delay: 10s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 5%;
  opacity: 0.57;
  top: -61%;
}

@keyframes rain-24 {
  0% {
    left: 5%;
    opacity: 0.57;
    top: -61%;
  }

  100% {
    opacity: 0;
    top: 101%;
  }
}

.rain:nth-of-type(25) {
  animation-name: rain-25;
  animation-delay: 17s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 51%;
  opacity: 0.49;
  top: -92%;
}

@keyframes rain-25 {
  0% {
    left: 51%;
    opacity: 0.49;
    top: -92%;
  }

  100% {
    opacity: 0;
    top: 132%;
  }
}

.rain:nth-of-type(26) {
  animation-name: rain-26;
  animation-delay: 0s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 74%;
  opacity: 0.53;
  top: -79%;
}

@keyframes rain-26 {
  0% {
    left: 74%;
    opacity: 0.53;
    top: -79%;
  }

  100% {
    opacity: 0;
    top: 119%;
  }
}

.rain:nth-of-type(27) {
  animation-name: rain-27;
  animation-delay: 8s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 41%;
  opacity: 0.6;
  top: -62%;
}

@keyframes rain-27 {
  0% {
    left: 41%;
    opacity: 0.6;
    top: -62%;
  }

  100% {
    opacity: 0;
    top: 102%;
  }
}

.rain:nth-of-type(28) {
  animation-name: rain-28;
  animation-delay: 0s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 23%;
  opacity: 0.5;
  top: -65%;
}

@keyframes rain-28 {
  0% {
    left: 23%;
    opacity: 0.5;
    top: -65%;
  }

  100% {
    opacity: 0;
    top: 105%;
  }
}

.rain:nth-of-type(29) {
  animation-name: rain-29;
  animation-delay: 15s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 39%;
  opacity: 0.4;
  top: -95%;
}

@keyframes rain-29 {
  0% {
    left: 39%;
    opacity: 0.4;
    top: -95%;
  }

  100% {
    opacity: 0;
    top: 135%;
  }
}

.rain:nth-of-type(30) {
  animation-name: rain-30;
  animation-delay: 16s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 28%;
  opacity: 0.48;
  top: -83%;
}

@keyframes rain-30 {
  0% {
    left: 28%;
    opacity: 0.48;
    top: -83%;
  }

  100% {
    opacity: 0;
    top: 123%;
  }
}

.rain:nth-of-type(31) {
  animation-name: rain-31;
  /*   */
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 11%;
  opacity: 0.35;
  top: -90%;
}

@keyframes rain-31 {
  0% {
    left: 11%;
    opacity: 0.35;
    top: -90%;
  }

  100% {
    opacity: 0;
    top: 130%;
  }
}

.rain:nth-of-type(32) {
  animation-name: rain-32;
  animation-delay: 5s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 40%;
  opacity: 0.46;
  top: -75%;
}

@keyframes rain-32 {
  0% {
    left: 40%;
    opacity: 0.46;
    top: -75%;
  }

  100% {
    opacity: 0;
    top: 115%;
  }
}

.rain:nth-of-type(33) {
  animation-name: rain-33;
  animation-delay: 17s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 65%;
  opacity: 0.39;
  top: -82%;
}

@keyframes rain-33 {
  0% {
    left: 65%;
    opacity: 0.39;
    top: -82%;
  }

  100% {
    opacity: 0;
    top: 122%;
  }
}

.rain:nth-of-type(34) {
  animation-name: rain-34;
  /*   */
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 85%;
  opacity: 0.5;
  top: -94%;
}

@keyframes rain-34 {
  0% {
    left: 85%;
    opacity: 0.5;
    top: -94%;
  }

  100% {
    opacity: 0;
    top: 134%;
  }
}

.rain:nth-of-type(35) {
  animation-name: rain-35;
  animation-delay: 9s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 67%;
  opacity: 0.4;
  top: -73%;
}

@keyframes rain-35 {
  0% {
    left: 67%;
    opacity: 0.4;
    top: -73%;
  }

  100% {
    opacity: 0;
    top: 113%;
  }
}

.rain:nth-of-type(36) {
  animation-name: rain-36;
  animation-delay: 13s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 16%;
  opacity: 0.39;
  top: -81%;
}

@keyframes rain-36 {
  0% {
    left: 16%;
    opacity: 0.39;
    top: -81%;
  }

  100% {
    opacity: 0;
    top: 121%;
  }
}

.rain:nth-of-type(37) {
  animation-name: rain-37;
  animation-delay: 9s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 98%;
  opacity: 0.44;
  top: -100%;
}

@keyframes rain-37 {
  0% {
    left: 98%;
    opacity: 0.44;
    top: -100%;
  }

  100% {
    opacity: 0;
    top: 140%;
  }
}

.rain:nth-of-type(38) {
  animation-name: rain-38;
  animation-delay: 7s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 51%;
  opacity: 0.37;
  top: -74%;
}

@keyframes rain-38 {
  0% {
    left: 51%;
    opacity: 0.37;
    top: -74%;
  }

  100% {
    opacity: 0;
    top: 114%;
  }
}

.rain:nth-of-type(39) {
  animation-name: rain-39;
  animation-delay: 6s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 82%;
  opacity: 0.38;
  top: -99%;
}

@keyframes rain-39 {
  0% {
    left: 82%;
    opacity: 0.38;
    top: -99%;
  }

  100% {
    opacity: 0;
    top: 139%;
  }
}

.rain:nth-of-type(40) {
  animation-name: rain-40;
  animation-delay: 12s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 53%;
  opacity: 0.39;
  top: -99%;
}

@keyframes rain-40 {
  0% {
    left: 53%;
    opacity: 0.39;
    top: -99%;
  }

  100% {
    opacity: 0;
    top: 139%;
  }
}

.rain:nth-of-type(41) {
  animation-name: rain-41;
  animation-delay: 0s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 58%;
  opacity: 0.6;
  top: -59%;
}

@keyframes rain-41 {
  0% {
    left: 58%;
    opacity: 0.6;
    top: -59%;
  }

  100% {
    opacity: 0;
    top: 99%;
  }
}

.rain:nth-of-type(42) {
  animation-name: rain-42;
  animation-delay: 2s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 17%;
  opacity: 0.46;
  top: -95%;
}

@keyframes rain-42 {
  0% {
    left: 17%;
    opacity: 0.46;
    top: -95%;
  }

  100% {
    opacity: 0;
    top: 135%;
  }
}

.rain:nth-of-type(43) {
  animation-name: rain-43;
  animation-delay: 7s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 24%;
  opacity: 0.54;
  top: -97%;
}

@keyframes rain-43 {
  0% {
    left: 24%;
    opacity: 0.54;
    top: -97%;
  }

  100% {
    opacity: 0;
    top: 137%;
  }
}

.rain:nth-of-type(44) {
  animation-name: rain-44;
  animation-delay: 5s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 14%;
  opacity: 0.41;
  top: -89%;
}

@keyframes rain-44 {
  0% {
    left: 14%;
    opacity: 0.41;
    top: -89%;
  }

  100% {
    opacity: 0;
    top: 129%;
  }
}

.rain:nth-of-type(45) {
  animation-name: rain-45;
  animation-delay: 14s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 22%;
  opacity: 0.51;
  top: -95%;
}

@keyframes rain-45 {
  0% {
    left: 22%;
    opacity: 0.51;
    top: -95%;
  }

  100% {
    opacity: 0;
    top: 135%;
  }
}

.rain:nth-of-type(46) {
  animation-name: rain-46;
  animation-delay: 14s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 89%;
  opacity: 0.33;
  top: -93%;
}

@keyframes rain-46 {
  0% {
    left: 89%;
    opacity: 0.33;
    top: -93%;
  }

  100% {
    opacity: 0;
    top: 133%;
  }
}

.rain:nth-of-type(47) {
  animation-name: rain-47;
  animation-delay: 17s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 74%;
  opacity: 0.31;
  top: -61%;
}

@keyframes rain-47 {
  0% {
    left: 74%;
    opacity: 0.31;
    top: -61%;
  }

  100% {
    opacity: 0;
    top: 101%;
  }
}

.rain:nth-of-type(48) {
  animation-name: rain-48;
  animation-delay: 6s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 100%;
  opacity: 0.43;
  top: -85%;
}

@keyframes rain-48 {
  0% {
    left: 100%;
    opacity: 0.43;
    top: -85%;
  }

  100% {
    opacity: 0;
    top: 125%;
  }
}

.rain:nth-of-type(49) {
  animation-name: rain-49;
  animation-delay: 13s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 58%;
  opacity: 0.58;
  top: -84%;
}

@keyframes rain-49 {
  0% {
    left: 58%;
    opacity: 0.58;
    top: -84%;
  }

  100% {
    opacity: 0;
    top: 124%;
  }
}

.rain:nth-of-type(50) {
  animation-name: rain-50;
  animation-delay: 14s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 85%;
  opacity: 0.33;
  top: -100%;
}

@keyframes rain-50 {
  0% {
    left: 85%;
    opacity: 0.33;
    top: -100%;
  }

  100% {
    opacity: 0;
    top: 140%;
  }
}

.rain:nth-of-type(51) {
  animation-name: rain-51;
  animation-delay: 17s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 91%;
  opacity: 0.37;
  top: -69%;
}

@keyframes rain-51 {
  0% {
    left: 91%;
    opacity: 0.37;
    top: -69%;
  }

  100% {
    opacity: 0;
    top: 109%;
  }
}

.rain:nth-of-type(52) {
  animation-name: rain-52;
  animation-delay: 15s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 61%;
  opacity: 0.53;
  top: -71%;
}

@keyframes rain-52 {
  0% {
    left: 61%;
    opacity: 0.53;
    top: -71%;
  }

  100% {
    opacity: 0;
    top: 111%;
  }
}

.rain:nth-of-type(53) {
  animation-name: rain-53;
  animation-delay: 4s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 36%;
  opacity: 0.42;
  top: -67%;
}

@keyframes rain-53 {
  0% {
    left: 36%;
    opacity: 0.42;
    top: -67%;
  }

  100% {
    opacity: 0;
    top: 107%;
  }
}

.rain:nth-of-type(54) {
  animation-name: rain-54;
  animation-delay: 19s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 8%;
  opacity: 0.46;
  top: -98%;
}

@keyframes rain-54 {
  0% {
    left: 8%;
    opacity: 0.46;
    top: -98%;
  }

  100% {
    opacity: 0;
    top: 138%;
  }
}

.rain:nth-of-type(55) {
  animation-name: rain-55;
  animation-delay: 3s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 94%;
  opacity: 0.31;
  top: -54%;
}

@keyframes rain-55 {
  0% {
    left: 94%;
    opacity: 0.31;
    top: -54%;
  }

  100% {
    opacity: 0;
    top: 94%;
  }
}

.rain:nth-of-type(56) {
  animation-name: rain-56;
  animation-delay: 19s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 94%;
  opacity: 0.43;
  top: -66%;
}

@keyframes rain-56 {
  0% {
    left: 94%;
    opacity: 0.43;
    top: -66%;
  }

  100% {
    opacity: 0;
    top: 106%;
  }
}

.rain:nth-of-type(57) {
  animation-name: rain-57;
  animation-delay: 2s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 100%;
  opacity: 0.5;
  top: -56%;
}

@keyframes rain-57 {
  0% {
    left: 100%;
    opacity: 0.5;
    top: -56%;
  }

  100% {
    opacity: 0;
    top: 96%;
  }
}

.rain:nth-of-type(58) {
  animation-name: rain-58;
  animation-delay: 14s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 57%;
  opacity: 0.34;
  top: -70%;
}

@keyframes rain-58 {
  0% {
    left: 57%;
    opacity: 0.34;
    top: -70%;
  }

  100% {
    opacity: 0;
    top: 110%;
  }
}

.rain:nth-of-type(59) {
  animation-name: rain-59;
  animation-delay: 6s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 80%;
  opacity: 0.38;
  top: -66%;
}

@keyframes rain-59 {
  0% {
    left: 80%;
    opacity: 0.38;
    top: -66%;
  }

  100% {
    opacity: 0;
    top: 106%;
  }
}

.rain:nth-of-type(60) {
  animation-name: rain-60;
  animation-delay: 14s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 43%;
  opacity: 0.34;
  top: -70%;
}

@keyframes rain-60 {
  0% {
    left: 43%;
    opacity: 0.34;
    top: -70%;
  }

  100% {
    opacity: 0;
    top: 110%;
  }
}

.rain:nth-of-type(61) {
  animation-name: rain-61;
  animation-delay: 2s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 50%;
  opacity: 0.31;
  top: -53%;
}

@keyframes rain-61 {
  0% {
    left: 50%;
    opacity: 0.31;
    top: -53%;
  }

  100% {
    opacity: 0;
    top: 93%;
  }
}

.rain:nth-of-type(62) {
  animation-name: rain-62;
  animation-delay: 0s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 86%;
  opacity: 0.48;
  top: -73%;
}

@keyframes rain-62 {
  0% {
    left: 86%;
    opacity: 0.48;
    top: -73%;
  }

  100% {
    opacity: 0;
    top: 113%;
  }
}

.rain:nth-of-type(63) {
  animation-name: rain-63;
  animation-delay: 12s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 54%;
  opacity: 0.48;
  top: -73%;
}

@keyframes rain-63 {
  0% {
    left: 54%;
    opacity: 0.48;
    top: -73%;
  }

  100% {
    opacity: 0;
    top: 113%;
  }
}

.rain:nth-of-type(64) {
  animation-name: rain-64;
  animation-delay: 15s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 80%;
  opacity: 0.32;
  top: -88%;
}

@keyframes rain-64 {
  0% {
    left: 80%;
    opacity: 0.32;
    top: -88%;
  }

  100% {
    opacity: 0;
    top: 128%;
  }
}

.rain:nth-of-type(65) {
  animation-name: rain-65;
  animation-delay: 3s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 38%;
  opacity: 0.37;
  top: -80%;
}

@keyframes rain-65 {
  0% {
    left: 38%;
    opacity: 0.37;
    top: -80%;
  }

  100% {
    opacity: 0;
    top: 120%;
  }
}

.rain:nth-of-type(66) {
  animation-name: rain-66;
  animation-delay: 16s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 92%;
  opacity: 0.46;
  top: -88%;
}

@keyframes rain-66 {
  0% {
    left: 92%;
    opacity: 0.46;
    top: -88%;
  }

  100% {
    opacity: 0;
    top: 128%;
  }
}

.rain:nth-of-type(67) {
  animation-name: rain-67;
  animation-delay: 15s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 15%;
  opacity: 0.38;
  top: -79%;
}

@keyframes rain-67 {
  0% {
    left: 15%;
    opacity: 0.38;
    top: -79%;
  }

  100% {
    opacity: 0;
    top: 119%;
  }
}

.rain:nth-of-type(68) {
  animation-name: rain-68;
  animation-delay: 4s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 12%;
  opacity: 0.34;
  top: -58%;
}

@keyframes rain-68 {
  0% {
    left: 12%;
    opacity: 0.34;
    top: -58%;
  }

  100% {
    opacity: 0;
    top: 98%;
  }
}

.rain:nth-of-type(69) {
  animation-name: rain-69;
  animation-delay: 16s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 93%;
  opacity: 0.5;
  top: -77%;
}

@keyframes rain-69 {
  0% {
    left: 93%;
    opacity: 0.5;
    top: -77%;
  }

  100% {
    opacity: 0;
    top: 117%;
  }
}

.rain:nth-of-type(70) {
  animation-name: rain-70;
  animation-delay: 6s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 13%;
  opacity: 0.55;
  top: -58%;
}

@keyframes rain-70 {
  0% {
    left: 13%;
    opacity: 0.55;
    top: -58%;
  }

  100% {
    opacity: 0;
    top: 98%;
  }
}

.rain:nth-of-type(71) {
  animation-name: rain-71;
  animation-delay: 16s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 4%;
  opacity: 0.51;
  top: -96%;
}

@keyframes rain-71 {
  0% {
    left: 4%;
    opacity: 0.51;
    top: -96%;
  }

  100% {
    opacity: 0;
    top: 136%;
  }
}

.rain:nth-of-type(72) {
  animation-name: rain-72;
  animation-delay: 11s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 90%;
  opacity: 0.59;
  top: -57%;
}

@keyframes rain-72 {
  0% {
    left: 90%;
    opacity: 0.59;
    top: -57%;
  }

  100% {
    opacity: 0;
    top: 97%;
  }
}

.rain:nth-of-type(73) {
  animation-name: rain-73;
  animation-delay: 19s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 76%;
  opacity: 0.56;
  top: -76%;
}

@keyframes rain-73 {
  0% {
    left: 76%;
    opacity: 0.56;
    top: -76%;
  }

  100% {
    opacity: 0;
    top: 116%;
  }
}

.rain:nth-of-type(74) {
  animation-name: rain-74;
  animation-delay: 12s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 48%;
  opacity: 0.56;
  top: -96%;
}

@keyframes rain-74 {
  0% {
    left: 48%;
    opacity: 0.56;
    top: -96%;
  }

  100% {
    opacity: 0;
    top: 136%;
  }
}

.rain:nth-of-type(75) {
  animation-name: rain-75;
  animation-delay: 17s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 86%;
  opacity: 0.46;
  top: -98%;
}

@keyframes rain-75 {
  0% {
    left: 86%;
    opacity: 0.46;
    top: -98%;
  }

  100% {
    opacity: 0;
    top: 138%;
  }
}

.rain:nth-of-type(76) {
  animation-name: rain-76;
  animation-delay: 15s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 60%;
  opacity: 0.55;
  top: -56%;
}

@keyframes rain-76 {
  0% {
    left: 60%;
    opacity: 0.55;
    top: -56%;
  }

  100% {
    opacity: 0;
    top: 96%;
  }
}

.rain:nth-of-type(77) {
  animation-name: rain-77;
  animation-delay: 3s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 12%;
  opacity: 0.44;
  top: -71%;
}

@keyframes rain-77 {
  0% {
    left: 12%;
    opacity: 0.44;
    top: -71%;
  }

  100% {
    opacity: 0;
    top: 111%;
  }
}

.rain:nth-of-type(78) {
  animation-name: rain-78;
  animation-delay: 13s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 29%;
  opacity: 0.43;
  top: -81%;
}

@keyframes rain-78 {
  0% {
    left: 29%;
    opacity: 0.43;
    top: -81%;
  }

  100% {
    opacity: 0;
    top: 121%;
  }
}

.rain:nth-of-type(79) {
  animation-name: rain-79;
  animation-delay: 2s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 37%;
  opacity: 0.53;
  top: -60%;
}

@keyframes rain-79 {
  0% {
    left: 37%;
    opacity: 0.53;
    top: -60%;
  }

  100% {
    opacity: 0;
    top: 100%;
  }
}

.rain:nth-of-type(80) {
  animation-name: rain-80;
  animation-delay: 14s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 41%;
  opacity: 0.48;
  top: -72%;
}

@keyframes rain-80 {
  0% {
    left: 41%;
    opacity: 0.48;
    top: -72%;
  }

  100% {
    opacity: 0;
    top: 112%;
  }
}

.rain:nth-of-type(81) {
  animation-name: rain-81;
  animation-delay: 17s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 2%;
  opacity: 0.58;
  top: -89%;
}

@keyframes rain-81 {
  0% {
    left: 2%;
    opacity: 0.58;
    top: -89%;
  }

  100% {
    opacity: 0;
    top: 129%;
  }
}

.rain:nth-of-type(82) {
  animation-name: rain-82;
  animation-delay: 17s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 59%;
  opacity: 0.31;
  top: -77%;
}

@keyframes rain-82 {
  0% {
    left: 59%;
    opacity: 0.31;
    top: -77%;
  }

  100% {
    opacity: 0;
    top: 117%;
  }
}

.rain:nth-of-type(83) {
  animation-name: rain-83;
  animation-delay: 16s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 79%;
  opacity: 0.54;
  top: -98%;
}

@keyframes rain-83 {
  0% {
    left: 79%;
    opacity: 0.54;
    top: -98%;
  }

  100% {
    opacity: 0;
    top: 138%;
  }
}

.rain:nth-of-type(84) {
  animation-name: rain-84;
  animation-delay: 10s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 14%;
  opacity: 0.49;
  top: -93%;
}

@keyframes rain-84 {
  0% {
    left: 14%;
    opacity: 0.49;
    top: -93%;
  }

  100% {
    opacity: 0;
    top: 133%;
  }
}

.rain:nth-of-type(85) {
  animation-name: rain-85;
  animation-delay: 18s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 87%;
  opacity: 0.34;
  top: -52%;
}

@keyframes rain-85 {
  0% {
    left: 87%;
    opacity: 0.34;
    top: -52%;
  }

  100% {
    opacity: 0;
    top: 92%;
  }
}

.rain:nth-of-type(86) {
  animation-name: rain-86;
  animation-delay: 4s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 66%;
  opacity: 0.59;
  top: -80%;
}

@keyframes rain-86 {
  0% {
    left: 66%;
    opacity: 0.59;
    top: -80%;
  }

  100% {
    opacity: 0;
    top: 120%;
  }
}

.rain:nth-of-type(87) {
  animation-name: rain-87;
  animation-delay: 16s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 59%;
  opacity: 0.45;
  top: -77%;
}

@keyframes rain-87 {
  0% {
    left: 59%;
    opacity: 0.45;
    top: -77%;
  }

  100% {
    opacity: 0;
    top: 117%;
  }
}

.rain:nth-of-type(88) {
  animation-name: rain-88;
  animation-delay: 3s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 19%;
  opacity: 0.51;
  top: -71%;
}

@keyframes rain-88 {
  0% {
    left: 19%;
    opacity: 0.51;
    top: -71%;
  }

  100% {
    opacity: 0;
    top: 111%;
  }
}

.rain:nth-of-type(89) {
  animation-name: rain-89;
  animation-delay: 16s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 41%;
  opacity: 0.55;
  top: -57%;
}

@keyframes rain-89 {
  0% {
    left: 41%;
    opacity: 0.55;
    top: -57%;
  }

  100% {
    opacity: 0;
    top: 97%;
  }
}

.rain:nth-of-type(90) {
  animation-name: rain-90;
  animation-delay: 19s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 78%;
  opacity: 0.59;
  top: -53%;
}

@keyframes rain-90 {
  0% {
    left: 78%;
    opacity: 0.59;
    top: -53%;
  }

  100% {
    opacity: 0;
    top: 93%;
  }
}

.rain:nth-of-type(91) {
  animation-name: rain-91;
  animation-delay: 11s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 92%;
  opacity: 0.45;
  top: -77%;
}

@keyframes rain-91 {
  0% {
    left: 92%;
    opacity: 0.45;
    top: -77%;
  }

  100% {
    opacity: 0;
    top: 117%;
  }
}

.rain:nth-of-type(92) {
  animation-name: rain-92;
  animation-delay: 11s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 67%;
  opacity: 0.41;
  top: -82%;
}

@keyframes rain-92 {
  0% {
    left: 67%;
    opacity: 0.41;
    top: -82%;
  }

  100% {
    opacity: 0;
    top: 122%;
  }
}

.rain:nth-of-type(93) {
  animation-name: rain-93;
  animation-delay: 10s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 64%;
  opacity: 0.59;
  top: -65%;
}

@keyframes rain-93 {
  0% {
    left: 64%;
    opacity: 0.59;
    top: -65%;
  }

  100% {
    opacity: 0;
    top: 105%;
  }
}

.rain:nth-of-type(94) {
  animation-name: rain-94;
  animation-delay: 18s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 28%;
  opacity: 0.44;
  top: -79%;
}

@keyframes rain-94 {
  0% {
    left: 28%;
    opacity: 0.44;
    top: -79%;
  }

  100% {
    opacity: 0;
    top: 119%;
  }
}

.rain:nth-of-type(95) {
  animation-name: rain-95;
  animation-delay: 18s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 27%;
  opacity: 0.59;
  top: -91%;
}

@keyframes rain-95 {
  0% {
    left: 27%;
    opacity: 0.59;
    top: -91%;
  }

  100% {
    opacity: 0;
    top: 131%;
  }
}

.rain:nth-of-type(96) {
  animation-name: rain-96;
  animation-delay: 11s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 22%;
  opacity: 0.51;
  top: -70%;
}

@keyframes rain-96 {
  0% {
    left: 22%;
    opacity: 0.51;
    top: -70%;
  }

  100% {
    opacity: 0;
    top: 110%;
  }
}

.rain:nth-of-type(97) {
  animation-name: rain-97;
  animation-delay: 5s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 68%;
  opacity: 0.6;
  top: -60%;
}

@keyframes rain-97 {
  0% {
    left: 68%;
    opacity: 0.6;
    top: -60%;
  }

  100% {
    opacity: 0;
    top: 100%;
  }
}

.rain:nth-of-type(98) {
  animation-name: rain-98;
  animation-delay: 0s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 9%;
  opacity: 0.57;
  top: -74%;
}

@keyframes rain-98 {
  0% {
    left: 9%;
    opacity: 0.57;
    top: -74%;
  }

  100% {
    opacity: 0;
    top: 114%;
  }
}

.rain:nth-of-type(99) {
  animation-name: rain-99;
  animation-delay: 3s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 46%;
  opacity: 0.54;
  top: -81%;
}

@keyframes rain-99 {
  0% {
    left: 46%;
    opacity: 0.54;
    top: -81%;
  }

  100% {
    opacity: 0;
    top: 121%;
  }
}

.rain:nth-of-type(100) {
  animation-name: rain-100;
  animation-delay: 9s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 42%;
  opacity: 0.37;
  top: -59%;
}

@keyframes rain-100 {
  0% {
    left: 42%;
    opacity: 0.37;
    top: -59%;
  }

  100% {
    opacity: 0;
    top: 99%;
  }
}

.rain:nth-of-type(101) {
  animation-name: rain-101;
  animation-delay: 2s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 61%;
  opacity: 0.43;
  top: -100%;
}

@keyframes rain-101 {
  0% {
    left: 61%;
    opacity: 0.43;
    top: -100%;
  }

  100% {
    opacity: 0;
    top: 140%;
  }
}

.rain:nth-of-type(102) {
  animation-name: rain-102;
  animation-delay: 10s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 35%;
  opacity: 0.47;
  top: -94%;
}

@keyframes rain-102 {
  0% {
    left: 35%;
    opacity: 0.47;
    top: -94%;
  }

  100% {
    opacity: 0;
    top: 134%;
  }
}

.rain:nth-of-type(103) {
  animation-name: rain-103;
  animation-delay: 13s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 99%;
  opacity: 0.35;
  top: -95%;
}

@keyframes rain-103 {
  0% {
    left: 99%;
    opacity: 0.35;
    top: -95%;
  }

  100% {
    opacity: 0;
    top: 135%;
  }
}

.rain:nth-of-type(104) {
  animation-name: rain-104;
  animation-delay: 13s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 34%;
  opacity: 0.53;
  top: -99%;
}

@keyframes rain-104 {
  0% {
    left: 34%;
    opacity: 0.53;
    top: -99%;
  }

  100% {
    opacity: 0;
    top: 139%;
  }
}

.rain:nth-of-type(105) {
  animation-name: rain-105;
  animation-delay: 13s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 19%;
  opacity: 0.48;
  top: -64%;
}

@keyframes rain-105 {
  0% {
    left: 19%;
    opacity: 0.48;
    top: -64%;
  }

  100% {
    opacity: 0;
    top: 104%;
  }
}

.rain:nth-of-type(106) {
  animation-name: rain-106;
  animation-delay: 6s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 28%;
  opacity: 0.54;
  top: -73%;
}

@keyframes rain-106 {
  0% {
    left: 28%;
    opacity: 0.54;
    top: -73%;
  }

  100% {
    opacity: 0;
    top: 113%;
  }
}

.rain:nth-of-type(107) {
  animation-name: rain-107;
  animation-delay: 7s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 53%;
  opacity: 0.44;
  top: -54%;
}

@keyframes rain-107 {
  0% {
    left: 53%;
    opacity: 0.44;
    top: -54%;
  }

  100% {
    opacity: 0;
    top: 94%;
  }
}

.rain:nth-of-type(108) {
  animation-name: rain-108;
  animation-delay: 0s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 1%;
  opacity: 0.39;
  top: -67%;
}

@keyframes rain-108 {
  0% {
    left: 1%;
    opacity: 0.39;
    top: -67%;
  }

  100% {
    opacity: 0;
    top: 107%;
  }
}

.rain:nth-of-type(109) {
  animation-name: rain-109;
  animation-delay: 2s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 25%;
  opacity: 0.56;
  top: -76%;
}

@keyframes rain-109 {
  0% {
    left: 25%;
    opacity: 0.56;
    top: -76%;
  }

  100% {
    opacity: 0;
    top: 116%;
  }
}

.rain:nth-of-type(110) {
  animation-name: rain-110;
  animation-delay: 13s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 96%;
  opacity: 0.43;
  top: -73%;
}

@keyframes rain-110 {
  0% {
    left: 96%;
    opacity: 0.43;
    top: -73%;
  }

  100% {
    opacity: 0;
    top: 113%;
  }
}

.rain:nth-of-type(111) {
  animation-name: rain-111;
  animation-delay: 18s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 84%;
  opacity: 0.43;
  top: -68%;
}

@keyframes rain-111 {
  0% {
    left: 84%;
    opacity: 0.43;
    top: -68%;
  }

  100% {
    opacity: 0;
    top: 108%;
  }
}

.rain:nth-of-type(112) {
  animation-name: rain-112;
  animation-delay: 3s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 98%;
  opacity: 0.38;
  top: -59%;
}

@keyframes rain-112 {
  0% {
    left: 98%;
    opacity: 0.38;
    top: -59%;
  }

  100% {
    opacity: 0;
    top: 99%;
  }
}

.rain:nth-of-type(113) {
  animation-name: rain-113;
  animation-delay: 10s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 4%;
  opacity: 0.42;
  top: -77%;
}

@keyframes rain-113 {
  0% {
    left: 4%;
    opacity: 0.42;
    top: -77%;
  }

  100% {
    opacity: 0;
    top: 117%;
  }
}

.rain:nth-of-type(114) {
  animation-name: rain-114;
  animation-delay: 16s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 55%;
  opacity: 0.47;
  top: -69%;
}

@keyframes rain-114 {
  0% {
    left: 55%;
    opacity: 0.47;
    top: -69%;
  }

  100% {
    opacity: 0;
    top: 109%;
  }
}

.rain:nth-of-type(115) {
  animation-name: rain-115;
  animation-delay: 11s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 59%;
  opacity: 0.5;
  top: -84%;
}

@keyframes rain-115 {
  0% {
    left: 59%;
    opacity: 0.5;
    top: -84%;
  }

  100% {
    opacity: 0;
    top: 124%;
  }
}

.rain:nth-of-type(116) {
  animation-name: rain-116;
  animation-delay: 4s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 23%;
  opacity: 0.57;
  top: -94%;
}

@keyframes rain-116 {
  0% {
    left: 23%;
    opacity: 0.57;
    top: -94%;
  }

  100% {
    opacity: 0;
    top: 134%;
  }
}

.rain:nth-of-type(117) {
  animation-name: rain-117;
  animation-delay: 4s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 46%;
  opacity: 0.37;
  top: -80%;
}

@keyframes rain-117 {
  0% {
    left: 46%;
    opacity: 0.37;
    top: -80%;
  }

  100% {
    opacity: 0;
    top: 120%;
  }
}

.rain:nth-of-type(118) {
  animation-name: rain-118;
  animation-delay: 16s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 19%;
  opacity: 0.46;
  top: -85%;
}

@keyframes rain-118 {
  0% {
    left: 19%;
    opacity: 0.46;
    top: -85%;
  }

  100% {
    opacity: 0;
    top: 125%;
  }
}

.rain:nth-of-type(119) {
  animation-name: rain-119;
  animation-delay: 9s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 59%;
  opacity: 0.56;
  top: -97%;
}

@keyframes rain-119 {
  0% {
    left: 59%;
    opacity: 0.56;
    top: -97%;
  }

  100% {
    opacity: 0;
    top: 137%;
  }
}

.rain:nth-of-type(120) {
  animation-name: rain-120;
  animation-delay: 17s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 3%;
  opacity: 0.41;
  top: -61%;
}

@keyframes rain-120 {
  0% {
    left: 3%;
    opacity: 0.41;
    top: -61%;
  }

  100% {
    opacity: 0;
    top: 101%;
  }
}

.rain:nth-of-type(121) {
  animation-name: rain-121;
  animation-delay: 9s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 85%;
  opacity: 0.36;
  top: -53%;
}

@keyframes rain-121 {
  0% {
    left: 85%;
    opacity: 0.36;
    top: -53%;
  }

  100% {
    opacity: 0;
    top: 93%;
  }
}

.rain:nth-of-type(122) {
  animation-name: rain-122;
  animation-delay: 5s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 26%;
  opacity: 0.58;
  top: -100%;
}

@keyframes rain-122 {
  0% {
    left: 26%;
    opacity: 0.58;
    top: -100%;
  }

  100% {
    opacity: 0;
    top: 140%;
  }
}

.rain:nth-of-type(123) {
  animation-name: rain-123;
  animation-delay: 7s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 82%;
  opacity: 0.35;
  top: -82%;
}

@keyframes rain-123 {
  0% {
    left: 82%;
    opacity: 0.35;
    top: -82%;
  }

  100% {
    opacity: 0;
    top: 122%;
  }
}

.rain:nth-of-type(124) {
  animation-name: rain-124;
  animation-delay: 0s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 28%;
  opacity: 0.46;
  top: -62%;
}

@keyframes rain-124 {
  0% {
    left: 28%;
    opacity: 0.46;
    top: -62%;
  }

  100% {
    opacity: 0;
    top: 102%;
  }
}

.rain:nth-of-type(125) {
  animation-name: rain-125;
  animation-delay: 7s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 8%;
  opacity: 0.45;
  top: -58%;
}

@keyframes rain-125 {
  0% {
    left: 8%;
    opacity: 0.45;
    top: -58%;
  }

  100% {
    opacity: 0;
    top: 98%;
  }
}

.rain:nth-of-type(126) {
  animation-name: rain-126;
  animation-delay: 16s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 53%;
  opacity: 0.59;
  top: -70%;
}

@keyframes rain-126 {
  0% {
    left: 53%;
    opacity: 0.59;
    top: -70%;
  }

  100% {
    opacity: 0;
    top: 110%;
  }
}

.rain:nth-of-type(127) {
  animation-name: rain-127;
  animation-delay: 6s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 30%;
  opacity: 0.52;
  top: -62%;
}

@keyframes rain-127 {
  0% {
    left: 30%;
    opacity: 0.52;
    top: -62%;
  }

  100% {
    opacity: 0;
    top: 102%;
  }
}

.rain:nth-of-type(128) {
  animation-name: rain-128;
  animation-delay: 2s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 42%;
  opacity: 0.37;
  top: -99%;
}

@keyframes rain-128 {
  0% {
    left: 42%;
    opacity: 0.37;
    top: -99%;
  }

  100% {
    opacity: 0;
    top: 139%;
  }
}

.rain:nth-of-type(129) {
  animation-name: rain-129;
  animation-delay: 17s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 30%;
  opacity: 0.49;
  top: -59%;
}

@keyframes rain-129 {
  0% {
    left: 30%;
    opacity: 0.49;
    top: -59%;
  }

  100% {
    opacity: 0;
    top: 99%;
  }
}

.rain:nth-of-type(130) {
  animation-name: rain-130;
  animation-delay: 11s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 82%;
  opacity: 0.42;
  top: -60%;
}

@keyframes rain-130 {
  0% {
    left: 82%;
    opacity: 0.42;
    top: -60%;
  }

  100% {
    opacity: 0;
    top: 100%;
  }
}

.rain:nth-of-type(131) {
  animation-name: rain-131;
  animation-delay: 9s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 54%;
  opacity: 0.48;
  top: -53%;
}

@keyframes rain-131 {
  0% {
    left: 54%;
    opacity: 0.48;
    top: -53%;
  }

  100% {
    opacity: 0;
    top: 93%;
  }
}

.rain:nth-of-type(132) {
  animation-name: rain-132;
  animation-delay: 10s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 14%;
  opacity: 0.38;
  top: -90%;
}

@keyframes rain-132 {
  0% {
    left: 14%;
    opacity: 0.38;
    top: -90%;
  }

  100% {
    opacity: 0;
    top: 130%;
  }
}

.rain:nth-of-type(133) {
  animation-name: rain-133;
  animation-delay: 6s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 73%;
  opacity: 0.43;
  top: -89%;
}

@keyframes rain-133 {
  0% {
    left: 73%;
    opacity: 0.43;
    top: -89%;
  }

  100% {
    opacity: 0;
    top: 129%;
  }
}

.rain:nth-of-type(134) {
  animation-name: rain-134;
  animation-delay: 4s;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  left: 100%;
  opacity: 0.54;
  top: -86%;
}

@keyframes rain-134 {
  0% {
    left: 100%;
    opacity: 0.54;
    top: -86%;
  }

  100% {
    opacity: 0;
    top: 126%;
  }
}

.rain:nth-of-type(135) {
  animation-name: rain-135;
  animation-delay: 10s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 52%;
  opacity: 0.4;
  top: -97%;
}

@keyframes rain-135 {
  0% {
    left: 52%;
    opacity: 0.4;
    top: -97%;
  }

  100% {
    opacity: 0;
    top: 137%;
  }
}

.rain:nth-of-type(136) {
  animation-name: rain-136;
  animation-delay: 19s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 5%;
  opacity: 0.51;
  top: -99%;
}

@keyframes rain-136 {
  0% {
    left: 5%;
    opacity: 0.51;
    top: -99%;
  }

  100% {
    opacity: 0;
    top: 139%;
  }
}

.rain:nth-of-type(137) {
  animation-name: rain-137;
  animation-delay: 4s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 1%;
  opacity: 0.51;
  top: -58%;
}

@keyframes rain-137 {
  0% {
    left: 1%;
    opacity: 0.51;
    top: -58%;
  }

  100% {
    opacity: 0;
    top: 98%;
  }
}

.rain:nth-of-type(138) {
  animation-name: rain-138;
  animation-delay: 19s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 8%;
  opacity: 0.44;
  top: -77%;
}

@keyframes rain-138 {
  0% {
    left: 8%;
    opacity: 0.44;
    top: -77%;
  }

  100% {
    opacity: 0;
    top: 117%;
  }
}

.rain:nth-of-type(139) {
  animation-name: rain-139;
  animation-delay: 4s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 45%;
  opacity: 0.36;
  top: -55%;
}

@keyframes rain-139 {
  0% {
    left: 45%;
    opacity: 0.36;
    top: -55%;
  }

  100% {
    opacity: 0;
    top: 95%;
  }
}

.rain:nth-of-type(140) {
  animation-name: rain-140;
  animation-delay: 12s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 15%;
  opacity: 0.55;
  top: -84%;
}

@keyframes rain-140 {
  0% {
    left: 15%;
    opacity: 0.55;
    top: -84%;
  }

  100% {
    opacity: 0;
    top: 124%;
  }
}

.rain:nth-of-type(141) {
  animation-name: rain-141;
  animation-delay: 9s;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  left: 68%;
  opacity: 0.38;
  top: -84%;
}

@keyframes rain-141 {
  0% {
    left: 68%;
    opacity: 0.38;
    top: -84%;
  }

  100% {
    opacity: 0;
    top: 124%;
  }
}

.rain:nth-of-type(142) {
  animation-name: rain-142;
  animation-delay: 12s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 3%;
  opacity: 0.6;
  top: -78%;
}

@keyframes rain-142 {
  0% {
    left: 3%;
    opacity: 0.6;
    top: -78%;
  }

  100% {
    opacity: 0;
    top: 118%;
  }
}

.rain:nth-of-type(143) {
  animation-name: rain-143;
  animation-delay: 15s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 57%;
  opacity: 0.34;
  top: -55%;
}

@keyframes rain-143 {
  0% {
    left: 57%;
    opacity: 0.34;
    top: -55%;
  }

  100% {
    opacity: 0;
    top: 95%;
  }
}

.rain:nth-of-type(144) {
  animation-name: rain-144;
  animation-delay: 14s;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  left: 98%;
  opacity: 0.47;
  top: -98%;
}

@keyframes rain-144 {
  0% {
    left: 98%;
    opacity: 0.47;
    top: -98%;
  }

  100% {
    opacity: 0;
    top: 138%;
  }
}

.rain:nth-of-type(145) {
  animation-name: rain-145;
  animation-delay: 14s;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  left: 82%;
  opacity: 0.58;
  top: -75%;
}

@keyframes rain-145 {
  0% {
    left: 82%;
    opacity: 0.58;
    top: -75%;
  }

  100% {
    opacity: 0;
    top: 115%;
  }
}

.rain:nth-of-type(146) {
  animation-name: rain-146;
  animation-delay: 10s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 31%;
  opacity: 0.39;
  top: -60%;
}

@keyframes rain-146 {
  0% {
    left: 31%;
    opacity: 0.39;
    top: -60%;
  }

  100% {
    opacity: 0;
    top: 100%;
  }
}

.rain:nth-of-type(147) {
  animation-name: rain-147;
  animation-delay: 15s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 61%;
  opacity: 0.42;
  top: -81%;
}

@keyframes rain-147 {
  0% {
    left: 61%;
    opacity: 0.42;
    top: -81%;
  }

  100% {
    opacity: 0;
    top: 121%;
  }
}

.rain:nth-of-type(148) {
  animation-name: rain-148;
  animation-delay: 16s;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  left: 57%;
  opacity: 0.32;
  top: -52%;
}

@keyframes rain-148 {
  0% {
    left: 57%;
    opacity: 0.32;
    top: -52%;
  }

  100% {
    opacity: 0;
    top: 92%;
  }
}

.rain:nth-of-type(149) {
  animation-name: rain-149;
  animation-delay: 14s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 36%;
  opacity: 0.41;
  top: -70%;
}

@keyframes rain-149 {
  0% {
    left: 36%;
    opacity: 0.41;
    top: -70%;
  }

  100% {
    opacity: 0;
    top: 110%;
  }
}

.rain:nth-of-type(150) {
  animation-name: rain-150;
  animation-delay: 6s;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  left: 17%;
  opacity: 0.37;
  top: -67%;
}

@keyframes rain-150 {
  0% {
    left: 17%;
    opacity: 0.37;
    top: -67%;
  }

  100% {
    opacity: 0;
    top: 107%;
  }
}

/******************************************************* zombie animations ******************************************************************/

.zombie1 {
  width: calc(351px / 2);
  height: 508px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/zombie1.png");
  -webkit-animation: animate1 1s steps(2) infinite;
}

.zombie2 {
  width: calc(351px / 2);
  height: 439px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/zombie2.png");
  -webkit-animation: animate1 1s steps(2) infinite;
}

.zombie3 {
  width: calc(351px / 2);
  height: 291px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/zombie3.png");
  -webkit-animation: animate1 1s steps(2) infinite;
}

.zombie4 {
  width: calc(351px / 2);
  height: 507px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/zombie4.png");
  -webkit-animation: animate1 1s steps(2) infinite;
}

.zombie5 {
  width: calc(356px / 2);
  height: 121px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/zombie5.png");
  -webkit-animation: animate1 1s steps(2) infinite;
}

.zombie6 {
  width: calc(351px / 2);
  height: 366px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/zombie6.png");
  -webkit-animation: animate1 1s steps(2) infinite;
}

/* .zombie7 {
  width: calc(359px / 2);
  height: 98px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/zombie7.png");
  -webkit-animation: animate7 1s steps(2) infinite;
} */

/* fade-fwd-slide-left */
.human1 {
  width: calc(200px / 2);
  height: 200px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/human1.png");
  -webkit-animation: animate1 1s steps(2) infinite;
}

.human2 {
  width: calc(200px / 2);
  height: 200px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/human2.png");
  -webkit-animation: animate1 1s steps(2) infinite;
}

.human3 {
  width: calc(200px / 2);
  height: 200px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/human3.png");
  -webkit-animation: animate1 1s steps(2) infinite;
}

/* .human4 {
  width: calc(200px / 2);
  height: 200px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/human4.png");
  -webkit-animation: animate1 1s steps(2) infinite;
}
.human5 {
  width: calc(200px / 2);
  height: 200px;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/human5.png");
  -webkit-animation: animate1 1s steps(2) infinite;
} */

/* @keyframes animate1 {
  from {
    background-position: 0;
  }
  to {
    background-position: 351px;
  }
} */

@keyframes animate7 {
  from {
    background-position: 0;
  }

  to {
    background-position: 359px;
  }
}

@keyframes scale-up-center {
  from {
    -moz-transform: scale(1) translate3d(0px, 90px, 0);
    -webkit-transform: scale(1) translate3d(0px, 90px, 0);
    -o-transform: scale(1) translate3d(0px, 90px, 0);
    -ms-transform: scale(1) translate3d(0px, 90px, 0);
    -webkit-transform: scale(1) translate3d(0px, 90px, 0);
    transform: scale(1) translate3d(0px, 90px, 0);
  }

  to {
    -moz-transform: scale(1.2) translate3d(15px, 90px, 0);
    -webkit-transform: scale(1.2) translate3d(15px, 90px, 0);
    -o-transform: scale(1.2) translate3d(15px, 90px, 0);
    -ms-transform: scale(1.2) translate3d(15px, 90px, 0);
    -webkit-transform: scale(1.2) translate3d(15px, 90px, 0);
    transform: scale(1.2) translate3d(15px, 90px, 0);
  }
}

/* MY ZOMBIES */

.my-zombie-class-1 {
  height: 195px;
  width: calc(119px / 2);
  position: absolute;
  /* z-index: 1; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie1.png");
  background-size: cover;
  top: 200px;
  left: 380px;
  -webkit-animation: my-zombie-animation-1 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie1-animation-1 {
  0% {
    top: 180px;
    left: -20px;
  }

  10% {
    top: 210px;
    left: -20px;
  }

  20% {
    top: 240px;
    left: 10px;
  }

  40% {
    top: 260px;
    left: 30px;
  }

  60% {
    top: 280px;
    left: 50px;
  }

  80% {
    top: 300px;
    left: 70px;
  }

  100% {
    top: 320px;
    left: 90px;
  }
}

.my-zombie-class-tuto {
  height: 195px;
  width: calc(119px / 2);
  position: absolute;
  /* z-index: 1; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie1.png");
  background-size: cover;
  top: 200px;
  left: 380px;
  -webkit-animation: my-zombie-animation-tuto 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-tuto {
  0% {
    background-position: 120px;
    top: 200px;
    left: 380px;
    transform: scale(1) translateX(20px);
    opacity: 0;
  }

  10% {
    opacity: 1;
    top: 220px;
    left: 380px;
  }

  20% {
    background-position: 0px;
    top: 240px;
    left: 380px;
    transform: scale(1.15);
  }

  40% {
    background-position: 120px;
    top: 260px;
    left: 380px;
    transform: scale(1.3);
  }

  60% {
    background-position: 0px;
    top: 280px;
    left: 380px;
    transform: scale(1.45);
  }

  80% {
    background-position: 120px;
    top: 300px;
    left: 380px;
    transform: scale(1.6);
  }

  100% {
    background-position: 0px;
    top: 320px;
    left: 380px;
    transform: scale(1.75);
  }
}


@keyframes my-zombie-animation-1 {
  0% {
    background-position: 120px;
    top: 200px;
    left: 380px;
    transform: scale(1) translateX(20px);
    opacity: 0;
  }

  10% {
    opacity: 1;
    top: 220px;
    left: 380px;
  }

  20% {
    background-position: 0px;
    top: 240px;
    left: 380px;
    transform: scale(1.15);
  }

  40% {
    background-position: 120px;
    top: 260px;
    left: 380px;
    transform: scale(1.3);
  }

  60% {
    background-position: 0px;
    top: 280px;
    left: 380px;
    transform: scale(1.45);
  }

  80% {
    background-position: 120px;
    top: 300px;
    left: 380px;
    transform: scale(1.6);
  }

  100% {
    background-position: 0px;
    top: 320px;
    left: 380px;
    transform: scale(1.75);
  }
}

.my-zombie-class-11 {
  height: 195px;
  width: calc(119px / 2);
  position: absolute;
  /* z-index: 1; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie1.png");
  background-size: cover;
  top: 200px;
  left: 380px;
  -webkit-animation: my-zombie-animation-11 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-11 {
  0% {
    background-position: 120px;
    top: 200px;
    transform: scale(1) translateX(20px);
    opacity: 0;
  }

  10% {
    opacity: 1;
    top: 220px;
  }

  20% {
    background-position: 0px;
    top: 240px;
    transform: scale(1.15);
  }

  40% {
    background-position: 120px;
    top: 260px;
    transform: scale(1.3);
  }

  60% {
    background-position: 0px;
    top: 280px;
    transform: scale(1.45);
  }

  80% {
    background-position: 120px;
    top: 300px;
    transform: scale(1.6);
  }

  100% {
    background-position: 0px;
    top: 320px;
    transform: scale(1.75);
  }
}

.my-zombie-class-2 {
  height: 250px;
  width: calc(200px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie2.png");
  background-size: cover;
  top: 180px;
  -webkit-animation: my-zombie-animation-2 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-2 {
  0% {
    background-position: 200px;
    transform: scale(1);
    opacity: 0;
    left: 5px;
    top: 180px;
  }

  10% {
    opacity: 1;
    top: 200px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    top: 220px;
  }

  40% {
    background-position: 200px;
    transform: scale(1.2) translateX(-5px);
    top: 240px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.3);
    top: 260px;
  }

  80% {
    background-position: 200px;
    transform: scale(1.4) translateX(-5px);
    top: 280px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.5);
    top: 300px;
  }
}


.my-zombie-class-22 {
  height: 250px;
  width: calc(200px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie2.png");
  background-size: cover;
  top: 180px;
  -webkit-animation: my-zombie-animation-22 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-22 {
  0% {
    background-position: 200px;
    left: 5px;
    transform: scale(1);
    opacity: 0;
    top: 180px;
  }

  10% {
    opacity: 1;
    top: 200px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    top: 220px;
  }

  40% {
    background-position: 200px;
    transform: scale(1.3) translateX(-5px);
    top: 240px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    top: 260px;
  }

  80% {
    background-position: 200px;
    transform: scale(1.6) translateX(-5px);
    top: 280px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    top: 300px;
  }
}

.my-zombie-class-3 {
  height: 200px;
  width: calc(240px / 2);
  position: absolute;
  /* z-index: 2; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie3.png");
  background-size: cover;
  top: 200px;
  left: 525px;
  -webkit-animation: my-zombie-animation-3 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-3 {
  0% {
    background-position: 240px;
    transform: scale(1);
    opacity: 0;
    top: 200px;
    left: 525px;
  }

  10% {
    opacity: 1;
    top: 220px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    top: 240px;
  }

  40% {
    background-position: 240px;
    transform: scale(1.3);
    top: 260px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    top: 280px;
  }

  80% {
    background-position: 240px;
    transform: scale(1.6);
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    top: 320px;
  }
}



.my-zombie-class-33 {
  height: 200px;
  width: calc(240px / 2);
  position: absolute;
  /* z-index: 2; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie3.png");
  background-size: cover;
  top: 200px;
  left: 525px;
  -webkit-animation: my-zombie-animation-33 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-33 {
  0% {
    background-position: 240px;
    transform: scale(1);
    opacity: 0;
    top: 200px;
    left: 525px;
  }

  10% {
    opacity: 1;
    top: 220px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    top: 240px;
  }

  40% {
    background-position: 240px;
    transform: scale(1.3);
    top: 260px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    top: 280px;
  }

  80% {
    background-position: 240px;
    transform: scale(1.6);
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    top: 320px;
  }
}

.my-zombie-class-4 {
  height: 215px;
  width: calc(150px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie4.png");
  background-size: cover;
  top: 220px;
  left: 670px;
  -webkit-animation: my-zombie-animation-4 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-4 {
  0% {
    background-position: 150px;
    transform: scale(1);
    opacity: 0;
    top: 220px;
    left: 670px;
  }

  10% {
    opacity: 1;
    top: 240px;
    left: 670px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    left: 670px;
    top: 260px;
  }

  40% {
    background-position: 150px;
    transform: scale(1.3);
    left: 670px;
    top: 280px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    left: 670px;
    top: 290px;
  }

  80% {
    background-position: 150px;
    transform: scale(1.6);
    left: 670px;
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    left: 670px;
    top: 310px;
  }
}

@keyframes my-zombie4-animation-4 {
  0% {
    top: 325px;
    left: 465px;
  }

  10% {
    top: 330px;
  }

  20% {
    top: 335px;
  }

  40% {
    top: 340px;
  }

  60% {
    top: 360px;
  }

  80% {
    top: 380px;
  }

  100% {
    top: 400px;
  }
}

.my-zombie-class-44 {
  height: 215px;
  width: calc(150px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie4.png");
  background-size: cover;
  top: 220px;
  left: 670px;
  -webkit-animation: my-zombie-animation-44 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-44 {
  0% {
    background-position: 150px;
    transform: scale(1);
    opacity: 0;
    top: 220px;
  }

  10% {
    opacity: 1;
    top: 240px;
    left: 670px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    left: 670px;
    top: 260px;
  }

  40% {
    background-position: 150px;
    transform: scale(1.3);
    left: 670px;
    top: 280px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    left: 670px;
    top: 290px;
  }

  80% {
    background-position: 150px;
    transform: scale(1.6);
    left: 670px;
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    left: 670px;
    top: 310px;
  }
}

.my-zombie-class-5 {
  height: 55px;
  width: calc(160px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie5.png");
  background-size: cover;
  top: 325px;
  left: 465px;
  -webkit-animation: my-zombie-animation-5 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-5 {
  0% {
    background-position: 160px;
    transform: scale(1);
    opacity: 0;
    top: 325px;
    left: 465px;
  }

  10% {
    opacity: 1;
    top: 330px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15) translateY(10px);
    top: 335px;
  }

  40% {
    background-position: 160px;
    transform: scale(1.3) translateY(20px);
    top: 340px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45) translateY(30px);
    top: 360px;
  }

  80% {
    background-position: 160px;
    transform: scale(1.6) translateY(40px);
    top: 380px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75) translateY(50px);
    top: 400px;
  }
}

@keyframes my-zombie5-animation-5 {
  0% {
    top: 227px;
    left: -20px;
  }

  10% {
    top: 237px;
  }

  20% {
    top: 247px;
  }

  40% {
    top: 257px;
  }

  60% {
    top: 267px;
  }

  80% {
    top: 277px;
  }

  100% {
    top: 287px;
  }
}

.my-zombie-class-55 {
  height: 55px;
  width: calc(160px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie5.png");
  background-size: cover;
  top: 325px;
  left: 465px;
  -webkit-animation: my-zombie-animation-55 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-55 {
  0% {
    background-position: 160px;
    transform: scale(1);
    opacity: 0;
    top: 325px;
  }

  10% {
    opacity: 1;
    top: 330px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15) translateY(10px);
    top: 335px;
  }

  40% {
    background-position: 160px;
    transform: scale(1.3) translateY(20px);
    top: 340px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45) translateY(30px);
    top: 360px;
  }

  80% {
    background-position: 160px;
    transform: scale(1.6) translateY(40px);
    top: 380px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75) translateY(50px);
    top: 400px;
  }
}

.my-zombie-class-6 {
  height: 239px;
  width: calc(233px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie6.png");
  background-size: cover;
  top: 227px;
  right: -35px;
  -webkit-animation: my-zombie-animation-6 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-6 {
  0% {
    background-position: 233px;
    transform: scale(1) translateX(-5px);
    opacity: 0;
    top: 227px;
    right: -35px;
  }

  10% {
    opacity: 1;
    top: 237px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15) translateX(-10px);
    top: 247px;
  }

  40% {
    background-position: 233px;
    transform: scale(1.3) translateX(-15px);
    top: 257px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45) translateX(-20px);
    top: 267px;
  }

  80% {
    background-position: 233px;
    transform: scale(1.6) translateX(-25px);
    top: 277px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75) translateX(-30px);
    top: 287px;
  }
}



.my-zombie-class-66 {
  height: 239px;
  width: calc(233px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie6.png");
  background-size: cover;
  top: 227px;
  right: -35px;
  -webkit-animation: my-zombie-animation-66 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-66 {
  0% {
    background-position: 233px;
    transform: scale(1) translateX(-5px);
    opacity: 0;
    top: 227px;
  }

  10% {
    opacity: 1;
    top: 237px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15) translateX(-10px);
    top: 247px;
  }

  40% {
    background-position: 233px;
    transform: scale(1.3) translateX(-15px);
    top: 257px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45) translateX(-20px);
    top: 267px;
  }

  80% {
    background-position: 233px;
    transform: scale(1.6) translateX(-25px);
    top: 277px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75) translateX(-30px);
    top: 287px;
  }
}

.my-zombie-class-7 {
  height: 195px;
  width: calc(119px / 2);
  position: absolute;
  /* z-index: 1; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie1.png");
  background-size: cover;
  top: 200px;
  left: 735px;
  -webkit-animation: my-zombie-animation-7 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-7 {
  0% {
    background-position: 119px;
    transform: scale(1);
    opacity: 0;
    top: 220px;
    left: 735px;
  }

  10% {
    opacity: 1;
    top: 240px;
    left: 725px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    left: 715px;
    top: 260px;
  }

  40% {
    background-position: 119px;
    transform: scale(1.3);
    left: 705px;
    top: 280px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    left: 690px;
    top: 290px;
  }

  80% {
    background-position: 119px;
    transform: scale(1.6);
    left: 680px;
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    left: 670px;
    top: 310px;
  }
}

.my-zombie-class-77 {
  height: 245px;
  width: calc(145px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie1.png");
  background-size: cover;
  top: 227px;
  right: 20px;
  -webkit-animation: my-zombie-animation-77 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-77 {
  0% {
    background-position: 119px;
    transform: scale(1);
    opacity: 0;
    top: 220px;
    left: 735px;
  }

  10% {
    opacity: 1;
    top: 240px;
    left: 725px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    left: 715px;
    top: 260px;
  }

  40% {
    background-position: 119px;
    transform: scale(1.3);
    left: 705px;
    top: 280px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    left: 690px;
    top: 290px;
  }

  80% {
    background-position: 119px;
    transform: scale(1.6);
    left: 680px;
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    left: 670px;
    top: 310px;
  }
}

.my-zombie-class-8 {
  height: 200px;
  width: calc(240px / 2);
  position: absolute;
  /* z-index: 2; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie3.png");
  background-size: cover;
  top: 200px;
  left: 100px;
  -webkit-animation: my-zombie-animation-8 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-8 {
  0% {
    background-position: 240px;
    transform: scale(1);
    opacity: 0;
    top: 200px;
    left: 100px;

  }

  10% {
    opacity: 1;
    top: 220px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    top: 240px;
  }

  40% {
    background-position: 240px;
    transform: scale(1.3);
    top: 260px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    top: 280px;
  }

  80% {
    background-position: 240px;
    transform: scale(1.6);
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    top: 320px;
  }
}

.my-zombie-class-88 {
  height: 200px;
  width: calc(240px / 2);
  position: absolute;
  /* z-index: 2; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie3.png");
  background-size: cover;
  top: 200px;
  left: 100px;
  -webkit-animation: my-zombie-animation-8 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-88 {
  0% {
    background-position: 240px;
    transform: scale(1);
    opacity: 0;
    top: 200px;
    left: 100px;

  }

  10% {
    opacity: 1;
    top: 220px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    top: 240px;
  }

  40% {
    background-position: 240px;
    transform: scale(1.3);
    top: 260px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    top: 280px;
  }

  80% {
    background-position: 240px;
    transform: scale(1.6);
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    top: 320px;
  }
}

.my-zombie-class-9 {
  height: 250px;
  width: calc(200px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie2.png");
  background-size: cover;
  top: 180px;
  left: 470px;
  -webkit-animation: my-zombie-animation-9 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-9 {
  0% {
    background-position: 200px;
    transform: scale(1);
    opacity: 0;
    top: 180px;
  }

  10% {
    opacity: 1;
    top: 200px;
    background-position: 0px;
  }

  20% {
    background-position: 200px;
    transform: scale(1.15);
    top: 220px;
  }

  40% {
    background-position: 0px;
    transform: scale(1.2) translateX(-5px);
    top: 240px;
  }

  60% {
    background-position: 200px;
    transform: scale(1.3);
    top: 260px;
  }

  80% {
    background-position: 0px;
    transform: scale(1.4) translateX(-5px);
    top: 280px;
  }

  100% {
    background-position: 200px;
    transform: scale(1.5);
    top: 300px;
  }
}

.my-zombie-class-99 {
  height: 250px;
  width: calc(200px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie2.png");
  background-size: cover;
  top: 180px;
  left: 470px;
  -webkit-animation: my-zombie-animation-99 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-99 {
  0% {
    background-position: 200px;
    transform: scale(1);
    opacity: 0;
    top: 180px;
  }

  10% {
    opacity: 1;
    top: 200px;
    background-position: 0px;
  }

  20% {
    background-position: 200px;
    transform: scale(1.15);
    top: 220px;
  }

  40% {
    background-position: 0px;
    transform: scale(1.2) translateX(-5px);
    top: 240px;
  }

  60% {
    background-position: 200px;
    transform: scale(1.3);
    top: 260px;
  }

  80% {
    background-position: 0px;
    transform: scale(1.4) translateX(-5px);
    top: 280px;
  }

  100% {
    background-position: 200px;
    transform: scale(1.5);
    top: 300px;
  }
}

.my-zombie-class-10 {
  height: 215px;
  width: calc(150px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie4.png");
  background-size: cover;
  top: 220px;
  left: 267px;
  -webkit-animation: my-zombie-animation-10 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-10 {
  0% {
    background-position: 150px;
    transform: scale(1);
    opacity: 0;
    top: 220px;
    left: 267px;
  }

  10% {
    opacity: 1;
    top: 240px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    top: 260px;
  }

  40% {
    background-position: 150px;
    transform: scale(1.3);
    top: 280px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    top: 290px;
  }

  80% {
    background-position: 150px;
    transform: scale(1.6);
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    top: 310px;
  }
}


.my-zombie-class-1010 {
  height: 215px;
  width: calc(150px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie4.png");
  background-size: cover;
  top: 220px;
  left: 267px;
  -webkit-animation: my-zombie-animation-1010 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-1010 {
  0% {
    background-position: 150px;
    transform: scale(1);
    opacity: 0;
    top: 220px;
    left: 267px;
  }

  10% {
    opacity: 1;
    top: 240px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    top: 260px;
  }

  40% {
    background-position: 150px;
    transform: scale(1.3);
    top: 280px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    top: 290px;
  }

  80% {
    background-position: 150px;
    transform: scale(1.6);
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    top: 310px;
  }
}


.my-zombie-class-11 {
  height: 239px;
  width: calc(233px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie6.png");
  background-size: cover;
  top: 227px;
  right: 200px;
  -webkit-animation: my-zombie-animation-11 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-11 {
  0% {
    background-position: 233px;
    transform: scale(1) translateX(-5px);
    opacity: 0;
    top: 227px;
  }

  10% {
    opacity: 1;
    top: 237px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15) translateX(-10px);
    top: 247px;
  }

  40% {
    background-position: 233px;
    transform: scale(1.3) translateX(-15px);
    top: 257px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45) translateX(-20px);
    top: 267px;
  }

  80% {
    background-position: 233px;
    transform: scale(1.6) translateX(-25px);
    top: 277px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75) translateX(-30px);
    top: 287px;
  }
}

.my-zombie-class-1111 {
  height: 239px;
  width: calc(233px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie6.png");
  background-size: cover;
  top: 227px;
  right: -20px;
  -webkit-animation: my-zombie-animation-1111 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-1111 {
  0% {
    background-position: 233px;
    transform: scale(1) translateX(-5px);
    opacity: 0;
    top: 227px;
  }

  10% {
    opacity: 1;
    top: 237px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15) translateX(-10px);
    top: 247px;
  }

  40% {
    background-position: 233px;
    transform: scale(1.3) translateX(-15px);
    top: 257px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45) translateX(-20px);
    top: 267px;
  }

  80% {
    background-position: 233px;
    transform: scale(1.6) translateX(-25px);
    top: 277px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75) translateX(-30px);
    top: 287px;
  }
}

.my-zombie-class-12 {
  height: 215px;
  width: calc(150px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie4.png");
  background-size: cover;
  top: 220px;
  left: 830px;
  -webkit-animation: my-zombie-animation-12 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-12 {
  0% {
    background-position: 150px;
    transform: scale(1);
    opacity: 0;
    top: 220px;
    left: 830px;
  }

  10% {
    opacity: 1;
    top: 240px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    top: 260px;
  }

  40% {
    background-position: 150px;
    transform: scale(1.3);
    top: 280px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    top: 290px;
  }

  80% {
    background-position: 150px;
    transform: scale(1.6);
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    top: 310px;
  }
}

.my-zombie-class-1212 {
  height: 215px;
  width: calc(150px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie4.png");
  background-size: cover;
  top: 220px;
  left: 830px;
  -webkit-animation: my-zombie-animation-1212 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-1212 {
  0% {
    background-position: 150px;
    transform: scale(1);
    opacity: 0;
    top: 220px;
    left: 830px;
  }

  10% {
    opacity: 1;
    top: 240px;
  }

  20% {
    background-position: 0px;
    transform: scale(1.15);
    top: 260px;
  }

  40% {
    background-position: 150px;
    transform: scale(1.3);
    top: 280px;
  }

  60% {
    background-position: 0px;
    transform: scale(1.45);
    top: 290px;
  }

  80% {
    background-position: 150px;
    transform: scale(1.6);
    top: 300px;
  }

  100% {
    background-position: 0px;
    transform: scale(1.75);
    top: 310px;
  }
}

.my-zombie-class-13 {
  height: 250px;
  width: calc(200px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie2.png");
  background-size: cover;
  top: 180px;
  left: 750px;
  -webkit-animation: my-zombie-animation-13 5s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-13 {
  0% {
    background-position: 200px;
    transform: scale(1);
    opacity: 0;
    top: 180px;
  }

  10% {
    opacity: 1;
    top: 200px;
    background-position: 0px;
  }

  20% {
    background-position: 200px;
    transform: scale(1.15);
    top: 220px;
  }

  40% {
    background-position: 0px;
    transform: scale(1.2) translateX(-5px);
    top: 240px;
  }

  60% {
    background-position: 200px;
    transform: scale(1.3);
    top: 260px;
  }

  80% {
    background-position: 0px;
    transform: scale(1.4) translateX(-5px);
    top: 280px;
  }

  100% {
    background-position: 200px;
    transform: scale(1.5);
    top: 300px;
  }
}

.my-zombie-class-1313 {
  height: 250px;
  width: calc(200px / 2);
  position: absolute;
  /* z-index: 3; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/zombie2.png");
  background-size: cover;
  top: 180px;
  left: 750px;
  -webkit-animation: my-zombie-animation-1313 3s steps(2);
  animation-fill-mode: forwards;
}

@keyframes my-zombie-animation-1313 {
  0% {
    background-position: 200px;
    transform: scale(1);
    opacity: 0;
    top: 180px;
  }

  10% {
    opacity: 1;
    top: 200px;
    background-position: 0px;
  }

  20% {
    background-position: 200px;
    transform: scale(1.15);
    top: 220px;
  }

  40% {
    background-position: 0px;
    transform: scale(1.2) translateX(-5px);
    top: 240px;
  }

  60% {
    background-position: 200px;
    transform: scale(1.3);
    top: 260px;
  }

  80% {
    background-position: 0px;
    transform: scale(1.4) translateX(-5px);
    top: 280px;
  }

  100% {
    background-position: 200px;
    transform: scale(1.5);
    top: 300px;
  }
}


/* my humans */
.my-human-class-1 {
  height: 120px;
  width: 105px;
  position: absolute;
  display: block;
  z-index: 3;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/human1.png");
  background-size: cover;
  top: 420px;
  left: 202px;
  -webkit-animation: my-human-animation-1 15s;
}

@keyframes my-human-animation-1 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.my-human-class-2 {
  height: 225px;
  width: 75px;
  position: absolute;
  display: block;
  z-index: 0;
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/human2.png");
  background-size: cover;
  top: 176px;
  left: 222px;
  -webkit-animation: my-human-animation-2 15s;
}

@keyframes my-human-animation-2 {
  0% {
    transform: scale(1);

  }

  100% {
    transform: scale(1);
  }
}

.my-human-class-3 {
  height: 225px;
  width: 72px;
  position: absolute;
  display: block;
  /* z-index: 1; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/human3.png");
  background-size: cover;
  top: 201px;
  left: 850px;
  -webkit-animation: my-human-animation-3 15s;
}

@keyframes my-human-animation-3 {
  0% {
    transform: scale(1);

  }

  100% {
    transform: scale(1);
  }
}

.my-human-class-4 {
  height: 160px;
  width: 60px;
  position: absolute;
  display: block;
  /* z-index: 1; */
  background: url("../../../../assets/img/monde_bonus/monde_zombie/decoupage02/human4.png");
  background-size: cover;
  top: 250px;
  left: 121px;
  -webkit-animation: my-human-animation-4 15s;
}

@keyframes my-human-animation-4 {
  0% {
    transform: scale(1);

  }

  100% {
    transform: scale(1);
  }
}

.GOtextZombie {
  top: 50%;
  position: relative;
  filter: drop-shadow(4px 4px 10px white);
  font-size: 180px;
  font-weight: 1000;
  animation: scale-in-centerGO 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 3s;
}