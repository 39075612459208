.Burger {
  position: relative;
  object-fit: contain;
  height: 100%;
  width: 72px;
  object-position: right center;
}
#Burger {
  right: 8px;
}

.paramMenu {
  position: absolute;
  display: flex;
  top: 16px;
  right: 0;
}
.paramMenu.monde0 {
  height: 80px;
}
.paramMenu.mondex {
  height: 70px;
}

#Param > div {
  width: 70px;
}

.paramMenu.styled {
  height: 70px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px white solid;
  box-sizing: border-box;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  padding: 5px;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}

.fade-in-confirm {
  -webkit-animation: fade-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-11-18 14:41:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    filter: opacity(1);
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    filter: opacity(1);
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    filter: opacity(1);
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    filter: opacity(1);
  }
}

@-webkit-keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    filter: opacity(1);
  }
}
/**
 * ----------------------------------------
 * animation fade-in-bl
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bl {
  0% {
    -webkit-transform: translateX(-50px) translateY(50px);
    transform: translateX(-50px) translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-bl {
  0% {
    -webkit-transform: translateX(-50px) translateY(50px);
    transform: translateX(-50px) translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    filter: opacity(1);
  }
}
/**
 * ----------------------------------------
 * animation fade-in-br
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-br {
  0% {
    -webkit-transform: translateX(50px) translateY(50px);
    transform: translateX(50px) translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-br {
  0% {
    -webkit-transform: translateX(50px) translateY(50px);
    transform: translateX(50px) translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    filter: opacity(1);
  }
}
/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    filter: opacity(1);
  }
}
/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    filter: opacity(1);
  }
}
/**
 * ----------------------------------------
 * animation fade-in-tl
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-tl {
  0% {
    -webkit-transform: translateX(-50px) translateY(-50px);
    transform: translateX(-50px) translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-tl {
  0% {
    -webkit-transform: translateX(-50px) translateY(-50px);
    transform: translateX(-50px) translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    filter: opacity(1);
  }
}
/**
 * ----------------------------------------
 * animation fade-in-tr
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-tr {
  0% {
    -webkit-transform: translateX(50px) translateY(-50px);
    transform: translateX(50px) translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-tr {
  0% {
    -webkit-transform: translateX(50px) translateY(-50px);
    transform: translateX(50px) translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    filter: opacity(0);
  }
  50% {
    filter: opacity(1);
  }
}
@keyframes fade-in {
  0% {
    filter: opacity(0);
  }
  100% {
    filter: opacity(1);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-11-18 14:4:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * DOCUMENT INFORMATION
 * @file fade-in.less
 * @version 1.0
 * @author Ana Travas, @ana108
 * ----------------------------------------
 */
/**
 * ----------------------------------------
 * animation fade-in-bck
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    filter: opacity(0);
    
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    filter: opacity(1);
  }
}
/**
   * ----------------------------------------
   * animation fade-in-bl
   * ----------------------------------------
   */
@-webkit-keyframes fade-in-bl {
  0% {
    -webkit-transform: translateX(-50px) translateY(50px);
    transform: translateX(-50px) translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-bl {
  0% {
    -webkit-transform: translateX(-50px) translateY(50px);
    transform: translateX(-50px) translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
/**
   * ----------------------------------------
   * animation fade-in-bottom
   * ----------------------------------------
   */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    filter: opacity(1);
  }
}
/**
   * ----------------------------------------
   * animation fade-in-br
   * ----------------------------------------
   */
@-webkit-keyframes fade-in-br {
  0% {
    -webkit-transform: translateX(50px) translateY(50px);
    transform: translateX(50px) translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-br {
  0% {
    -webkit-transform: translateX(50px) translateY(50px);
    transform: translateX(50px) translateY(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
/**
   * ----------------------------------------
   * animation fade-in-fwd
   * ----------------------------------------
   */
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    filter: opacity(1);
  }
}
/**
   * ----------------------------------------
   * animation fade-in-left
   * ----------------------------------------
   */
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    filter: opacity(1);
  }
}
/**
   * ----------------------------------------
   * animation fade-in-right
   * ----------------------------------------
   */
@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    filter: opacity(1);
  }
}
/**
   * ----------------------------------------
   * animation fade-in-tl
   * ----------------------------------------
   */
@-webkit-keyframes fade-in-tl {
  0% {
    -webkit-transform: translateX(-50px) translateY(-50px);
    transform: translateX(-50px) translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-tl {
  0% {
    -webkit-transform: translateX(-50px) translateY(-50px);
    transform: translateX(-50px) translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
/**
   * ----------------------------------------
   * animation fade-in-top
   * ----------------------------------------
   */
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    filter: opacity(1);
  }
}
/**
   * ----------------------------------------
   * animation fade-in-tr
   * ----------------------------------------
   */
@-webkit-keyframes fade-in-tr {
  0% {
    -webkit-transform: translateX(50px) translateY(-50px);
    transform: translateX(50px) translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
@keyframes fade-in-tr {
  0% {
    -webkit-transform: translateX(50px) translateY(-50px);
    transform: translateX(50px) translateY(-50px);
    filter: opacity(0);
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    filter: opacity(1);
  }
}
/**
   * ----------------------------------------
   * animation fade-in
   * ----------------------------------------
   */
@-webkit-keyframes fade-in {
  0% {
    filter: opacity(0);
  }
  100% {
    filter: opacity(1);
  }
}
@keyframes fade-in {
  0% {
    filter: opacity(0);
  }
  100% {
    filter: opacity(1);
  }
}

@-webkit-keyframes spaceboots {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -1px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-1px, 1px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(1px, 0px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(0px, 1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(0px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(1px, 1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(0px, 1px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, 0px) rotate(-1deg);
  }
}
@keyframes spaceboots {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -1px) rotate(-1deg);
  }
  20% {
    transform: translate(-1px, 1px) rotate(1deg);
  }
  30% {
    transform: translate(1px, 0px) rotate(0deg);
  }
  40% {
    transform: translate(0px, 1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(0px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(1px, 1px) rotate(1deg);
  }
  90% {
    transform: translate(0px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, 0px) rotate(-1deg);
  }
}
.shake,
.shake:hover,
.shake:focus {
  -webkit-animation-name: spaceboots;
  -webkit-animation-duration: 0.8s;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-name: spaceboots;
  animation-duration: 0.8s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  filter: drop-shadow(0px 0px 10px red) drop-shadow(0px 0px 5px red) !important ;
}
/* @-webkit-keyframes shake {
    10%, 90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
    10%, 90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  .face {
    -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-perspective: 1000px;
            perspective: 1000px;
            filter : drop-shadow(0px 0px 10px red) drop-shadow(0px 0px 5px red) ;
  }
   */
