.fit {
    height: 100%;
    width: 100%;
}
.relative {
    position: relative;
}
.sliderMonde2Container {
    height: 85%;
    width: 87%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.3), 0px 0px 1px 2px rgba(0,0,0,0.7); */
}
#title{
  text-align: center;
  color: #667a91;
  padding-top: 2%;
  font-weight: 500;
  position: relative;
  top: 1%;
  font-size: 150%;
  }
  .section {
    box-sizing : border-box ;
    color : #667a91 ;
    width : 28%;
    float : left ;
    height: 70%;
    border-right: 1px solid black;
    padding : 5% 0 ;
    margin-top: 40px;
  }
  .section.s1 {
    width : 41%;
    padding: 0;
  }
  .section.s1 .discription {
    position: relative;
    top: -40px;
  }
  .section > h5 {
    text-align : center ;
    color : white ; 
    background : #667a91 ;
    margin : 0 ;
    font-size: 1.6rem;
    line-height: 150%;
  }
  .section > h5 span{
    display : inline-block ;
  }
  
  .invert{
    color : #667a91 ; 
    background : white  ;
  }
  span.invert:before{
    content :"\00a0"
  }
  span.invert:after{
    content :"\00a0"
  }
  .discription{
    width : 100%;
    height: calc(100% - 20px);
  }
  .flex{
    display : flex;
  }
  .flex3 {
    width  : 33%;
    height : 100% ;
  }
  .img100 {
    height : 100% ;
    width : 100% ; 
    object-fit : contain ;
    padding: 50% 20%;

}
  .img100.top {
    object-position : center top ;
  }
    .img100.bottom {
    object-position : center bottom ;
  }
  
  #xButtonMonde2{
    height: 7%;
    position: absolute;
    top: 11%;
    left: 87%;
    background: #0599c3;
    border-radius: 25px;
  }

  .discription-top {
    padding: 3%;
    padding-left: 6%;
  }
  .discription-top p {
      font-size: medium;
    margin: 0;
  }

  .discription-bottom-container {
      display: flex;
      align-items: center;
      height: 170px;
      position: relative;
      top: 5%;
  }
  .discription-bottom {

  }
  .discription-bottom p {
      font-size: medium;
      margin: 0;
  }

  .img-discription {
    object-fit: contain;
    height: 58%;
    width: 31%;
    
  }

.boatArrow img{
  height: 45%;
  width: 100%;
  object-fit: contain;
  object-position: top center;
}

.grid-monde2-slide{
  height: 70%;
  max-width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  grid-column-gap: 10px;
  column-gap: 10px;
  grid-row-gap: 15px;
  row-gap: 15px;
  position: relative;
  top: -37px;
  left: 24px;
}
.grid-monde2-slide.slide5 {
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  
}
.grid-monde2-slide.slide6 {
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  left: 24px;
  top: 0;
}
.grid-monde2-slide.slide6 > div {
  align-self: center;
}

.discription-slide5 > p,.discription-slide6 > p {
  margin: 0;
}

.img-slide5,.img-slide6{
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  margin-right: 6%;
  object-fit: contain;
  padding-top: 17%;
  position: relative;
  top: -4px;
}
.img-slide6 {
  height: initial;
  padding : 0 ;
  top: 0;
  max-width: 34%;
  vertical-align: bottom;
}
.discription-slide5,.discription-slide6{
  display: inline-block;
  vertical-align: bottom;
  color: #667a91;
  font-size: 1.1em;

}

.discription-slide6{
  vertical-align: text-top;
}

.arrowUp {
  filter : drop-shadow(0px 2px 2px rgba(0,0,0,0.7));
  object-fit: contain;
  width: 100%;
  height: 40%;
}
.arrowDown {
  filter : drop-shadow(0px 2px 2px rgba(0,0,0,0.7));
  object-fit : contain;
  width: 100%;
  height: 40%;
  transform: rotate(180deg);
}
.dropShadowClickable {
  filter: drop-shadow(0px 0px 5px rgb(255, 255, 255)) 
}
.saturateDebloqued {
  filter: saturate(28%)
}
.dropShadowClickableLight {
  filter: drop-shadow(2px 4px 6px white) ;
}
.disableClick {
  filter: grayscale(100%);
}


/* Inline #21 | http://localhost:3000/game */

#arrowMag {
  width: 2%;
  height: 7%;
  position: absolute;
  top: 35%;
  left: 11%;
  z-index: -1;

}

#arrowConduire {
  width: 2%;
  height: 7%;
  position: absolute;
  top: 47%;
  left: 61%;
  z-index: -1;

}

#arrowBatiment {
  width: 2%;
  height: 7%;
  position: absolute;
  top: 35%;
  left: 29%;
  z-index: -1;

}
#arrowBabourKbir {
  width: 2%;
height: 7%;
position: absolute;
top: 17.8%;
left: 48%;
z-index: -1;
}
#arrowBalise {
  width: 2%;
  height: 7%;
  position: absolute;
  top: 40%;
  left: 91.4%;
  z-index: -1;
}
