.Clock-Container {
  height: 65px;
  width: 75px;
  position: relative;
  transform: translate(10px, 3px);
}
.Clock-Container-ab {
  height: 100%;
  width: 100%;
  position: relative;
}

.Clock-head {
  width: 28%;
  padding-top: 13%;
  background: #4d4d4d;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.clock-Body {
  height: 80%;
  width: 80%;
  background: #e78852;
  position: absolute;
  border-radius: 15%;
}

.outer-Green {
  position: relative;
  height: 90%;
  width: 95%;
  background: #00a69c;
  border-radius: 15%;
}

.inner-white {
  position: relative;
  height: 80%;
  width: 88%;
  background: white;
  border-radius: 15%;
}

.center-clock {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Clock-leg {
  width: 10%;
  padding-top: 10%;
  background: #4d4d4d;
  position: absolute;
  border-radius: 50%;
}
.Clock-leg.left {
  bottom: 4%;
  left: 20%;
}

.Clock-leg.right {
  bottom: 4%;
  left: 70%;
}

.flipClock > .flipUnitContainer {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Get the bourbon mixin from http://bourbon.io */
/* Reset */
.flip-clock-wrapper * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-clock-wrapper a {
  cursor: pointer;
  text-decoration: none;
  color: #ccc;
}

.flip-clock-wrapper a:hover {
  color: #fff;
}

.flip-clock-wrapper ul {
  list-style: none;
  margin: 0;
}

.flip-clock-wrapper.clearfix:before,
.flip-clock-wrapper.clearfix:after {
  content: " ";
  display: table;
}

.flip-clock-wrapper.clearfix:after {
  clear: both;
}

.flip-clock-wrapper.clearfix {
  *zoom: 1;
}

/* Main */
.flip-clock-wrapper {
  -webkit-user-select: none;
}

.flip-clock-meridium {
  background: none !important;
  box-shadow: 0 0 0 !important;
  font-size: 10px !important;
}

.flip-clock-meridium a {
  color: #313333;
}

.flip-clock-wrapper {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
}

#clock.ClockWithMinute .flip-clock-wrapper {
  display: inline-block;
  width: 40%;
  margin-right: 7%;
}
#clock.ClockWithOutMinute .flip-clock-wrapper {
  display: inline-block;
  width: 70%;
}
.monde4-clock > .flip-clock-wrapper ul li {
  line-height: 900%;
}
.monde4-clock > .flip-clock-wrapper ul li a div div.inn {
  color: white;
  background-image: linear-gradient(30deg, #662962, #d95fdf);
  font-size: 34px;
}
.monde4-clock {
  text-align: right;
  width: 131px;
  height: 47px;
  position: absolute;
  bottom: 87.2%;
  right: 74px;
}
.monde3-clock > .flip-clock-wrapper ul li {
  line-height: 900%;
}
.monde3-clock > .flip-clock-wrapper ul li a div div.inn {
  color: white;
  background-image: linear-gradient(30deg, #82704c, #e7c984);
  font-size: 34px;
}
.monde3-clock {
  text-align: right;
  width: 131px;
  height: 47px;
  position: absolute;
  bottom: 87.2%;
  right: 74px;
}
.mondeBonus-clock {
  text-align: right;
  width: 101px;
  height: 43px;
  position: absolute;
  bottom: 87.2%;
  right: 70px;
}
.monde2-clock > .flip-clock-wrapper ul li {
  line-height: 900%;
}
.monde2-clock > .flip-clock-wrapper ul li a div div.inn {
  color: white;
  background-image: linear-gradient(30deg, #4cc6f2, #3596c0);
  font-size: 34px;
}

.monde2-clock {
  text-align: center;
  width: 120px;
  height: 47px;
  position: absolute;
  bottom: 75%;
  right: 5px;
}
.monde2-clock.iles {
  text-align: center;
  width: 120px;
  height: 47px;
  position: absolute;
  bottom: 86.5%;
  right: 80px;
}
.monde2-clock.game-2 {
  text-align: center;
  width: 120px;
  height: 47px;
  position: absolute;
  bottom: 35%;
  right: 630px;
}

.monde1-clock > .flip-clock-wrapper ul li {
  line-height: 900%;
}
.monde1-clock > .flip-clock-wrapper ul li a div div.inn {
  color: white;
  background-image: linear-gradient(30deg, #f15a24, #fed457);
  font-size: 34px;
}

.monde1-clock {
  text-align: center;
  width: 120px;
  height: 47px;
  position: absolute;
  bottom: 86.5%;
  right: 80px;
}
.flip-clock-wrapper:before,
.flip-clock-wrapper:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
.flip-clock-wrapper:after {
  clear: both;
}

/* Skeleton */
.flip-clock-wrapper ul {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 80%;
  font-size: 35%;
  font-weight: bold;
  line-height: 600%;
  border-radius: 6px;
  background: rgba(53, 51, 51, 0.294);
}

.flip-clock-wrapper ul li {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 750%;
  text-decoration: none !important;
}

.flip-clock-wrapper ul li:first-child {
  z-index: 2;
}

.flip-clock-wrapper ul li a {
  display: block;
  height: 100%;
  -webkit-perspective: 200px;
  -moz-perspective: 200px;
  perspective: 200px;
  margin: 0 !important;
  overflow: visible !important;
  cursor: default !important;
}

.flip-clock-wrapper ul li a div {
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  font-size: 40px;
  overflow: hidden;
  outline: 1px solid transparent;
}

.flip-clock-wrapper ul li a div .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.flip-clock-wrapper ul li a div.up {
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  top: 0;
}

.flip-clock-wrapper ul li a div.up:after {
  content: "";
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 3px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
}

.flip-clock-wrapper ul li a div.down {
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  -o-transform-origin: 50% 0;
  transform-origin: 50% 0;
  bottom: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.flip-clock-wrapper ul li a div div.inn {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200%;
  color: white;
  text-shadow: 0 1px 2px #0000001a;
  text-align: center;
  background-color: #e78852;
  border-radius: 6px;
  font-size: 65%;
}

.flip-clock-wrapper ul li a div.up div.inn {
  top: 0;
}

.flip-clock-wrapper ul li a div.down div.inn {
  bottom: 0;
}

/* PLAY */
.flip-clock-wrapper ul.play li.flip-clock-before,
.flip-clock-wrapper ul.play1 li.flip-clock-before {
  z-index: 3;
}

.flip-clock-wrapper .flip {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}
.flip-clock-wrapper .flip::before {
  content: "";
  position: absolute;
  z-index: +5;
  top: calc(50% - 0.5px);
  left: 0;
  width: 100%;
  height: 0.5px;
  background-color: rgba(255, 255, 255, 0.4);
}

span.digit-text {
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);
}
.flip-clock-wrapper ul.play li.flip-clock-active,
.flip-clock-wrapper ul.play1 li.flip-clock-active {
  -webkit-animation: asd 0.5s 0.5s linear both;
  -moz-animation: asd 0.5s 0.5s linear both;
  animation: asd 0.5s 0.5s linear both;
  z-index: 5;
}

.flip-clock-divider {
  float: left;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 100px;
}

.flip-clock-divider:first-child {
  width: 0;
}

.flip-clock-dot {
  display: block;
  background: #323434;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  left: 5px;
}

.flip-clock-divider .flip-clock-label {
  position: absolute;
  top: -1.5em;
  right: -86px;
  color: black;
  text-shadow: none;
}

.flip-clock-divider.minutes .flip-clock-label {
  right: -88px;
}

.flip-clock-divider.seconds .flip-clock-label {
  right: -91px;
}

.flip-clock-dot.top {
  top: 30px;
}

.flip-clock-dot.bottom {
  bottom: 30px;
}

@-webkit-keyframes asd {
  0% {
    z-index: 2;
  }

  20% {
    z-index: 4;
  }

  100% {
    z-index: 4;
  }
}

@-moz-keyframes asd {
  0% {
    z-index: 2;
  }

  20% {
    z-index: 4;
  }

  100% {
    z-index: 4;
  }
}

@-o-keyframes asd {
  0% {
    z-index: 2;
  }

  20% {
    z-index: 4;
  }

  100% {
    z-index: 4;
  }
}

@keyframes asd {
  0% {
    z-index: 2;
  }

  20% {
    z-index: 4;
  }

  100% {
    z-index: 4;
  }
}

.flip-clock-wrapper ul.play li.flip-clock-active .down,
.flip-clock-wrapper ul.play1 li.flip-clock-active .down {
  z-index: 2;
  -webkit-animation: turn 0.5s 0.5s linear both;
  -moz-animation: turn 0.5s 0.5s linear both;
  animation: turn 0.5s 0.5s linear both;
}

@-webkit-keyframes turn {
  0% {
    -webkit-transform: rotateX(90deg);
  }

  100% {
    -webkit-transform: rotateX(0deg);
  }
}

@-moz-keyframes turn {
  0% {
    -moz-transform: rotateX(90deg);
  }

  100% {
    -moz-transform: rotateX(0deg);
  }
}

@-o-keyframes turn {
  0% {
    -o-transform: rotateX(90deg);
  }

  100% {
    -o-transform: rotateX(0deg);
  }
}

@keyframes turn {
  0% {
    transform: rotateX(90deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.flip-clock-wrapper ul.play li.flip-clock-before .up,
.flip-clock-wrapper ul.play1 li.flip-clock-before .up {
  z-index: 2;
  -webkit-animation: turn2 0.5s linear both;
  -moz-animation: turn2 0.5s linear both;
  animation: turn2 0.5s linear both;
}

@-webkit-keyframes turn2 {
  0% {
    -webkit-transform: rotateX(0deg);
  }

  100% {
    -webkit-transform: rotateX(-90deg);
  }
}

@-moz-keyframes turn2 {
  0% {
    -moz-transform: rotateX(0deg);
  }

  100% {
    -moz-transform: rotateX(-90deg);
  }
}

@-o-keyframes turn2 {
  0% {
    -o-transform: rotateX(0deg);
  }

  100% {
    -o-transform: rotateX(-90deg);
  }
}

@keyframes turn2 {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(-90deg);
  }
}

.flip-clock-wrapper ul li.flip-clock-active {
  z-index: 3;
}

/* SHADOW */
.flip-clock-wrapper ul.play li.flip-clock-before .up .shadow,
.flip-clock-wrapper ul.play1 li.flip-clock-before .up .shadow {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.1)),
    color-stop(100%, black)
  );
  background: linear, top, rgba(0, 0, 0, 0.1) 0%, black 100%;
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
  background: linear, to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%;
  -webkit-animation: show 0.5s linear both;
  -moz-animation: show 0.5s linear both;
  animation: show 0.5s linear both;
  background: rgba(53, 51, 51, 0.294);
}

.flip-clock-wrapper ul.play li.flip-clock-active .up .shadow,
.flip-clock-wrapper ul.play1 li.flip-clock-active .up .shadow {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.1)),
    color-stop(100%, black)
  );
  background: linear, top, rgba(0, 0, 0, 0.1) 0%, black 100%;
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
  background: linear, to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%;
  -webkit-animation: hide 0.5s 0.3s linear both;
  -moz-animation: hide 0.5s 0.3s linear both;
  animation: hide 0.5s 0.3s linear both;
  background: rgba(53, 51, 51, 0.294);
}

/*DOWN*/
.flip-clock-wrapper ul.play li.flip-clock-before .down .shadow,
.flip-clock-wrapper ul.play1 li.flip-clock-before .down .shadow {
  background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, black),
    color-stop(100%, rgba(0, 0, 0, 0.1))
  );
  background: linear, top, black 0%, rgba(0, 0, 0, 0.1) 100%;
  background: -o-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
  background: -ms-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
  background: linear, to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%;
  -webkit-animation: show 0.5s linear both;
  -moz-animation: show 0.5s linear both;
  animation: show 0.5s linear both;
  background: rgba(53, 51, 51, 0.294);
}

.flip-clock-wrapper ul.play li.flip-clock-active .down .shadow,
.flip-clock-wrapper ul.play1 li.flip-clock-active .down .shadow {
  background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, black),
    color-stop(100%, rgba(0, 0, 0, 0.1))
  );
  background: linear, top, black 0%, rgba(0, 0, 0, 0.1) 100%;
  background: -o-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
  background: -ms-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
  background: linear, to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%;
  -webkit-animation: hide 0.5s 0.3s linear both;
  -moz-animation: hide 0.5s 0.3s linear both;
  animation: hide 0.5s 0.2s linear both;
  background: rgba(53, 51, 51, 0.294);
}

@-webkit-keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
