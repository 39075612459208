.config-container {
  height: 70%;
  width: 70%;
  background-color: #2f3031bd;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 43;
}

.config-container>img:nth-child(1) {
  margin: 5% auto;
  display: block;
  height: 70px;
  object-fit: contain;
  max-width: 90%;

}

/* .input-container-config {
    height: 69%;
   } */

.input-container {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 8%;
}

.image-config-container {
  width: 40%;
  margin-right: 5%;
}

.image-config-container>img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.inputStyle {
  width: 55%;
  background: white;
  border-radius: 5px;
}

.save-close-config {
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: 3% auto;
}

.save-close-config>img {
  height: 100%;
  object-fit: contain;
}





/* input[type=range] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #0698c3;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #0698c3;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #0698c3;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #0698c3;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background: #0698c3;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #0698c3;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #0698c3;
  } */

.input-range__label--value,
.input-range__label--min,
.input-range__label--max {
  display: none;
}

.input-range__track--active {
  background: #0698c3;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 95%;
  margin: 0 auto;
}

.input-range__slider {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,dddddd+100 */
  background: rgb(255, 255, 255);
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dddddd', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */

  box-sizing: border-box;
  border: 2px solid #acacac;
}


.input-range__slider-container,
.input-range__track--active {
  transition: none;
  -webkit-transition: none;
}