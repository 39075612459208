.ScoreTab-Container {
  background-color: #2f3031cf;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
}

.box-ScoreTab-container {
  position: relative;
  height: 75%;
  width: 35%;
  /* float: left; */
  left: 31%;
  top: 10%;
  /* top: 9%; */
  background-image: url("../img/objects/score_background.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.box-ScoreTab-container > svg {
  position: absolute;
}

.dialog-ScoreTab-container {
  line-height: 26px;
  color: #667a91;
  position: relative;
  top: 0;
  left: 0;
  padding-top: 4%;
  box-sizing: content-box;
  padding-left: 5%;
  padding-right: 12%;
  font-size: 150%;
}
.first-text {
  font-size: 20px;
  color: #667a91;
  width: 150px;
  position: relative;
  top: 8%;
  left: 40%;
}
.first-textOups {
  font-size: 20px;
  color: #667a91;
  width: 310px;
  position: relative;
  top: 8%;
  left: 4%;
  text-align: center;
}
.scorevert {
  color: #7db229;
  font-size: 25px;
  /* font-size: 20px; */
  /* color: #615e5e; */
  width: 150px;
  position: relative;
  top: 16%;
  left: 32%;
  line-height: 0.7;
}
.separateur1 {
  background-color: #667a91;
  width: 80%;
  height: 0.5%;
  position: relative;
  top: 14%;
  left: 10%;
}
.separateur2 {
  background-color: #667a91;
  width: 70%;
  height: 0.5%;
  position: relative;
  top: 38%;
  left: 13%;
}
.scorevert-num {
  color: #7db229;
  font-size: 35px;
  width: 160px;
  position: relative;
  top: 22%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  font-weight: 600;
}
.stars {
  width: 57%;
  position: relative;
  height: 11%;
  top: 30%;
  left: 23.5%;
}

.stars > img {
  width: 17%;
  position: relative;
  height: 74%;
  margin-left: -2%;
  object-fit: contain;
  object-position: center center;
}
.stars > span {
  position: relative;
  height: 25%;
  font-size: 10px;
  color: #667a91;
  top: 38%;
  left: -14%;
}
.meilleurscore {
  font-size: 20px;
  color: #667a91;
  width: 200px;
  position: relative;
  top: 27%;
  left: 20%;
}
.meilleurscore-num {
  font-size: 20px;
  color: #667a91;
  width: 70px;
  position: relative;
  top: 32%;
  left: 45%;
}
.coquillage-text {
  font-size: 17px;
  color: #667a91;
  width: 278px;
  position: relative;
  top: 43%;
  left: 32%;
}
.coquillage {
  font-size: 17px;
  color: #667a91;
  width: 83px;
  position: relative;
  height: 7%;
  top: 44%;
  left: 35%;
}
.coquillage > img {
  position: relative;
  width: 50%;
  height: 100%;
  object-fit: contain;
}
.coquillage > span {
  width: 50%;
  height: 100%;
  font-size: 20px;
  position: relative;
}
.ScoreTab-Container > img {
  float: right;
  object-fit: contain;
  width: 26%;
  position: relative;
  left: -5%;
  /* top: 8%; */
  top: 6%;
}

.btn-next-level {
  position: absolute;
  bottom: 0px;
  right: 14%;
  height: 27px;
  width: 70px;
  transform: translateY(-80%);
  z-index: 999;
  object-fit: contain;
  object-position: center center;
}
.Erreur {
  position: absolute;
  bottom: 6px;
  right: 12%;
  height: 13px;
  width: 25px;
  transform: translateY(-50%);
  z-index: 999;
  background-image: url("../img/button/skipmonde4.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.btn-suivant {
  height: 9%;
  width: 30%;
  position: relative;
  object-position: center center;
  object-fit: contain;
  margin-top: 62%;
  margin-left: 33%;
  top: -6px;
}
/* cocktail */
.cocktail-text {
  font-size: 17px;
  color: #667a91;
  width: 276px;
  position: relative;
  top: 47%;
  left: 5%;
  /* height: 38px; */
  display: flex;
  /* justify-content: center; */
  /* align-content: space-around; */
  justify-content: center;
  text-align: center;
}
