.BackgroundFilterWhite {
    height: 100%;
    width: 100%;
    position: absolute;
}
.whiteBg {
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1;
}
.BackgroundFilterWhiteContainer{
  height: 100%;
  width: 100%;
  position: relative;
  left: 0;
  display: flex;
  top: 0;
  align-items: flex-end;
  justify-content: space-between;
  

}
/* .BackgroundFilterWhiteContainer > *{
  display: inline-block;
} */


.ListMagazin.open {
  width: 43%;
  position: absolute;
  height: 85%;
  padding: 2% 3%;
  left: 0;
  bottom: 0;
  z-index: +2;
  
}
.ListMagazin.open *{
  font-family: "SmudgieCrayon" !important;
  
}

.ListMagazin.close {
  position: absolute;
  height: 20%;
  padding: 2% 3%;
  left: 0;
  bottom: 0;
}
.ListMagazin.close  > img{
  height: 100%;
  object-fit: contain;
  object-position: bottom center;
  position: absolute;
  bottom: 3%;
}
.InnerListContainer {
  position: relative;
  background-color: #feedbf;
  width: 100%;
  height: 100%;
}
ul.list {
  font-size : 16px;
}
ul.list > li {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 94%;
  margin-bottom: 9px;
}
ul.list > li > label {
  display: block;
  width: 100%;
}
ul.list > li > label > span.itemName {
  position: relative;
  width: 80%;
  display: inline-block;
}
ul.list > li > label > span.number {
  position: relative;
  font-size: 1.2em;
  padding-right: 7px;
  width: 20px;
  display: inline-block;
  text-align: center;
}
ul.list > li > label > span.itemName::before {
  content: "";
  position: absolute;
  top: 90%;
  width: 100%;
  height: 1.2px;
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 65%, rgba(0,0,0,0) 100%);
  -webkit-clip-path: polygon(0% 0%, 77% 30%, 100% 50%, 62% 90%, 0% 100%);
  clip-path: polygon(0% 0%, 77% 30%, 100% 50%, 62% 90%, 0% 100%);
}

.bagOpenContainer {
  width: 40%;
  height: 98%;
  position: absolute;
  right: 0;
  bottom: 0;
}
.BagMagazin.open{
  width: 100%;
  position: relative;
  background-size: contain;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 2%;
  
}
.BagMagazin.close {
  width: 20%;
  position: absolute;
  height: 20%;
  margin-bottom: 2%;
  right: 0;
  bottom: 0;
}
.BagMagazin.close > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom center;
  position: absolute;
  bottom: -2%;
  right: 5%;
}

.BagMagazinCloseImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom center;
  position: absolute;
  bottom: -2%;
  right: 5%;
}

.BagMagazin > .xclose {
  position: absolute;
  top: 91px;
  left: 75%;
  border: 2px #319ec4 solid;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: grid;
  justify-content: center;
  align-content: center;
  color: #1e96c1;
  font-weight: bolder;
  font-size: 23px;
  background-color: #feedbf;
  filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.4));
 }
 .xcloseList:before{
  content: "×";
  font-weight: bolder;
  font-size: 34px;
 }
 
 .InnerListContainer > .xclose {
  position: absolute;
  top: 0;
  right: 0;
  border: 2px #319ec4 solid;
  border-radius: 50%;
  transform: translate(44%, -44%);
  height: 40px;
  width: 40px;
  display: grid;
  justify-content: center;
  align-content: center;
  color: #1e96c1;
  font-weight: bolder;
  font-size: 23px;
  background-color: #feedbf;
  filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.4));
  font-size: 23px;
 }  
.ItemCollected {
  box-sizing: border-box;
  /* border: 0.5px solid black; */
  display: inline-block;
  position: relative;
  margin: 3px 5px;
}
.ItemCollected > img{
  object-fit: contain;
  max-height: 60px;
  min-height: 40px;
  max-width: 60px;
}
.ItemCollected > .idjumellemonde3{
  object-fit: contain;
  max-height: 50px;
  min-height: 40px;
  max-width: 60px;
}
.left-shape {
  shape-outside: polygon(0% 0%, 0% 100%, 100% 100%, 14.64% 91.31%, 23.95% 56.78%, 50.1% 45.42%, 51.24% 32.04%, 74.15% 22.41%, 100% 22%, 100% 0%);
   float: left;
   width: 50%;
   height: 100%;
 }
  
.right-shape {
  shape-outside: polygon(100% 0%, 100% 100%, 0% 100%, 84.45% 87.72%, 70.22% 53.03%, 52.59% 45.27%, 57.81% 35%, 42.71% 22.28%, 0% 22%, 0% 0%);
  float: right;
  width: 50%;
  height: 100%;
}
  
/* checkbox design */ 
/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  display: none;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 1.25em; height: 1.25em;
  border: 2px solid #ccc;
  
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '\2713\0020';
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-75%,-50%);
  font-size: 1.3em;
  line-height: 0.8;
  color: #09ad7e;
  transition: all .2s;
  font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: translate(-50%,-50%) scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: translate(-75%,-50%) scale(1);

}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
/* [type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 2px dotted blue;
} */

/* hover style just for information */
/* label:hover:before {
  border: 2px solid #4778d9!important;
} */

#listTitleMonde2Game4 {
  display: block;
  height: 12%;
  margin: 0 auto ;
  padding-top: 2%;
}

.arcontainer {
  position: absolute;
  float: left;
  height: 15px;
  width: 40px;
  top: -5px;
  display: flex;
  align-items: center;
  left: -5px;
}

.arcontainer_content {
  display: flex;
  position: relative;
  float: left;
  width: 16px;
  border: 1px solid white;
  background: white;
  margin: 1px;
  font-size: 15px;
  border-radius: 3px;
  text-align: center;
  justify-content: center;
  color: #6cadbf;
  align-items: center;
  font-weight: 900;
}

.arcontainer_content_left {
  height: 16px;
}

.arcontainer_content_right {
  height: 12px;
}

.wobble-hor-top {
	-webkit-animation: wobble-hor-top 1.5s 0.2s both;
	        animation: wobble-hor-top 1.5s 0.2s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-7-1 15:49:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-top
 * ----------------------------------------
 */
 @-webkit-keyframes wobble-hor-top {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-7px) rotate(6deg);
            transform: translateX(-7px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(5px) rotate(-6deg);
            transform: translateX(5px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-5px) rotate(3.6deg);
            transform: translateX(-5px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(2px) rotate(-2.4deg);
            transform: translateX(2px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-2px) rotate(1.2deg);
            transform: translateX(-2px) rotate(1.2deg);
  }
}
@keyframes wobble-hor-top {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-7px) rotate(6deg);
            transform: translateX(-7px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(5px) rotate(-6deg);
            transform: translateX(5px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-5px) rotate(3.6deg);
            transform: translateX(-5px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(2px) rotate(-2.4deg);
            transform: translateX(2px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-2px) rotate(1.2deg);
            transform: translateX(-2px) rotate(1.2deg);
  }
}
