.zombieAv-container {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
}

.curtainsZombieAv {
    width: 9.5%;
    height: 25%;
    position: absolute;
    display: flex;
    left: 45.4%;
    top: 36.5%;
    z-index: 2;
}

.doorZombieAv {
    width: 9.5%;
    height: 24.3%;
    position: absolute;
    display: flex;
    left: 45.4%;
    top: 36.5%;
    z-index: 1;
}

.chair1left {
    width: 35%;
    height: 41%;
    position: absolute;
    display: flex;
    left: 2%;
    top: 50%;
    z-index: 5;
}

.chair1right {
    width: 35%;
    height: 41%;
    position: absolute;
    display: flex;
    left: 62.5%;
    top: 50%;
    z-index: 5;
}

.chair2left {
    width: 26%;
    height: 31%;
    position: absolute;
    display: flex;
    left: 16%;
    top: 46%;
    z-index: 4;
}

.chair2right {
    width: 28%;
    height: 34%;
    position: absolute;
    display: flex;
    left: 55%;
    top: 46%;
    z-index: 4;
}

.chair3left {
    width: 20%;
    height: 25%;
    position: absolute;
    display: flex;
    left: 26%;
    top: 42%;
    z-index: 3;
}


.chair3right {
    width: 19.4%;
    height: 23%;
    position: absolute;
    display: flex;
    left: 53.3%;
    top: 42%;
    z-index: 3;
}

.skull {
    width: 4%;
    height: 8%;
    position: absolute;
    display: flex;
    left: 59%;
    top: 90%;
    z-index: 0;
}

.brain {
    width: 12%;
    height: 10%;
    position: absolute;
    display: flex;
    left: 14%;
    top: 91%;
    z-index: 5;
}

.bloodScreen {
    z-index: -1;
    width: 961px;
    height: 570px;
    position: absolute;
    -webkit-animation: blink-1 2s infinite both;
    animation: blink-1 2s infinite both;
}

@-webkit-keyframes blink-1 {

    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

@keyframes blink-1 {

    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
    }
}

@keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
    }
}

@-webkit-keyframes slide-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
    }
}

@keyframes slide-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@-webkit-keyframes flicker-in-1 {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    10.1% {
        opacity: 1;
    }

    10.2% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    20.1% {
        opacity: 1;
    }

    20.6% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    30.1% {
        opacity: 1;
    }

    30.5% {
        opacity: 1;
    }

    30.6% {
        opacity: 0;
    }

    45% {
        opacity: 0;
    }

    45.1% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    55% {
        opacity: 1;
    }

    55.1% {
        opacity: 0;
    }

    57% {
        opacity: 0;
    }

    57.1% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    60.1% {
        opacity: 0;
    }

    65% {
        opacity: 0;
    }

    65.1% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    75.1% {
        opacity: 0;
    }

    77% {
        opacity: 0;
    }

    77.1% {
        opacity: 1;
    }

    85% {
        opacity: 1;
    }

    85.1% {
        opacity: 0;
    }

    86% {
        opacity: 0;
    }

    86.1% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes flicker-in-1 {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    10.1% {
        opacity: 1;
    }

    10.2% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    20.1% {
        opacity: 1;
    }

    20.6% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    30.1% {
        opacity: 1;
    }

    30.5% {
        opacity: 1;
    }

    30.6% {
        opacity: 0;
    }

    45% {
        opacity: 0;
    }

    45.1% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    55% {
        opacity: 1;
    }

    55.1% {
        opacity: 0;
    }

    57% {
        opacity: 0;
    }

    57.1% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    60.1% {
        opacity: 0;
    }

    65% {
        opacity: 0;
    }

    65.1% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    75.1% {
        opacity: 0;
    }

    77% {
        opacity: 0;
    }

    77.1% {
        opacity: 1;
    }

    85% {
        opacity: 1;
    }

    85.1% {
        opacity: 0;
    }

    86% {
        opacity: 0;
    }

    86.1% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}


.zombie-item {
    width: 80px;
    height: 80px;
    position: absolute;
    display: block;
    filter: drop-shadow(2px 4px 6px white);
    z-index: 3;
}

.fade-in-fwd {
    -webkit-animation: fade-in-fwd 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-fwd 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-fwd {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in-fwd {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.shakeCorrect,
.shakeCorrect:hover,
.shakeCorrect:focus {
    filter: drop-shadow(0px 0px 10px green) drop-shadow(0px 0px 5px green) !important;
}

@keyframes spaceboots {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -1px) rotate(-1deg);
    }

    20% {
        transform: translate(-1px, 1px) rotate(1deg);
    }

    30% {
        transform: translate(1px, 0px) rotate(0deg);
    }

    40% {
        transform: translate(0px, 1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(0px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(2px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(1px, 1px) rotate(1deg);
    }

    90% {
        transform: translate(0px, 1px) rotate(0deg);
    }

    100% {
        transform: translate(1px, 0px) rotate(-1deg);
    }
}

.shakeZ,
.shakeZ:hover,
.shakeZ:focus {
    filter: drop-shadow(0px 0px 10px red) drop-shadow(0px 0px 5px red) !important;
    /* transform: scale(1.7) !important; */
}



/*** objects' animations ***/
.zombieScaleUp {
    -webkit-animation: scale-up-center 4s cubic-bezier(0.39, 0.575, 0.565, 1) 3s both slide-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-up-center 4s cubic-bezier(0.39, 0.575, 0.565, 1) 3s both;
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
}

/* MY ZOMBIES */
@-webkit-keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.my-zombieAv-class-1 {
    /* height: 275px; */
    height: 172px;
    width: calc(220px / 2);
    position: absolute;
    z-index: 3;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie11.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie11Cropped.png");
    background-size: cover;
    /* top: 175px; */
    left: 150px;
    -webkit-animation: my-zombieAv-animation-1 5s linear;
    animation-fill-mode: forwards;
}

.my-zombieAv-class-11 {
    /* height: 275px; */
    height: 172px;
    width: calc(220px / 2);
    position: absolute;
    z-index: 3;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie11.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie11Cropped.png");
    background-size: cover;
    /* top: 175px; */
    left: 150px;
    -webkit-animation: my-zombieAv-animation-11 3.5s linear;
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-11 {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 1;
    }

    10% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    20% {

        opacity: 1;
    }

    40% {

        opacity: 1;
    }

    60% {

        opacity: 1;
    }

    80% {

        opacity: 1;
    }


    95% {

        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes my-zombieAv-animation-1 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0;
        top: 260px;
    }

    20% {
        opacity: 1;
    }

    40% {

        opacity: 1;
        top: 175px;
    }

    60% {

        opacity: 1;
        top: 175px;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        top: 260px;
    }
}

@keyframes my-zombieAv-animation-22 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }


    40% {

        opacity: 1;
        top: 182px;
    }

    60% {

        opacity: 1;
        top: 182px;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        top: 260px;
    }
}

@keyframes my-zombieAv-animation-2 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }


    40% {

        opacity: 1;
        top: 182px;
    }

    60% {

        opacity: 1;
        top: 182px;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        top: 260px;
    }
}

@keyframes my-zombieAv-animation-33 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }

    40% {

        opacity: 1;
        top: 200px;
    }

    60% {

        opacity: 1;
        top: 200px;
    }

    90% {
        opacity: 1;
    }


    100% {
        opacity: 0;
        top: 260px;
    }
}

@keyframes my-zombieAv-animation-3 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }

    40% {

        opacity: 1;
        top: 200px;
    }

    60% {

        opacity: 1;
        top: 200px;
    }

    90% {
        opacity: 1;
    }


    100% {
        opacity: 0;
        top: 260px;
    }
}

@keyframes my-zombieAv-animation-44 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 236px;
    }

    20% {
        opacity: 1;
    }

    40% {
        top: 150px;
        opacity: 1;
    }

    60% {
        top: 150px;
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        top: 236px;
        opacity: 0;
    }
}

@keyframes my-zombieAv-animation-4 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 236px;
    }

    20% {
        opacity: 1;
    }

    40% {
        top: 150px;
        opacity: 1;
    }

    60% {
        top: 150px;
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        top: 236px;
        opacity: 0;
    }
}

@keyframes my-zombieAv-animation-55 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 1;
        top: 403px;
    }

    10% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        top: 393px;
    }

    20% {
        top: 383px;
        opacity: 1;
    }

    40% {
        top: 383px;
        opacity: 1;
    }

    60% {
        top: 383px;
        opacity: 1;
    }

    90% {
        top: 393px;
        opacity: 1;
    }

    95% {
        top: 403px;
        opacity: 1;
    }


    100% {
        top: 403px;
        opacity: 0;
    }
}

@keyframes my-zombieAv-animation-5 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 1;
        top: 403px;
    }

    10% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        top: 393px;
    }

    20% {
        top: 383px;
        opacity: 1;
    }

    40% {
        top: 383px;
        opacity: 1;
    }

    60% {
        top: 383px;
        opacity: 1;
    }

    80% {
        top: 393px;
        opacity: 1;
    }

    95% {
        top: 403px;
        opacity: 1;
    }


    100% {
        top: 403px;
        opacity: 0;
    }
}

@keyframes my-zombieAv-animation-66 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }

    40% {
        top: 215px;
        opacity: 1;
    }

    60% {
        top: 215px;
        opacity: 1;
    }

    90% {
        opacity: 1;
    }


    100% {
        top: 260px;
        opacity: 0;
    }
}

@keyframes my-zombieAv-animation-6 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }

    40% {
        top: 215px;
        opacity: 1;
    }

    60% {
        top: 215px;
        opacity: 1;
    }

    90% {
        opacity: 1;
    }


    100% {
        top: 260px;
        opacity: 0;
    }
}

.my-zombieAv-class-tuto {
    height: 55px;
    width: calc(160px / 2);
    position: absolute;
    z-index: 3;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie5.png");
    background-size: cover;
    top: 325px;
    left: 450px;
    -webkit-animation: my-zombieAv-animation-tuto 5s steps(2);
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-tuto {
    0% {
        background-position: 160px;
        transform: scale(1);
        opacity: 0;
        top: 325px;
        left: 450px;
    }

    10% {
        opacity: 1;
        top: 330px;
    }

    20% {
        background-position: 0px;
        transform: scale(1.15) translateY(10px);
        top: 335px;
    }

    40% {
        background-position: 160px;
        transform: scale(1.3) translateY(20px);
        top: 340px;
    }

    60% {
        background-position: 0px;
        transform: scale(1.45) translateY(30px);
        top: 360px;
    }

    80% {
        background-position: 160px;
        transform: scale(1.6) translateY(40px);
        top: 380px;
    }

    100% {
        background-position: 0px;
        transform: scale(1.75) translateY(50px);
        top: 400px;
    }
}


.my-zombieAv-class-2 {
    /* height: 250px; */
    height: 134px;
    width: calc(150px / 2);
    position: absolute;
    z-index: 3;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie22.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie22Cropped.png");
    background-size: cover;
    /* top: 162px; */
    left: 310px;
    -webkit-animation: my-zombieAv-animation-2 5s linear;
    animation-fill-mode: forwards;
}

.my-zombieAv-class-22 {
    /* height: 250px; */
    height: 134px;
    width: calc(150px / 2);
    position: absolute;
    z-index: 3;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie22.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie22Cropped.png");
    background-size: cover;
    /* top: 162px; */
    left: 310px;
    -webkit-animation: my-zombieAv-animation-22 3.5s linear;
    animation-fill-mode: forwards;
}


.my-zombieAv-class-3 {
    /* height: 230px; */
    height: 125px;
    width: calc(156px / 2);

    position: absolute;
    z-index: 3;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie33.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie33Cropped.png");
    background-size: cover;
    /* top: 180px; */
    left: 671px;
    -webkit-animation: my-zombieAv-animation-3 5s linear;
    animation-fill-mode: forwards;
}

.my-zombieAv-class-33 {
    /* height: 230px; */
    height: 125px;
    width: calc(156px / 2);

    position: absolute;
    z-index: 3;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie33.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie33Cropped.png");
    background-size: cover;
    /* top: 180px; */
    left: 671px;
    -webkit-animation: my-zombieAv-animation-33 3.5s linear;
    animation-fill-mode: forwards;
}



.my-zombieAv-class-4 {
    /* height: 210px; */
    height: 136px;
    width: calc(254px / 2);
    position: absolute;
    z-index: 2;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie4.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie4Cropped.png");
    background-size: cover;
    /* top: 163px; */
    left: 349px;
    -webkit-animation: my-zombieAv-animation-4 5s linear;
    animation-fill-mode: forwards;
}

.my-zombieAv-class-44 {
    /* height: 210px; */
    height: 136px;
    width: calc(254px / 2);
    position: absolute;
    z-index: 2;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie4.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie4Cropped.png");
    background-size: cover;
    /* top: 163px; */
    left: 349px;
    -webkit-animation: my-zombieAv-animation-44 3.5s linear;
    animation-fill-mode: forwards;
}


.my-zombieAv-class-5 {
    height: 75px;
    width: calc(220px / 2);
    position: absolute;
    z-index: 5;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie5.png");
    background-size: cover;
    /* top: 383px; */
    left: 113px;
    -webkit-animation: my-zombieAv-animation-5 5s linear;
    animation-fill-mode: forwards;
}

.my-zombieAv-class-55 {
    height: 75px;
    width: calc(220px / 2);
    position: absolute;
    z-index: 5;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie5.png");
    background-size: cover;
    /* top: 383px; */
    left: 113px;
    -webkit-animation: my-zombieAv-animation-55 3.5s linear;
    animation-fill-mode: forwards;
}



.my-zombieAv-class-6 {
    /* height: 268px; */
    height: 161px;
    width: calc(263px / 2);
    position: absolute;
    z-index: 4;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie6.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie6Cropped.png");
    background-size: cover;
    /* top: 195px; */
    right: 245px;
    -webkit-animation: my-zombieAv-animation-6 5s linear;
    animation-fill-mode: forwards;
}

.my-zombieAv-class-66 {
    /* height: 268px; */
    height: 161px;
    width: calc(263px / 2);
    position: absolute;
    z-index: 4;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie6.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie6Cropped.png");
    background-size: cover;
    /* top: 195px; */
    right: 245px;
    -webkit-animation: my-zombieAv-animation-66 3.5s linear;
    animation-fill-mode: forwards;
}

.my-zombieAv-class-7 {
    height: 55px;
    width: calc(160px / 2);
    position: absolute;
    z-index: 3;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie5.png");
    background-size: cover;
    top: 325px;
    left: 450px;
    -webkit-animation: my-zombieAv-animation-7 5s steps(2);
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-7 {

    0% {
        background-position: 160px;
        transform: scale(1);
        opacity: 0;
        top: 325px;
        left: 450px;
    }

    10% {
        opacity: 1;
        top: 330px;
    }

    20% {
        background-position: 0px;
        transform: scale(1.15) translateY(10px);
        top: 335px;
    }

    40% {
        background-position: 160px;
        transform: scale(1.3) translateY(20px);
        top: 340px;
    }

    60% {
        background-position: 0px;
        transform: scale(1.45) translateY(30px);
        top: 360px;
    }

    80% {
        background-position: 160px;
        transform: scale(1.6) translateY(40px);
        top: 380px;
    }

    100% {
        background-position: 0px;
        transform: scale(1.75) translateY(50px);
        top: 400px;
    }
}

.my-zombieAv-class-77 {
    height: 55px;
    width: calc(160px / 2);
    position: absolute;
    z-index: 3;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie5.png");
    background-size: cover;
    top: 325px;
    left: 450px;
    -webkit-animation: my-zombieAv-animation-77 3.5s steps(2);
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-77 {
    0% {
        background-position: 160px;
        transform: scale(1);
        opacity: 0;
        top: 325px;
        left: 450px;
    }

    10% {
        opacity: 1;
        top: 330px;
    }

    20% {
        background-position: 0px;
        transform: scale(1.15) translateY(10px);
        top: 335px;
    }

    40% {
        background-position: 160px;
        transform: scale(1.3) translateY(20px);
        top: 340px;
    }

    60% {
        background-position: 0px;
        transform: scale(1.45) translateY(30px);
        top: 360px;
    }

    80% {
        background-position: 160px;
        transform: scale(1.6) translateY(40px);
        top: 380px;
    }

    100% {
        background-position: 0px;
        transform: scale(1.75) translateY(50px);
        top: 400px;
    }
}

.my-zombieAv-class-8 {
    width: calc(121px / 2);
    /* height: 202px; */
    height: 108px;
    position: absolute;
    z-index: 2;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie22.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie22Cropped.png");
    background-size: cover;
    /* top: 166px; */
    left: 536px;
    -webkit-animation: my-zombieAv-animation-8 5s linear;
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-8 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }

    40% {
        top: 166px;

        opacity: 1;
    }

    60% {
        top: 166px;

        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        top: 260px;

        opacity: 0;
    }
}

.my-zombieAv-class-88 {
    width: calc(121px / 2);
    /* height: 202px; */
    height: 108px;
    position: absolute;
    z-index: 2;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie22.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie22Cropped.png");
    background-size: cover;
    /* top: 166px; */
    left: 536px;
    -webkit-animation: my-zombieAv-animation-88 3.5s linear;
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-88 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }

    40% {
        top: 166px;

        opacity: 1;
    }

    60% {
        top: 166px;

        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        top: 260px;

        opacity: 0;
    }
}

.my-zombieAv-class-9 {
    /* height: 250px; */
    height: 158px;
    width: calc(198px / 2);
    position: absolute;
    z-index: 4;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie11.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie11Cropped.png");
    background-size: cover;
    left: 806px;
    /* top: 183px; */
    -webkit-animation: my-zombieAv-animation-9 5s linear;
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-9 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }

    40% {
        top: 203px;
        opacity: 1;
    }

    60% {
        top: 203px;
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        top: 260px;
        opacity: 0;
    }
}

.my-zombieAv-class-99 {
    /* height: 250px; */
    height: 158px;
    width: calc(198px / 2);
    position: absolute;
    z-index: 4;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie11.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie11Cropped.png");
    background-size: cover;
    left: 806px;
    /* top: 183px; */
    -webkit-animation: my-zombieAv-animation-99 3.5s linear;
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-99 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }

    40% {
        top: 203px;
        opacity: 1;
    }

    60% {
        top: 203px;
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        top: 260px;
        opacity: 0;
    }
}

.my-zombieAv-class-10 {
    /* height: 250px; */
    height: 125px;
    width: calc(174px / 2);
    position: absolute;
    z-index: 4;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie33.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie33Cropped.png");
    background-size: cover;
    left: 215px;
    /* top: 203px; */
    -webkit-animation: my-zombieAv-animation-10 5s linear;
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-10 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }

    40% {
        top: 223px;
        opacity: 1;
    }

    60% {
        top: 223px;
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        top: 260px;
        opacity: 0;
    }
}

.my-zombieAv-class-1010 {
    /* height: 250px; */
    height: 125px;
    width: calc(174px / 2);
    position: absolute;
    z-index: 4;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie33.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie33Cropped.png");
    background-size: cover;
    left: 215px;
    /* top: 203px; */
    -webkit-animation: my-zombieAv-animation-1010 3.5s linear;
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-1010 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    20% {
        opacity: 1;
    }

    40% {
        top: 223px;
        opacity: 1;
    }

    60% {
        top: 223px;
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        top: 260px;
        opacity: 0;
    }
}

.my-zombieAv-class-11 {
    /* height: 250px; */
    height: 151px;
    width: calc(244px / 2);
    position: absolute;
    z-index: 4;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie6Cropped.png");
    background-size: cover;
    left: 265px;
    /* top: 217px; */
    -webkit-animation: my-zombieAv-animation-11 5s;
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-11 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 0.8;
        top: 260px;
    }

    40% {

        opacity: 1;
        top: 175px;
    }

    60% {

        opacity: 1;
        top: 175px;
    }

    100% {
        opacity: 0;
        top: 260px;
    }
}

.my-zombieAv-class-1111 {
    /* height: 275px; */
    height: 172px;
    width: calc(220px / 2);
    position: absolute;
    z-index: 3;
    /* background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie11.png"); */
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/zombies/zombie11Cropped.png");
    background-size: cover;
    /* top: 175px; */
    left: 150px;
    -webkit-animation: my-zombieAv-animation-1111 3.5s linear;
    animation-fill-mode: forwards;
}

@keyframes my-zombieAv-animation-1111 {
    0% {
        /* -webkit-transform: translateY(500px);
        transform: translateY(500px); */
        opacity: 1;
        top: 237px;
    }

    10% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        top: 227px;
    }

    20% {
        top: 217px;
        opacity: 1;
    }

    40% {
        top: 217px;
        opacity: 1;
    }

    60% {
        top: 217px;
        opacity: 1;
    }

    80% {
        top: 227px;
        opacity: 1;
    }


    95% {
        top: 237px;
        opacity: 1;
    }

    100% {
        top: 217px;
        opacity: 0;
    }
}

/* 0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  } */
/* my humans */
@keyframes my-humanAv-animation-1 {
    0% {
        /* -webkit-transform: translateY(1000px);
        transform: translateY(1000px); */
        opacity: 0;
    }

    100% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        opacity: 1;
    }

    /* 0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    } */
}

@keyframes my-humanAv-animation-2 {
    0% {
        /* -webkit-transform: translateY(1000px);
        transform: translateY(1000px); */
        opacity: 0;
    }

    100% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        opacity: 1;
    }

    /* 0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    } */
}

@keyframes my-humanAv-animation-3 {
    0% {
        /* -webkit-transform: translateY(1000px);
        transform: translateY(1000px); */
        opacity: 0;
    }

    100% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        opacity: 1;
    }

    /* 0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    } */
}

@keyframes my-humanAv-animation-4 {
    0% {
        /* -webkit-transform: translateY(1000px);
        transform: translateY(1000px); */
        opacity: 0;
    }

    100% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        opacity: 1;
    }

    /* 0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    } */
}

@keyframes my-humanAv-animation-5 {
    0% {
        /* -webkit-transform: translateY(1000px);
        transform: translateY(1000px); */
        opacity: 0;
    }

    100% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        opacity: 1;
    }

    /* 0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    } */
}

@keyframes my-humanAv-animation-6 {
    0% {
        /* -webkit-transform: translateY(1000px);
        transform: translateY(1000px); */
        opacity: 0;
    }

    100% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        opacity: 1;
    }

    /* 0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    } */
}

@keyframes my-humanAv-animation-7 {
    0% {
        /* -webkit-transform: translateY(1000px);
        transform: translateY(1000px); */
        opacity: 0;
    }

    100% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        opacity: 1;
    }

    /* 0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    } */
}

@keyframes my-humanAv-animation-8 {
    0% {
        /* -webkit-transform: translateY(1000px);
        transform: translateY(1000px); */
        opacity: 0;
    }

    100% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        opacity: 1;
    }

    /* 0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    } */
}

@keyframes my-humanAv-animation-9 {
    0% {
        /* -webkit-transform: translateY(1000px);
        transform: translateY(1000px); */
        opacity: 0;
    }

    100% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        opacity: 1;
    }

    /* 0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    } */
}

@keyframes my-humanAv-animation-10 {
    0% {
        /* -webkit-transform: translateY(1000px);
        transform: translateY(1000px); */
        opacity: 0;
    }

    100% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        opacity: 1;
    }

    /* 0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    } */
}

@keyframes my-humanAv-animation-11 {
    0% {
        /* -webkit-transform: translateY(1000px);
        transform: translateY(1000px); */
        opacity: 0;
    }

    100% {
        /* -webkit-transform: translateY(0);
        transform: translateY(0); */
        opacity: 1;
    }

    /* 0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    } */
}


.my-humanAv-class-1 {
    height: 320px;
    width: 94px;
    position: absolute;
    display: block;
    z-index: 4;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/human11.png");
    background-size: cover;
    top: 150px;
    left: 60px;
    -webkit-animation: my-humanAv-animation-1 0.5s;
}



.my-humanAv-class-2 {
    height: 200px;
    width: 75px;
    position: absolute;
    display: block;
    z-index: 5;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/human22.png");
    background-size: cover;
    top: 325px;
    left: 30px;
    -webkit-animation: my-humanAv-animation-2 0.5s;
}

.my-humanAv-class-3 {
    height: 182px;
    width: 200px;
    position: absolute;
    display: block;
    z-index: 5;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/human33.png");
    background-size: cover;
    top: 327px;
    left: 427px;
    -webkit-animation: my-humanAv-animation-3 0.5s;
}

.my-humanAv-class-4 {
    height: 120px;
    width: 105px;
    position: absolute;
    display: block;
    z-index: 5;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/human4.png");
    background-size: cover;
    top: 335px;
    left: 368px;
    -webkit-animation: my-humanAv-animation-4 0.5s;
}


.my-humanAv-class-5 {
    height: 227px;
    width: 120px;
    position: absolute;
    display: block;
    z-index: 5;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/human55.png");
    background-size: cover;
    top: 296px;
    left: 700px;
    -webkit-animation: my-humanAv-animation-5 0.5s;
}

.my-humanAv-class-6 {
    height: 295px;
    width: 95px;
    position: absolute;
    display: block;
    z-index: 4;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/human66.png");
    background-size: cover;
    top: 165px;
    left: 740px;
    -webkit-animation: my-humanAv-animation-6 0.5s;
}

.my-humanAv-class-7 {
    height: 295px;
    width: 86px;
    position: absolute;
    display: block;
    z-index: 3;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/human11.png");
    background-size: cover;
    top: 166px;
    left: 570px;
    -webkit-animation: my-humanAv-animation-7 0.5s;
}

.my-humanAv-class-8 {
    height: 120px;
    width: 105px;
    position: absolute;
    display: block;
    z-index: 5;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/human4.png");
    background-size: cover;
    top: 440px;
    left: 570px;
    -webkit-animation: my-humanAv-animation-8 0.5s;
}

.my-humanAv-class-9 {
    height: 227px;
    width: 120px;
    position: absolute;
    display: block;
    z-index: 5;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/human55.png");
    background-size: cover;
    top: 296px;
    left: 200px;
    -webkit-animation: my-humanAv-animation-9 0.5s;
}

.my-humanAv-class-10 {
    height: 240px;
    width: 78px;
    position: absolute;
    display: block;
    z-index: 2;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/human66.png");
    background-size: cover;
    top: 160px;
    left: 248px;
    -webkit-animation: my-humanAv-animation-10 0.5s;
}

.my-humanAv-class-11 {
    height: 182px;
    width: 200px;
    position: absolute;
    display: block;
    z-index: 5;
    background: url("../../../../assets/img/monde_bonus/monde_zombie_avion/human33.png");
    background-size: cover;
    top: 371px;
    left: 761px;
    -webkit-animation: my-humanAv-animation-11 0.5s;
}


.GOtextZombie {
    top: 50%;
    position: relative;
    filter: drop-shadow(4px 4px 10px white);
    font-size: 180px;
    font-weight: 1000;
    animation: scale-in-centerGO 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: 3s;
}