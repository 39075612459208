#BackgroundContainer{
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    object-position: top center;
  }
#Bangalow1{

}
#Magasin{

}
#Bangalow2{}
#door{}
#mini_fire{}
#fire_hyd{}
#gps{}
.slide-in-cloud1 {
	-webkit-animation: slide-in-cloud 11s ease-in-out infinite alternate-reverse both;
	        animation: slide-in-cloud 11s ease-in-out infinite alternate-reverse both;
}
.slide-in-cloud2 {
	-webkit-animation: slide-in-cloud 10s ease-in-out infinite alternate-reverse both;
	        animation: slide-in-cloud 10s ease-in-out infinite alternate-reverse both;
}
.slide-in-cloud3 {
	-webkit-animation: slide-in-cloud 12s ease-in-out infinite alternate-reverse both;
	        animation: slide-in-cloud 12s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes slide-in-cloud {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
}
@keyframes slide-in-cloud {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
}

#arrowmagasin{
  width: 2%;
  height: 7%;
  position: absolute;
  top: 31%;
  left: 9%;
  z-index: -1;
}
#arrowgps{
  width: 2%;
  height: 7%;
  position: absolute;
  top: 74%;
  left: 4.5%;
  z-index: -1;

}
#arrowbung{
  width: 2%;
  height: 7%;
  position: absolute;
  top: 31%;
  left: 65%;
  z-index: -1;

}
#arrowbangalow1 {
  width: 2%;
  height: 7%;
  position: absolute;
  top: 39%;
  left: 34%;
  z-index: -1;
}
#arrowalarm {
  width: 2%;
  height: 6%;
  position: absolute;
  top: 46%;
  left: 65.5%;
  z-index: -1;
}
#arrowfiremen{
  width: 2%;
  height: 7%;
  position: absolute;
  top: 40%;
  left: 96%;
  z-index: -1;
}

#arrowfire {
  width: 2%;
  height: 7%;
  position: absolute;
  top: 48%;
  left: 54.8%;
  z-index: -1;
}
.dropShadowMonde3 {
  filter: drop-shadow(0px 0px 5px rgb(255, 255, 255)) drop-shadow(0px 0px 15px rgb(255, 255, 255));
}