/*Hoja de estilos*/
#cadre {
    display: block;
    margin: 0 auto;

}


#fond{
    opacity: 0.3;
    width: 800px;
    height: 500px;
    top: 31px;
    position: absolute;
    left: 86px;
    transform: translate(70px, 30px);
}
.PuzzleContainer{
    width: 100%;
    height: 100%;
    position: absolute;


}
.puzzle-cadre{
    width: 100%;
    height: 100%;
    position: absolute;

}
.button-container{
    height: 7%;
    width: 12%;
    position: absolute;
    top: 92%;
    left: 86%;
    visibility: hidden;
}

.button-container > img{
    position: absolute;
    height: 100%;
    width: 100%;
}
#puzzle-complete{
    position: absolute;
    height: 80%;
    width: 80%;
    top : 10%;
    left: 10%;
}




  