#mainContainer1{
    height: 100%;
    width: 100%;
    position: absolute;
}
.Aeroport{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;

}


.ClickForGame {
    position: absolute;
    object-fit: contain;
    object-position: center center;
   /* filter: drop-shadow(0px 0px 3px black); */
}
.ClickForGame:hover {
    cursor: pointer;  
    filter: drop-shadow(0px 1px 5px white);

}
.ClickForGame#Avion {
    height: 36%;
    width: 50%;
    top: 50%;
    left: 0%;
    filter: drop-shadow(0px 1px 5px white);
}

.ClickForGame#Brevet {
    height: auto;
    width: 18%;
    top: 54.5%;
    left: 55%;
    filter: drop-shadow(0px 1px 5px white);
}
#arrow{
    width: 2%;
    height: 7%;
    position: absolute;
    top: 46%;    left: 63%;
    z-index: -1;

  }
  #arrowavion{
    width: 2%;
    height: 7%;
    position: absolute;
    top: 42%;
    left: 21%;
    z-index: -1;

  }
  .bounce-top {
	-webkit-animation: bounce-top 3s infinite both;
	        animation: bounce-top 3s infinite both;
}
@-webkit-keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  
  .tayaroDangaro {
        box-shadow: inset 0 0 50px 40px #dc354675;
        animation: pulseDanger 0.5s infinite alternate;
        
      }

      @keyframes pulseDanger {
        from {
          box-shadow: inset 0 0 50px 0px #dc354675;
        }

      }