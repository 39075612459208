.Danser-Container{
    height: 70%;
    width: 70%;
    position: relative;
    top: 15%;
    left: 15%;
}
.Danser-Items-Container{
    height: 70%!important;
    width: 70%!important;
    display: flex;
    position: relative;
    left: 105px;
    bottom: 103px;
}

.Danser-Item {
    height: 100% ;
    width: 33.33% 
}
.Rule-Container {
    opacity: 0.96;
    display: flex;
    position: relative;
    align-items: center;
    top: 90%;
    left: 22%;
    background-color: whitesmoke;
    height: 80px!important;
    width: 350px!important;
    padding: 0 3%;
    clip-path: polygon(7% 0, 100% 0%, 93% 100%, 0% 100%);
}
.animationFadeIn > img {
    height: 100% ;
    width: 100% ;
    object-fit: contain ;
    object-position: center center 
}
.Rule-Text{
    font-size: 21px;
    color: #667a91;
    position: relative;
    left: 8%;
}
.Rule-Text.poisson{
    font-size: 19px;

}
.Rule-Indication{

    position: relative;
    left: 20%;
}