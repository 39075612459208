.DirectionArrows{
  position: absolute;
  height: 39%;
  width: 31%;
  margin-top: 32%;
  margin-left: 35%;
  justify-content: center;
  align-items: center;
}
#ArrowForward{
  transform: rotate(180deg);
    position: relative;
    height: 25%;
    width: 25%;
    margin-left: 40%;
    
  }
#ArrowForward > img {
    position: absolute;
    height: 100%;
    width: 100%;
  }
#ArrowLeft{
    position: relative;
    height: 25%;
    width: 25%;
    transform: rotate(90deg);
    margin-top: 7%;
    margin-left: 15%;
    float: left;
    
}
#ArrowLeft > img {
    position: absolute;
    height: 100%;
    width: 100%;
  }
#ArrowRight{
    position: relative;
    height: 25%;
    width: 25%;
    transform: rotate(270deg);
    margin-top: 7%;
    margin-left: 25%;
    float: left;
    
}
#ArrowRight > img {
    position: absolute;
    height: 100%;
    width: 100%;
  }
#ArrowBack{
    position: relative;
    height: 25%;
    width: 25%;
    margin-left: 40%;
    float: left;
    margin-top: 7%;
    
}
#ArrowBack > img {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .gameContainer{
    width: 28%;
    height: 38.21%;
    position: absolute;
    top: 13%;
    left: 36.5%;
    background-color: #fdd384;
  }
  #direction-item{
    display: flex;
    height: 30%;
    width: 29%;
    position: absolute;
    top: 18%;
    z-index: 999;
    left: 36%;

  }
  #direction-item > div {
    display: flex;
    height: 100%; 
    width: 100%;
    position: relative;
    float: left;
    animation: fade-in-fwd 1.5s ease-in-out 1s 1 alternate-reverse both;

  }
  .direction-item-flex-center {
    text-align: center ;
    max-width: 80%;
    margin: 0 auto;
    
}
.directionElement{
  width: 40%;
  height: 90%;
  position: relative;
  float: left;
  top: 0%;
  left: 32%;
}
.directionFleche1{
  background-image: linear-gradient( #6356EE, #BBA2FF);
  width: 100%;
  height: 50%;
  clip-path: polygon(100% 85%, 85% 100%, 50% 40%, 15% 100%, 0 85%, 50% 0);
  animation: fade-in  1.25s  both;


}
.directionFleche2{
  background-image: linear-gradient( #6356EE, #BBA2FF);
    width: 100%;
  height: 50%;
  clip-path: polygon(100% 85%, 85% 100%, 50% 40%, 15% 100%, 0 85%, 50% 0);
  animation: fade-in  0.25s  both;
   
}
.directionRight {
  width: 40%;
  height: 90%;
  position: relative;
  float: left;
  top: 0%;
  left: 32%;
  transform: rotate(90deg);
 
}
.directionLeft {
  transform: rotate(270deg);
  width: 40%;
  height: 90%;
  position: relative;
  float: left;
  top: 6%;
  left: 32%;
  
}
.directionBack {
  transform: rotate(180deg);
  width: 40%;
  height: 90%;
  position: relative;
  float: left;
  top: 6%;
  left: 32%;
  
}
.map-container{
  width: 28.5%;
  height: 39%;
  position: relative;
  float: left;
  margin-top: 7.5%;
  margin-left: 36.1%;
}

.map-container > div > div > img {
  height: 221.28px!important;
}
.hero-container{
  width: 23.5px;
  height: 23.5px;
    position: relative;
  top : -16px;
  left: 110px;
  z-index: 1;

}
.zoom-container{
  width: 20%;
  height: 50%;
  position: relative;
  float: left;
  bottom: 224px;
  left: 0%;
  z-index: 1;

}
.zoom-in{
  width: 100%;
  height: 50%;
  margin-top: 1%;
}
.zoom-out{
  width: 100%;
  height: 50%;
  margin-top: 1%;
}
.villagers-container{
  width: 23.5px;
  height: 23.5px;
    position: relative;
  top : -196px;
  left: -29px;
  z-index: 1;

}

.pulseGPS {
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgb(150, 150, 150);
	transform: scale(1);
	animation: pulse-black-gps 2s infinite;
}

@keyframes pulse-black-gps {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(150, 150, 150, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(150, 150, 150, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(150, 150, 150, 0);
	}
}